import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../styles/iconTextButton.css';

interface IconTextButtonProps {
    icon?: string;
    faIcon?: any;
    text?: string;
    onClick?: () => void;
    size?: string;
    containerStyle?: React.CSSProperties;
    iconStyle?: React.CSSProperties;
    className?: string;
    color?: string;
}

const IconTextButton: React.FC<IconTextButtonProps> = ({ icon, faIcon, text, onClick, size, containerStyle, iconStyle, className, color }) => {
    return (
        <div
            className={`iconTextButton ${className}`}
            onClick={onClick}
            style={{
                ...containerStyle,
            }}
        >
            {faIcon && (
                <FontAwesomeIcon
                    style={{
                        width: size ?? '20px',
                        height: size ?? '20px',
                        ...iconStyle,
                    }}
                    icon={faIcon}
                    size="xl"
                    title={text}
                    color={color}
                />
            )}
            {icon && !faIcon && <img src={icon} alt={text} title={text} />}
            {text && <div>{text}</div>}
        </div>
    );
};

export default IconTextButton;
