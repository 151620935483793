import React from 'react';
import dateToUTCMidnight from '../../utilities/date/dateToUTCMidnight';
import Dropdown from '../Dropdowns/Dropdown';
import { PLEASE_SELECT___ } from '../../constants/constantStrings';

export const DrawerDateInput = (props) => {
    const [day, setDay] = React.useState(null);
    const [month, setMonth] = React.useState(null);
    const [year, setYear] = React.useState(null);

    React.useEffect(() => {
        if (props.date) {
            let date = dateToUTCMidnight(props.date).toISOString().split('T')[0];
            date = dateToUTCMidnight(date);
            const newDay = date.getUTCDate();
            const newMonth = date.getUTCMonth();
            const newYear = date.getUTCFullYear();
            if (newDay !== day && newMonth !== month && newYear !== year) {
                setDay(newDay - 1);
                setMonth(newMonth);
                setYear(newYear);
            }
        }
        if (day && month && year && !props.date) {
            setDay(null);
            setMonth(null);
            setYear(null);
        }
    }, [props.date, day, month, year]);

    React.useEffect(() => {
        if (day !== null && month !== null && year !== null) {
            let newDate = dateToUTCMidnight(new Date(year, month, day + 1));
            props.setDate(newDate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [day, month, year]);

    const THIS_YEAR = dateToUTCMidnight().getFullYear();
    // years from 100 years ago till this year
    const YEARS = Array.from(Array(100).keys()).map((y) => THIS_YEAR - y);

    const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const DAYS_PER_MONTH = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    const isLeapYear = (y) => y % 4 === 0 && y % 100 !== 0;

    // account for leap year
    if (year && !isLeapYear(year)) DAYS_PER_MONTH[1] = 28;

    const DAYS = Array.from(Array(month ? DAYS_PER_MONTH[month] : 31).keys()).map((d) => d + 1);

    return (
        <div className="addEditDocument-basicFields-date">
            <Dropdown
                selection={MONTHS[month] ?? 'month'}
                fontColor={month === null ? 'lightgray' : null}
                idPrefix="Month"
                options={MONTHS}
                select={(newMonth) => {
                    if (newMonth === PLEASE_SELECT___) return;
                    newMonth = MONTHS.indexOf(newMonth);
                    setMonth(newMonth);
                    if (day && day > DAYS_PER_MONTH[newMonth] - 1) {
                        setDay(DAYS_PER_MONTH[newMonth] - 1);
                    }
                }}
                style={{ height: '38px', width: '40%' }}
                readOnly={props.readOnly}
            />
            <Dropdown
                selection={day !== null ? day + 1 : 'day'}
                fontColor={day === null ? 'lightgray' : null}
                idPrefix="Day"
                options={DAYS}
                select={(newDay) => {
                    if (newDay === PLEASE_SELECT___) return;
                    setDay(newDay - 1);
                }}
                style={{ height: '38px', width: '25%' }}
                readOnly={props.readOnly}
            />
            <Dropdown
                selection={year ?? 'year'}
                fontColor={year === null ? 'lightgray' : null}
                idPrefix="Year"
                options={YEARS}
                select={(newYear) => {
                    if (newYear === PLEASE_SELECT___) return;
                    setYear(newYear);
                    if (month === 1 && day === 28 && !isLeapYear(newYear)) {
                        setDay(27);
                    }
                }}
                style={{ height: '38px', width: '25%' }}
                readOnly={props.readOnly}
            />
        </div>
    );
};
