import React from 'react';
import ObjectSearchSelectTs, { ObjectSearchSelectProps } from '../Dropdowns/ObjectSearchSelectTs';
import api from '../../api';
import { IInvestmentMaster } from '../../types/InvestmentMaster';
import { generateStringHash } from '../../utilities/string/generateStringHash';
import { toQueryString } from '../../utilities/apiHelpers/queryString';

type InvestmentMasterSearchFilterProps = Omit<ObjectSearchSelectProps<IInvestmentMaster>, 'getLabel'> & {
    filter?: any;
};

const InvestmentMasterSearchFilter = ({
    filter = {},
    onChange,
    selected,
    isMulti = false,
    formatOptionLabel,
    width,
    defaultOptions = false,
    defaultLabel = '',
    label = '',
}: InvestmentMasterSearchFilterProps) => {
    let filterHash = generateStringHash(JSON.stringify(filter));
    return (
        <ObjectSearchSelectTs<IInvestmentMaster>
            key={filterHash}
            label={label}
            placeholder={`Select Investment Master${isMulti ? 's' : ''}`}
            selected={selected}
            onChange={onChange}
            getLabel={(investmentMaster: IInvestmentMaster) => investmentMaster?.name}
            defaultLabel={defaultLabel || 'None'}
            matchProperty="_id"
            loadOptions={async (inputValue: string) => {
                try {
                    const query = {
                        ...filter,
                        search: inputValue,
                        count: 50,
                    };
                    const queryStr = toQueryString(query);
                    const route = `/investmentmasters?${queryStr}`;
                    const investmentMasters: IInvestmentMaster[] = (await api.get(route)).results ?? [];
                    return investmentMasters;
                } catch (e) {
                    console.error('Error loading investment masters:', e);
                    return [];
                }
            }}
            width={width ?? '100%'}
            isMulti={isMulti}
            formatOptionLabel={(option) => {
                if (formatOptionLabel) return formatOptionLabel(option);
                const investmentMaster: IInvestmentMaster = option.value;
                if (!investmentMaster) return defaultLabel || 'None';
                return (
                    <div>
                        <div style={{ fontSize: '12px', color: 'var(--color-light-gray)' }}>{investmentMaster.ticker}</div>
                        <div style={{}}>{investmentMaster.name}</div>
                    </div>
                );
            }}
            defaultOptions={defaultOptions}
        />
    );
};

export default InvestmentMasterSearchFilter;
