import React from 'react';
import IconTooltip from '../../../Icons/IconTooltip';
import { capitalizeFirstEveryWord } from '../../../../utilities/format/capitalizeFirst';
import { BulkUploadColumn } from '../types/bulkUploadTypes';

interface HoverableColumnHeaderProps {
    columnDataObject: any;
    containerStyle?: React.CSSProperties;
}

const HoverableColumnHeader: React.FC<HoverableColumnHeaderProps> = ({ columnDataObject, containerStyle = {} }: HoverableColumnHeaderProps) => {
    const { dataType, required, invalidMessage } = columnDataObject;
    const text = `${capitalizeFirstEveryWord(dataType)} ${required ? '(required)' : '(optional)'}`;
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                ...containerStyle,
            }}
        >
            {capitalizeFirstEveryWord(columnDataObject.displayName)}
            {columnDataObject.required ? ' *' : ''}
            {
                <IconTooltip
                    text={text}
                    containerStyle={{
                        marginLeft: '10px',
                    }}
                    iconStyle={{
                        width: '12px',
                        height: '12px',
                    }}
                    popupStyle={{
                        marginLeft: '-75px',
                        marginTop: '-60px',
                    }}
                />
            }
        </div>
    );
};

export const renderHoverableColumns = (columns: { [key: string]: BulkUploadColumn }) => {
    return (
        <>
            {Object.values(columns).map((columnDataObject) => (
                <th>
                    <HoverableColumnHeader columnDataObject={columnDataObject} />
                </th>
            ))}
        </>
    );
};

export const DraggableColumnHeaders: React.FC<{
    columns: { [key: string]: BulkUploadColumn & { headerStyle?: React.CSSProperties } };
    onDrop: (source: string, target: string) => void;
}> = ({ columns, onDrop }) => {
    const [draggedColumn, setDraggedColumn] = React.useState<string | null>(null);
    const [hoveredColumn, setHoveredColumn] = React.useState<string | null>(null);

    const handleDragStart = (columnName: string) => {
        setDraggedColumn(columnName);
    };

    const handleDragEnter = (columnName: string) => {
        if (draggedColumn && draggedColumn !== columnName) {
            setHoveredColumn(columnName);
        }
    };

    const handleDragLeave = () => {
        setHoveredColumn(null);
    };

    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault(); // Necessary to allow dropping
    };

    const handleDrop = (targetColumnName: string) => {
        if (draggedColumn && draggedColumn !== targetColumnName) {
            onDrop(draggedColumn, targetColumnName);
        }
        setDraggedColumn(null);
        setHoveredColumn(null);
    };

    return (
        <>
            {Object.entries(columns).map(([columnName, columnDataObject]) => (
                <th
                    key={columnName}
                    draggable
                    onDragStart={() => handleDragStart(columnName)}
                    onDragOver={handleDragOver}
                    onDrop={() => handleDrop(columnName)}
                    style={{
                        cursor: 'move',
                        backgroundColor: hoveredColumn === columnName ? 'blue' : 'transparent',
                        ...columnDataObject.headerStyle,
                    }}
                >
                    <HoverableColumnHeader columnDataObject={columnDataObject} />
                </th>
            ))}
        </>
    );
};

export default HoverableColumnHeader;
