import React from 'react';

interface ConnectionOauthSetupButtonProps {
    connection_id?: string;
    integration_name?: string;
}

const INTEGRATIONS: any = {
    carta: {
        prodUrl: 'https://login.app.carta.com/o/authorize/',
        devUrl: 'https://login.playground.carta.team/o/authorize/',
        prodOauthId: 'WE4KI3EaTxtCOYeAMhN8Y1E4rRZt2oxM6KslP0JR',
        devOauthId: 'w3fB6Kz6G7mncMuHd4OGEcB3fDolGsF0B3ibQAw3',
        scope: 'openid email profile read_corporation_info read_draftissuers readwrite_draftissuers read_investor_capitalizationtables read_investor_securities read_investor_funds read_investor_firms read_investor_investments read_investor_stakeholdercapitalizationtable read_issuer_securities readwrite_issuer_securities read_issuer_capitalizationtablesummary readwrite_issuer_draftsecurities read_issuer_valuations read_issuer_stakeholders read_issuer_draftsecurities read_issuer_stakeholdercapitalizationtable read_issuer_securitiestemplates read_issuer_info read_issuer_shareclasses read_opencaptables readwrite_opencaptables read_portfolio_issuervaluations read_portfolio_securities read_portfolio_transactions read_portfolio_fundinvestmentdocuments read_portfolio_info read_user_info readwrite_file_upload',
    },
    // Add more integrations here
};

const ConnectionOauthSetupButton: React.FC<ConnectionOauthSetupButtonProps> = ({ connection_id, integration_name }) => {
    const is_prod = window.location.hostname === 'app.altexchange.com';
    if (!connection_id || !integration_name) {
        return <></>;
    }
    const integration = INTEGRATIONS[integration_name.toLowerCase()];

    if (!integration) {
        return <></>;
    }

    const generateRandomState = () => {
        return Math.random().toString(36).substring(2);
    };

    const buildUrl = (integration: any, redirectUri: string, state: string) => {
        const oauthId = is_prod ? integration.prodOauthId : integration.devOauthId;
        const baseUrl = is_prod ? integration.prodUrl : integration.devUrl;
        let query = `?client_id=${oauthId}`;
        query += `&redirect_uri=${encodeURIComponent(redirectUri)}`;
        query += `&response_type=code&scope=${encodeURIComponent(integration.scope)}`;
        query += `&state=${state}`;
        return baseUrl + query;
    };

    const handleButtonClick = () => {
        const redirectUri = `${window.location.origin}/oauth/${integration_name.toLowerCase()}`;
        const state = generateRandomState();
        const url = buildUrl(integration, redirectUri, state);

        // Save lookup info to local storage
        localStorage.setItem(state, JSON.stringify({ connection_id, integration_name }));

        window.location.href = url;
    };

    if (!Object.keys(INTEGRATIONS).includes(integration_name.toLowerCase())) {
        return <></>;
    }

    return (
        <button onClick={handleButtonClick} className="oauth-setup-button">
            Setup OAuth for {integration_name}
        </button>
    );
};

export default ConnectionOauthSetupButton;
