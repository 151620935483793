import Multitoggle from '../Toggles/Multitoggle';

export const HIDDEN_INVESTMENT_FILTER_OPTIONS = {
    all: 'All',
    hidden: 'Hidden',
    visible: 'Visible',
};

const HiddenInvestmentFilter = ({ value, onChange }: { value: string; onChange: (newValue: string) => void }) => {
    return (
        <Multitoggle
            containerStyle={{ width: '250px', height: '37px' }}
            keyStr={'hiddenToggle'}
            options={Object.values(HIDDEN_INVESTMENT_FILTER_OPTIONS)}
            selection={value || HIDDEN_INVESTMENT_FILTER_OPTIONS.all}
            onSelect={onChange}
        />
    );
};

export default HiddenInvestmentFilter;
