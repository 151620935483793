import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

import api2 from '../../api2';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';
import renderInputError from '../../components/renderInputError';
import { renderInputComponentList } from '../../components/DrawerComponents/renderInputComponentList';
import { Integration } from '../../types/Integration';
import '../../styles/investmentMaster.css';

interface IntegrationDrawerProps {
    integration?: Integration;
    closeDrawer: () => void;
    loading: () => void;
    loaded: () => void;
    setAlert: (message: string, isError?: boolean) => void;
    reloadData: () => void;
}

interface IntegrationDrawerState {
    integrationData: Partial<Pick<Integration, 'name' | 'has_worker' | 'tfa_required' | 'phone_required' | 'portal_url'>>;
    errors: { [key: string]: string | undefined };
}

class IntegrationDrawer extends Component<IntegrationDrawerProps, IntegrationDrawerState> {
    state: IntegrationDrawerState = {
        integrationData: this.props.integration || {},
        errors: {},
    };

    componentDidUpdate(prevProps: IntegrationDrawerProps) {
        if (!_.isEqual(prevProps.integration, this.props.integration)) {
            this.setState({ integrationData: this.props.integration || {} });
        }
    }

    getFields = () => {
        const fields = {
            Name: {
                placeholder: 'Enter Name',
                label: 'Name',
                fieldName: 'name',
                type: 'text',
                required: true,
            },
            HasWorker: {
                label: 'Has Worker',
                fieldName: 'has_worker',
                type: 'checkbox',
            },
            TfaRequired: {
                label: 'TFA Required',
                fieldName: 'tfa_required',
                type: 'checkbox',
            },
            PhoneRequired: {
                label: 'Phone Required',
                fieldName: 'phone_required',
                type: 'checkbox',
            },
            PortalUrl: {
                placeholder: 'Enter Portal Url',
                label: 'Portal Url',
                fieldName: 'portal_url',
                type: 'text',
            },
            LambdaArn: {
                placeholder: 'Enter Lambda ARN',
                label: 'Lambda ARN',
                fieldName: 'lambda_arn',
                type: 'text',
            },
            CycleContainerId: {
                placeholder: 'Enter Cycle Container ID',
                label: 'Cycle Container ID',
                fieldName: 'cycle_container_id',
                type: 'text',
            },
            WorkerVersion: {
                placeholder: 'Enter Worker Version',
                label: 'Worker Version',
                fieldName: 'worker_version',
                type: 'number',
            },
        };
        return fields;
    };

    checkFields = (data: Partial<Integration>, errors: { [key: string]: string | undefined }) => {
        Object.values(this.getFields()).forEach((field: any) => {
            const fieldValue = data[field.fieldName as keyof Integration];
            if (field.required && (!fieldValue || (Array.isArray(fieldValue) && fieldValue.length === 0))) {
                errors[field.fieldName] = 'This field is required.';
            }
        });
    };

    isSafeToSave = (setErrors = true) => {
        let errors: { [key: string]: string | undefined } = {};
        this.checkFields(this.state.integrationData, errors);
        const isSafe = Object.keys(errors).length === 0;
        if (setErrors) {
            errors.submit = isSafe ? undefined : 'There are missing/invalid required fields.';
            this.setState({ errors: isSafe ? {} : errors });
        }
        return isSafe;
    };

    handleSubmit = async () => {
        console.log(this.isSafeToSave());
        if (!this.isSafeToSave()) return;
        this.props.loading();

        const { integrationData } = this.state;
        const { integration, setAlert, reloadData, closeDrawer, loaded } = this.props;

        try {
            if (integration) {
                await api2.client.IntegrationApi.updateIntegration({
                    integration_id: integration._id!,
                    UpdateIntegrationRequest: integrationData,
                });
                setDismissableAlert(setAlert, 'Integration updated successfully');
            } else {
                await api2.client.IntegrationApi.createIntegration({
                    CreateIntegrationRequest: {
                        ...integrationData,
                        name: integrationData.name || 'New Integration',
                    },
                });
                setDismissableAlert(setAlert, 'Integration created successfully');
            }
            reloadData();
            closeDrawer();
        } catch (err: any) {
            setDismissableAlert(setAlert, `Integration ${integration ? 'update' : 'create'} failed`, true);
        } finally {
            loaded();
        }
    };

    setKeyValue = (key: keyof Integration, value: Integration[keyof Integration]) => {
        this.setState({ integrationData: { ...this.state.integrationData, [key]: value } });
    };

    renderSubmitButton = () => {
        return (
            <div>
                <button className="drawer-submit-button" onClick={this.handleSubmit}>
                    {this.props.integration ? 'Save Changes' : 'Create'}
                </button>
                {renderInputError(this.state.errors, 'submit')}
                <div style={{ clear: 'both', height: '20px' }} />
            </div>
        );
    };

    renderCancelButton = () => {
        return (
            <>
                <div className="addNewAdvisorGroup-cancel">
                    <span className="a" onClick={this.props.closeDrawer}>
                        Cancel
                    </span>
                </div>
                <div style={{ clear: 'both', height: '20px' }} />
            </>
        );
    };

    render = () => {
        const { integrationData, errors } = this.state;
        return (
            <>
                <div className="addNewAdvisorGroup-drawer">
                    <h1>{this.props.integration ? `Edit Integration` : `Create Integration`}</h1>
                    <div>
                        {renderInputComponentList(
                            this.getFields(),
                            (key: keyof Integration, value: Integration[keyof Integration]) => this.setKeyValue(key, value),
                            integrationData,
                            errors,
                            true,
                            false,
                            true
                        )}
                    </div>
                    {this.renderSubmitButton()}
                    {this.renderCancelButton()}
                </div>
                <div style={{ clear: 'both', height: '300px' }} />
            </>
        );
    };
}

export default IntegrationDrawer;
