import { Component } from 'react';
import _ from 'lodash';
import { faDownload, faTrash, faTrashRestore, faUpload } from '@fortawesome/free-solid-svg-icons';

import { FileParseResult, FileTextResult } from '../../../../constants/file';
import UTCDatePicker from '../../../Inputs/UTCDatePicker/UTCDatePicker';
import dateToUTCMidnight from '../../../../utilities/date/dateToUTCMidnight';
import IconButton from '../../../Buttons/IconButton';
import { DraggableColumnHeaders } from '../components/DraggableColumnHeaders';
import { BulkUploadDataType, BulkUploadHeaderComponent } from '../types/bulkUploadTypes';
import { BulkUploadStatus, SingleFileParseBulkUploadEditResult, FileParseBulkUploadEditResult } from '../types/bulkUploadTypes';
import {
    validateData,
    getDeletedResults,
    getSubmittedResults,
    getPendingResults,
    getErroredResults,
    canSubmitTable,
    canUnsubmitTable,
    canDeleteTable,
    canRestoreTable,
    canDeleteRow,
    canRestoreRow,
    canSubmitRow,
    canUnsubmitRow,
} from '../utilities/bulkUploaderReviewStageUtilities';
import BulkUploadHandler from '../bulkUploadHandlers/BulkUploadHandler';
import { reorderColumns } from '../utilities/helpers';
import Select from 'react-select';

import styles from './BulkUploaderReviewStage.module.scss';
import generateOutputCsv from '../utilities/generateOutputCsv';
import { saveAs } from 'file-saver';
import { TimelessDate } from '../../../../types/TimelessDate';
import saveCsv from '../../../../utilities/file/saveCsv';

interface BulkUploaderReviewStageProps<T> {
    fileTextResults: FileTextResult[];
    fileParsingResults: FileParseResult<T>[];
    setFileParsingResults: (fileParsingResults: FileParseResult<T>[]) => void;
    handler: BulkUploadHandler<any, any>;
    getFileParsingResults: (fileTextResult: FileTextResult[], columnOrder?: string[]) => Promise<FileParseResult<T>[]>;
    addHeaderComponent: (key: string, component: BulkUploadHeaderComponent) => void;
    removeHeaderComponent: (key: string) => void;
}

interface BulkUploaderReviewStageState<T> {
    fileParsingEditResults: FileParseBulkUploadEditResult<T>[];
}

export default class BulkUploaderReviewStage<T = Record<string, string>> extends Component<BulkUploaderReviewStageProps<T>, BulkUploaderReviewStageState<T>> {
    constructor(props: BulkUploaderReviewStageProps<T>) {
        super(props);
        this.state = {
            fileParsingEditResults: this.initializeEditingData(props.fileParsingResults),
        };
    }

    componentDidMount(): void {
        this.setHeaderComponents();
    }

    componentDidUpdate(prevProps: Readonly<BulkUploaderReviewStageProps<T>>, prevState: Readonly<BulkUploaderReviewStageState<T>>, snapshot?: any): void {
        if (!_.isEqual(prevProps.fileParsingResults, this.props.fileParsingResults)) {
            this.setState({
                fileParsingEditResults: this.initializeEditingData(this.props.fileParsingResults),
            });
        }
    }

    componentWillUnmount(): void {
        this.removeHeaderComponents();
    }

    // load a single file's data for editing
    initializeEditingData = (fileParsingResults: FileParseResult<T>[]): FileParseBulkUploadEditResult<T>[] => {
        return fileParsingResults.map((fileParsingResult, fileIdx) => {
            if (fileParsingResult.success === false) {
                return {
                    fileParsingResult,
                    editResults: [],
                    errors: ['Error parsing file. Please check the file and try again.', fileParsingResult.message],
                };
            }
            return {
                fileParsingResult,
                status: BulkUploadStatus.Pending,
                editResults: fileParsingResult.data.map((singleDatum: T, singleDatumIdx: number) => {
                    const [isValid, errors] = validateData<T>(singleDatum, this.props.handler.getColumns(this.getColumnOrder(fileParsingResult)));
                    return {
                        data: singleDatum,
                        fileIdx,
                        dataIdx: singleDatumIdx,
                        isValid,
                        status: BulkUploadStatus.Pending,
                        messages: [],
                        errors,
                    };
                }),
            };
        });
    };

    validateAllData = () => {
        let a = 1;
        this.handleMutateEditResult(
            -1,
            -1,
            (editResult, columnHeaders) => {
                const columns = this.props.handler.getColumns(columnHeaders);
                const [isValid, errors] = validateData<T>(editResult.data, columns);
                return {
                    ...editResult,
                    isValid,
                    errors,
                    ...(errors.length > 0 ? { messages: [] } : {}),
                };
            },
            false
        );
        setTimeout(this.setHeaderComponents, 10);
    };

    // MUTATE FUNCTIONS

    // mutate the fileParsingEditResults for the file with the given fileIdx
    handleMutateFile = (fileIdx: number, mutateResult: (t: FileParseBulkUploadEditResult<T>) => FileParseBulkUploadEditResult<T>, validateAfterUpdate: boolean = true): void => {
        this.setState(
            (prevState) => {
                const fileParsingEditResults = prevState.fileParsingEditResults.map((editingFileParsingResult, editingFileParsingResultIdx) => {
                    if (editingFileParsingResultIdx === fileIdx) {
                        return mutateResult(editingFileParsingResult);
                    }
                    return editingFileParsingResult;
                });
                return { fileParsingEditResults };
            },
            validateAfterUpdate ? this.validateAllData : undefined
        );
    };

    // mutate the editingData for the file with the given fileIdx
    // if dataIdx is -1, then mutate all data for the file
    handleMutateEditResult = (
        fileIdx: number,
        dataIdx: number,
        mutateResult: (t: SingleFileParseBulkUploadEditResult<T>, columnHeaders?: string[]) => SingleFileParseBulkUploadEditResult<T>,
        validateAfterUpdate: boolean = true
    ): void | Promise<void> => {
        this.setState(
            (prevState) => {
                // loop through all fileParsingResults and update the editingData for the file with the given fileIdx
                const fileParsingEditResults = prevState.fileParsingEditResults.map((editingFileParsingResult, editingFileParsingResultIdx) => {
                    // update all data for the file
                    // only update the editingData for the file with the given fileIdx
                    if (editingFileParsingResultIdx === fileIdx || fileIdx === -1) {
                        return {
                            ...editingFileParsingResult,
                            editResults: editingFileParsingResult.editResults.map((editResult, editResultIdx) => {
                                // update the editingData, only for the data with the given dataIdx
                                if (editResult.dataIdx === dataIdx || dataIdx === -1) {
                                    editResult = mutateResult(editResult, editingFileParsingResult.fileParsingResult.columnHeaders);
                                    return editResult;
                                }
                                return editResult;
                            }),
                        };
                    }
                    return editingFileParsingResult;
                });
                return { fileParsingEditResults };
            },
            validateAfterUpdate ? this.validateAllData : undefined
        );
    };

    handleFieldChange = (fileIdx: number, dataIdx: number, field: string, value: any) => {
        this.handleMutateEditResult(
            fileIdx,
            dataIdx,
            (editResult) => {
                return {
                    ...editResult,
                    status: BulkUploadStatus.Pending,
                    data: { ...editResult.data, [field]: value },
                };
            },
            true
        );
    };

    handleDeleteRow = (fileIdx: number, dataIdx: number, restore: boolean = false) => {
        this.handleMutateEditResult(fileIdx, dataIdx, (editResult) => {
            // if result is already submitted, then we can't delete it
            const canDelete = ![BulkUploadStatus.Submitted].includes(editResult.status);
            if (!canDelete) {
                window.alert('Cannot delete a submitted row.');
                return editResult;
            }
            return {
                ...editResult,
                status: restore ? BulkUploadStatus.Pending : BulkUploadStatus.Deleted,
            };
        });
    };

    // SUBMIT FUNCTIONS

    // submit the row with the given fileIdx and dataIdx
    handleUnsubmitRow = async (fileIdx: number, dataIdx: number, displayAlert = true) => {
        if (displayAlert && !window.confirm('Unsubmitting will delete data in the database that has already been successfully created. Are you sure?')) {
            return;
        }
        console.log('Unsubmitting row:', fileIdx, dataIdx);

        const editResult = this.state.fileParsingEditResults[fileIdx].editResults[dataIdx];
        const canUnsubmitThisRow = canUnsubmitRow(editResult);

        if (canUnsubmitThisRow) {
            // call submit function on the data
            const deleteResult = await this.props.handler.delete(editResult.created_id as string);
            if (deleteResult.success) {
                this.handleMutateEditResult(
                    fileIdx,
                    dataIdx,
                    (editResult) => {
                        return {
                            ...editResult,
                            status: BulkUploadStatus.Pending,
                            errors: [],
                            messages: ['Unsubmitted successfully.'],
                        };
                    },
                    true
                );
            } else {
                this.handleMutateEditResult(
                    fileIdx,
                    dataIdx,
                    (editResult) => {
                        return {
                            ...editResult,
                            status: BulkUploadStatus.Error,
                            errors: [deleteResult.message],
                            messages: [],
                        };
                    },
                    true
                );
            }
        } else {
            if (displayAlert) window.alert(`Unable to unsubmit ${this.props.handler.type} row.`);
        }
    };

    handleUnsubmitTable = async (fileIdx: number, showAlert = true) => {
        if (showAlert && !window.confirm('Unsubmitting will remove the data from the database. Are you sure?')) {
            return;
        }
        const editResults = this.state.fileParsingEditResults[fileIdx].editResults;
        const submittedResults = getSubmittedResults(editResults);
        const canUnsubmitThisTable = canUnsubmitTable(editResults);
        if (canUnsubmitThisTable) {
            await Promise.all(
                submittedResults.map(async (submittedResult) => {
                    const rowResult = await this.handleUnsubmitRow(submittedResult.fileIdx, submittedResult.dataIdx, false);
                })
            );
        } else {
            // window.alert(`Unable to unsubmit ${this.props.handler.type} table.`);
        }
    };

    // submit the row with the given fileIdx and dataIdx
    handleSubmitRow = async (fileIdx: number, dataIdx: number, displayAlert = true) => {
        console.log('Submitting row:', fileIdx, dataIdx);

        try {
            const fileParsingResult = this.state.fileParsingEditResults[fileIdx].fileParsingResult;
            const editResult = this.state.fileParsingEditResults[fileIdx].editResults[dataIdx];
            const canSubmitThisRow = canSubmitRow(editResult);

            if (canSubmitThisRow) {
                // call submit function on the data
                const columns = this.props.handler.getColumns(this.getColumnOrder(fileParsingResult));
                const createResult = await this.props.handler.create(columns, editResult.data as { [key: string]: any });

                if (createResult.success) {
                    this.handleMutateEditResult(
                        fileIdx,
                        dataIdx,
                        (editResult) => {
                            return {
                                ...editResult,
                                status: BulkUploadStatus.Submitted,
                                errors: [],
                                messages: [createResult.message],
                                created_id: createResult.id,
                            };
                        },
                        true
                    );
                } else {
                    this.handleMutateEditResult(
                        fileIdx,
                        dataIdx,
                        (editResult) => {
                            return {
                                ...editResult,
                                status: BulkUploadStatus.Error,
                                messages: [createResult.message],
                            };
                        },
                        true
                    );
                }
            } else {
                console.log('Unable to submit row.');
            }
        } catch (err) {
            console.log('Error submitting row:', err);
            this.handleMutateEditResult(
                fileIdx,
                dataIdx,
                (editResult) => {
                    return {
                        ...editResult,
                        status: BulkUploadStatus.Error,
                        messages: [(err as any)?.response?.data?.message || 'Error submitting row.'],
                    };
                },
                true
            );
        }
    };

    // submit the table with the given fileIdx
    handleSubmitTable = async (fileIdx: number) => {
        const editResults = this.state.fileParsingEditResults[fileIdx].editResults;
        const pendingResults = getPendingResults(editResults);
        const canSubmitThisTable = canSubmitTable(editResults);
        if (canSubmitThisTable) {
            const results = await Promise.all(
                pendingResults.map(async (pendingResult) => {
                    const rowResult = await this.handleSubmitRow(pendingResult.fileIdx, pendingResult.dataIdx, false);
                    return rowResult;
                })
            );
        } else {
            // window.alert(`Unable to submit ${this.props.handler.type} table.`);
        }
    };

    renderSubmitIconButton = (title: string, onClick: () => void, unsubmit = false, disabled = false, size?: number) => {
        return (
            <IconButton
                title={title}
                faIcon={unsubmit ? faDownload : faUpload}
                color={unsubmit ? 'var(--color-blue)' : 'var(--color-green)'}
                onClick={onClick}
                disabled={disabled}
                size={size}
            />
        );
    };

    renderDeleteIconButton = (title: string, onClick: () => void, restore = false, disabled = false) => {
        return (
            <IconButton
                title={title}
                faIcon={restore ? faTrashRestore : faTrash}
                color={restore ? 'var(--color-blue)' : 'var(--color-dark-red)'}
                onClick={onClick}
                disabled={disabled}
            />
        );
    };

    renderExportResultsCsvButton = () => {
        const editResults = this.state.fileParsingEditResults.map((fileParseBulkUploadEditResult) => fileParseBulkUploadEditResult.editResults).flat();

        // get successful or failed results
        const processedResults = editResults.filter((editResults) => [BulkUploadStatus.Submitted, BulkUploadStatus.Error].includes(editResults.status));

        return (
            <button
                disabled={processedResults.length === 0}
                onClick={async () => {
                    const csvContent = generateOutputCsv(
                        this.state.fileParsingEditResults.map((fileParseBulkUploadEditResult) => fileParseBulkUploadEditResult.editResults).flat()
                    );
                    // const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
                    // saveAs(blob, `${this.props.handler.type}_upload_results.csv`);
                    saveCsv(csvContent, `${this.props.handler.type}_upload_results.csv`);
                }}
                style={{
                    whiteSpace: 'nowrap',
                    padding: '5px 10px',
                }}
            >
                Export Results
            </button>
        );
    };

    renderSingleTableHeader = (fileParseBulkUploadEditResult: FileParseBulkUploadEditResult<T>, tableIdx: number) => {
        const fileParsingResult = fileParseBulkUploadEditResult.fileParsingResult;
        const editResults = fileParseBulkUploadEditResult.editResults;

        const columns = this.props.handler.getColumns(this.getColumnOrder(fileParsingResult));

        const canDeleteThisTable = canDeleteTable(editResults);
        const canRestoreThisTable = canRestoreTable(editResults);
        const canSubmitThisTable = canSubmitTable(editResults);
        const canUnsubmitThisTable = canUnsubmitTable(editResults);

        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '10px',
                    // width: '90%',
                }}
            >
                <div style={{ fontSize: '18px' }}>
                    File {tableIdx + 1}: <strong>{fileParseBulkUploadEditResult.fileParsingResult.file.name}</strong>
                </div>
                <div className={styles.tableButtons}>
                    {this.renderSubmitIconButton(
                        'Submit Table',
                        async () => {
                            if (!canSubmitThisTable) return;
                            return await this.handleSubmitTable(tableIdx);
                        },
                        false,
                        !canSubmitThisTable
                    )}

                    {this.renderSubmitIconButton(
                        'Unsubmit Table',
                        async () => {
                            if (!canUnsubmitThisTable) return;
                            return await this.handleUnsubmitTable(tableIdx);
                        },
                        true,
                        !canUnsubmitThisTable
                    )}

                    {/* delete whole table */}
                    {this.renderDeleteIconButton(
                        canDeleteThisTable ? 'Delete All Table Rows' : 'Restore All Table Rows',
                        () => {
                            const shouldDelete = canDeleteThisTable;
                            this.handleMutateFile(tableIdx, (fileParseBulkUploadEditResult) => {
                                const editResults = fileParseBulkUploadEditResult.editResults;
                                editResults.forEach((editResult) => {
                                    // delete/restore all except submitted rows
                                    if ([BulkUploadStatus.Deleted, BulkUploadStatus.Pending, BulkUploadStatus.Error].includes(editResult.status)) {
                                        editResult.status = shouldDelete ? BulkUploadStatus.Deleted : BulkUploadStatus.Pending;
                                    }
                                });
                                return fileParseBulkUploadEditResult;
                            });
                        },
                        !canDeleteThisTable,
                        !(canDeleteThisTable || canRestoreThisTable)
                    )}
                </div>
            </div>
        );
    };

    getColumnOrder = (fileParsingResult: FileParseResult<T>): string[] => {
        return fileParsingResult.columnHeaders || (this.props.handler.columnOrder as string[]);
    };

    switchColumns = async (fileParseBulkUploadEditResult: FileParseBulkUploadEditResult<T>, fileIdx: number, source: string, target: string) => {
        const hasAnySubmitted = fileParseBulkUploadEditResult.editResults.some((editResult) => editResult.status === BulkUploadStatus.Submitted);

        // if any have been submitted, warn they will be lost
        if (hasAnySubmitted) {
            if (!window.confirm('Switching columns will reset all submitted data. Are you sure?')) {
                return;
            }
        } else {
        }

        const fileParsingResult = fileParseBulkUploadEditResult.fileParsingResult;

        // reorder the columns in the column headers
        const newColumnHeaders = reorderColumns(source, target, this.getColumnOrder(fileParsingResult));

        const thisFileTextResult = this.props.fileTextResults[fileIdx];

        // parse the text file result with the new column headers
        const newFileParseResult = (await this.props.getFileParsingResults([thisFileTextResult], newColumnHeaders))[0] as FileParseResult<T>;

        // set the new column headers in the fileParsingResult
        newFileParseResult.columnHeaders = newColumnHeaders;

        // reparse the data for this table from the text
        const newEditResults = this.initializeEditingData([newFileParseResult])[0].editResults;

        // save reordered columns to this table's file object
        this.handleMutateFile(fileIdx, (thisFileParseBulkUploadEditResult) => {
            // save the new column headers to the file object
            thisFileParseBulkUploadEditResult.fileParsingResult = newFileParseResult;

            // save the new edit results to the file object
            thisFileParseBulkUploadEditResult.editResults = newEditResults;

            return thisFileParseBulkUploadEditResult;
        });

        setTimeout(() => {
            this.validateAllData();
        }, 100);
        // make sure to pass the columns from file objects, whenever calling the handler functions for getColumns(<columnOrder[]>) and parseSingleCsvLine()
    };

    renderSingleTable = (fileParseBulkUploadEditResult: FileParseBulkUploadEditResult<T>, tableIdx: number) => {
        const columnOrder = this.getColumnOrder(fileParseBulkUploadEditResult.fileParsingResult);
        const columns = this.props.handler.getColumns(columnOrder);
        // const { columns } = this.props;
        const fileParsingResult = fileParseBulkUploadEditResult.fileParsingResult;
        const editResults = fileParseBulkUploadEditResult.editResults;

        return (
            <div className={styles.tableContainer}>
                <div style={{ marginTop: '20px' }} />
                {this.renderSingleTableHeader(fileParseBulkUploadEditResult, tableIdx)}
                <div className={styles.table}>
                    <table key={`table_${tableIdx}`}>
                        <thead>
                            <tr>
                                <DraggableColumnHeaders
                                    columns={columns}
                                    onDrop={(source: string, target: string) => {
                                        this.switchColumns(fileParseBulkUploadEditResult, tableIdx, source, target);
                                    }}
                                />
                                <th>Is Valid?</th>
                                <th>Delete</th>
                                <th>Submit</th>
                                <th>Status</th>
                                <th>Messages</th>
                            </tr>
                        </thead>

                        <tbody>
                            {editResults.map((editResult, editResultIdx) => {
                                // pull out the properties from the editResult
                                let { data, fileIdx, dataIdx, isValid, status, messages, errors } = editResult;

                                const canDeleteThisRow = canDeleteRow(editResult);
                                const canRestoreThisRow = canRestoreRow(editResult);
                                const canSubmitThisRow = canSubmitRow(editResult);
                                const canUnsubmitThisRow = canUnsubmitRow(editResult);

                                const canEditRow = [BulkUploadStatus.Pending, BulkUploadStatus.Error].includes(status);

                                // get the color class for the row status
                                const rowStatusColorClass =
                                    status === BulkUploadStatus.Submitted
                                        ? styles.green
                                        : status === BulkUploadStatus.Deleted
                                          ? styles.red
                                          : status === BulkUploadStatus.Pending
                                            ? styles.orange
                                            : '';

                                const tableDataElements = Object.entries(columns).map(([fieldName, columnData]) => {
                                    const value: string = data[fieldName as keyof typeof data]?.toString() || '';
                                    return (
                                        <td key={fieldName}>
                                            <div
                                                style={{
                                                    ...columnData.style,
                                                }}
                                                title={value}
                                            >
                                                {columnData.dataType === BulkUploadDataType.date ? (
                                                    <UTCDatePicker
                                                        placeholderText={'MM/DD/YYYY'}
                                                        dateFormat="MM/dd/yyyy"
                                                        selected={value ? TimelessDate.parse(value) : null}
                                                        onChange={(date: TimelessDate | null) => {
                                                            this.handleFieldChange(tableIdx, dataIdx, fieldName, date?.toISOString());
                                                        }}
                                                        disabled={!canEditRow}
                                                    />
                                                ) : columnData.dataType === BulkUploadDataType.option ? (
                                                    <Select
                                                        options={columnData.getEnumOptions!(data).map((option) => ({ value: option, label: option }))}
                                                        value={{ value, label: value }}
                                                        onChange={(selectedOption) => {
                                                            // if required and no option selected, return
                                                            if (columnData.required && !selectedOption) return;
                                                            // change field value
                                                            this.handleFieldChange(tableIdx, dataIdx, fieldName, selectedOption?.value);
                                                        }}
                                                        isDisabled={!canEditRow}
                                                        menuPlacement="bottom"
                                                        maxMenuHeight={180}
                                                    />
                                                ) : columnData.dataType === BulkUploadDataType.boolean ? (
                                                    <Select
                                                        options={[
                                                            { value: 'true', label: 'Yes' },
                                                            { value: 'false', label: 'No' },
                                                        ]}
                                                        value={{ value: value === 'true' ? 'Yes' : 'No', label: value === 'true' ? 'Yes' : 'No' }}
                                                        onChange={(selectedOption) => {
                                                            if (selectedOption === null || selectedOption.value === null) return;

                                                            this.handleFieldChange(tableIdx, dataIdx, fieldName, selectedOption.value === 'true' ? true : false);
                                                        }}
                                                        isDisabled={!canEditRow}
                                                        menuPlacement="bottom"
                                                        maxMenuHeight={180}
                                                    />
                                                ) : (
                                                    <input
                                                        type="text"
                                                        style={{
                                                            height: '35px',
                                                            padding: '0 10px',
                                                        }}
                                                        value={value}
                                                        onChange={(e) => {
                                                            // for numbers check if it is a number
                                                            // if (columnData.dataType === 'number' && isNaN(Number(e.target.value))) return;

                                                            this.handleFieldChange(tableIdx, dataIdx, fieldName, e.target.value);
                                                        }}
                                                        disabled={!canEditRow}
                                                    />
                                                )}
                                            </div>
                                        </td>
                                    );
                                });

                                tableDataElements.push(
                                    ...[
                                        <td>{isValid ? <div style={{ color: 'green' }}>Valid</div> : <div style={{ color: 'red' }}>Invalid</div>}</td>,
                                        <td style={{}}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                {this.renderDeleteIconButton(
                                                    canDeleteThisRow ? 'Delete row' : 'Restore row',
                                                    () => this.handleDeleteRow(tableIdx, dataIdx, !canDeleteThisRow),
                                                    !canDeleteThisRow,
                                                    !(canDeleteThisRow || canRestoreThisRow)
                                                )}
                                            </div>
                                        </td>,
                                        <td>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                {this.renderSubmitIconButton(
                                                    canUnsubmitThisRow ? 'Unsubmit row' : 'Submit row',
                                                    canUnsubmitThisRow ? () => this.handleUnsubmitRow(tableIdx, dataIdx) : () => this.handleSubmitRow(tableIdx, dataIdx),
                                                    canUnsubmitThisRow,
                                                    !(canUnsubmitThisRow || canSubmitThisRow)
                                                )}
                                            </div>
                                        </td>,
                                        <td className={rowStatusColorClass}>{status}</td>,
                                        <td
                                            style={{
                                                minWidth: '300px',
                                            }}
                                        >
                                            {messages.map((message) => (
                                                <div key={message} style={{}}>
                                                    {message}
                                                </div>
                                            ))}
                                            {errors.map((error) => (
                                                <div key={error} style={{ color: 'red' }}>
                                                    {error}
                                                </div>
                                            ))}
                                        </td>,
                                    ]
                                );

                                const renderTableRow = (elements: JSX.Element[]) => {
                                    return (
                                        <tr
                                            key={`table_${tableIdx}_data_${dataIdx}`}
                                            className={`${
                                                status === BulkUploadStatus.Submitted
                                                    ? styles.submittedRow
                                                    : status === BulkUploadStatus.Deleted
                                                      ? styles.deletedRow
                                                      : status === BulkUploadStatus.Error
                                                        ? styles.erroredRow
                                                        : styles.pendingRow
                                            }`}
                                        >
                                            {elements}
                                        </tr>
                                    );
                                };

                                return renderTableRow(tableDataElements);
                            })}
                        </tbody>
                    </table>
                </div>

                {fileParseBulkUploadEditResult.errors && fileParseBulkUploadEditResult.errors.length > 0 && (
                    <div style={{ padding: '10px', color: 'red' }}>
                        {fileParseBulkUploadEditResult.errors.map((error) => (
                            <div key={error}>{error}</div>
                        ))}
                    </div>
                )}
            </div>
        );
    };

    setHeaderComponents = () => {
        this.props.addHeaderComponent('bulk-uploader-global-buttons', {
            renderChildren: () => {
                const canSubmitSome = this.state.fileParsingEditResults.some((fileParseBulkUploadEditResult) => canSubmitTable(fileParseBulkUploadEditResult.editResults));

                const canUnsubmitSome = this.state.fileParsingEditResults.some((fileParseBulkUploadEditResult) => canUnsubmitTable(fileParseBulkUploadEditResult.editResults));

                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {this.renderExportResultsCsvButton()}

                        {this.renderSubmitIconButton(
                            'Submit All',
                            async () => {
                                let fileIdx = 0;
                                for (const fileParsingEditResult of this.state.fileParsingEditResults) {
                                    if (canSubmitTable(fileParsingEditResult.editResults)) {
                                        await this.handleSubmitTable(fileIdx);
                                    } else {
                                        window.alert(`Unable to submit table for the file ${fileParsingEditResult.fileParsingResult.file.name}.`);
                                    }
                                    fileIdx++;
                                }

                                this.setHeaderComponents();
                            },
                            false,
                            !canSubmitSome,
                            30
                        )}

                        {this.renderSubmitIconButton(
                            'Unsubmit All',
                            async () => {
                                if (!window.confirm('Unsubmitting will remove the data from the database. Are you sure?')) {
                                    return;
                                }

                                let fileIdx = 0;
                                for (const fileParsingEditResult of this.state.fileParsingEditResults) {
                                    if (canUnsubmitTable(fileParsingEditResult.editResults)) {
                                        await this.handleUnsubmitTable(fileIdx, false);
                                    } else {
                                        window.alert(`Unable to unsubmit table for the file ${fileParsingEditResult.fileParsingResult.file.name}.`);
                                    }
                                    fileIdx++;
                                }

                                this.setHeaderComponents();
                            },
                            true,
                            !canUnsubmitSome,
                            30
                        )}
                    </div>
                );
            },
            style: {
                right: '150px',
                top: '-10px',
            },
        });
    };

    removeHeaderComponents = () => {
        this.props.removeHeaderComponent('bulk-uploader-global-buttons');
    };

    render() {
        return (
            <div className={styles.main}>
                {this.state.fileParsingEditResults.map((fileParseBulkUploadEditResult, fileIdx) => this.renderSingleTable(fileParseBulkUploadEditResult, fileIdx))}
            </div>
        );
    }
}
