import { FileParseResult, FileTextResult, FileTypes } from '../../../../constants/file';
import BulkUploadHandler, { BulkUploadHandlerCreateType, BulkUploadHandlerConfig } from './BulkUploadHandler';
import sharedFields, { isObjectId, sharedFieldEnum } from '../types/sharedFields';
import { BulkUploadColumn, BulkUploadRequestResult, BulkUploadType } from '../types/bulkUploadTypes';
import apiClient from '../../../../apiClient';
import validator from 'validator';
import isExtendedAscii from '../../../../utilities/string/isExtendedAscii';
import { getInvestmentSubtypes, getInvestmentTypes } from '../../../../pages/Account/AddManualInvestmentStages/investmentTypeConstants';
import { Currency } from '../../../../types/Currency';
import { InvestmentStatuses } from '../../../../types/Investment';
import api from '../../../../api';

export interface InvestmentMasterUploadHandlerConfig extends BulkUploadHandlerConfig {}

export interface InvestmentMasterUploadHandlerCreateType extends BulkUploadHandlerCreateType {
    ticker?: string;
    name?: string;
    asset_manager?: string;
    description?: string;
    type?: string;
    sub_type?: string;
    website?: string;

    currency?: string;
    price?: string;
    exchange?: string;
    vintage_year?: string;
    status?: string;

    twitter?: string;
    facebook?: string;
    linkedin?: string;
    instagram?: string;

    contacts?: string; // slash separated list of contact ids
}

const investmentTypes = getInvestmentTypes();
const investmentTypeNames = investmentTypes.map((type) => type.name);
const investmentSubTypeNames = investmentTypes.map((type) => type.types || []).flat();

// enum fields
const investmentTypeField = sharedFieldEnum(investmentTypeNames);
const investmentSubTypeField = sharedFieldEnum(investmentSubTypeNames);
const currencyField = sharedFieldEnum(Object.values(Currency));
const statusField = sharedFieldEnum(Object.values(InvestmentStatuses));

class InvestmentMasterUploadHandler extends BulkUploadHandler<InvestmentMasterUploadHandlerCreateType, InvestmentMasterUploadHandlerConfig> {
    // The type of the bulk upload, eg. 'user' or 'valuation'
    type = BulkUploadType.investment_master;

    // The base columns that are required for the bulk upload
    base_columns = {
        ticker: {
            ...sharedFields.string,
            displayName: 'Ticker',
            fieldName: 'ticker',
            isValid: (ticker: string) => {
                const isRightLength =
                    validator.isLength(ticker + '', { min: 3, max: 12 }) && validator.isAlphanumeric(ticker + '', 'en-US', { ignore: '-' }) && validator.isUppercase(ticker + '');
                const split = ticker.split('-');
                if (split.length !== 2) return false;
                return isRightLength;
            },
            invalidMessage:
                'The ticker must be between 3 and 12 characters, contain only Uppercase alphanumeric characters, and must be in the format <exchange_ticker_prefix>-<investment_ticker>',
            required: true,
        },
        exchange: {
            ...sharedFields.string,
            displayName: 'Exchange',
            fieldName: 'exchange',
            required: true,
        },
        type: {
            ...investmentTypeField,
            displayName: 'Type',
            fieldName: 'type',
            isValid: (type: string) => investmentTypeNames.map((t) => t.toLowerCase()).includes(type.toLowerCase()),
            format: (type: string) => investmentTypes.find((t) => t.name.toLowerCase() === type.toLowerCase())?.name || '',
            required: true,
        },
        sub_type: {
            ...investmentSubTypeField,
            displayName: 'Sub Type',
            fieldName: 'sub_type',
            isValid: (sub_type: string, data: InvestmentMasterUploadHandlerCreateType) => {
                if (!sub_type) return true;
                const subtypes = data.type ? getInvestmentSubtypes(data.type) : [];
                return subtypes.map((s) => s.toLowerCase()).includes(sub_type.toLowerCase());
            },
            format: (sub_type: string) => investmentSubTypeNames.find((t) => t.toLowerCase() === sub_type.toLowerCase()) || '',
            invalidMessage: 'The sub type must be a valid sub type for the selected investment type',
            getEnumOptions: (data: any) => {
                return data.type ? getInvestmentSubtypes(data.type) || investmentSubTypeField.options || [] : investmentSubTypeField.options || [];
            },
            required: false,
        },
        name: {
            ...sharedFields.string,
            displayName: 'Name',
            fieldName: 'name',
            isValid: (name: string) => !name || (validator.isLength(name + '', { min: 2, max: 140 }) && isExtendedAscii(name + '')),
            invalidMessage: 'The name must be between 2 and 140 characters and contain only ascii characters',
            required: false,
        },
        asset_manager: {
            ...sharedFields.object_id,
            displayName: 'Asset Manager',
            fieldName: 'asset_manager',
            required: false,
        },
        description: {
            ...sharedFields.string,
            displayName: 'Description',
            fieldName: 'description',
            required: false,
        },
        website: {
            ...sharedFields.string,
            displayName: 'Website',
            fieldName: 'website',
            isValid: (website: string) => !website || validator.isURL(website + '', { require_protocol: true }),
            invalidMessage: 'The website must be a valid URL',
            required: false,
        },
        currency: {
            ...currencyField,
            displayName: 'Currency',
            fieldName: 'currency',
            isValid: (currency: string) =>
                Object.values(Currency)
                    .map((value) => value.toLowerCase())
                    .includes(currency.toLowerCase()),
            format: (currency: string) => Object.keys(Currency).find((key) => Currency[key as keyof typeof Currency].toLowerCase() === currency.toLowerCase()) || '',
            required: false,
        },
        price: {
            ...sharedFields.string,
            displayName: 'Price',
            fieldName: 'price',
            required: false,
        },
        vintage_year: {
            ...sharedFields.string,
            displayName: 'Vintage Year',
            fieldName: 'vintage_year',
            isValid: (val: string) =>
                !val ||
                validator.isInt(val + '', {
                    min: 1900,
                    max: new Date().getFullYear(),
                }),
            required: false,
        },
        status: {
            ...statusField,
            displayName: 'Status',
            fieldName: 'status',
            isValid: (status: string) =>
                Object.values(InvestmentStatuses)
                    .map((value) => value.toLowerCase())
                    .includes(status.toLowerCase() as InvestmentStatuses),
            format: (status: string) =>
                Object.keys(InvestmentStatuses).find((key) => InvestmentStatuses[key as keyof typeof InvestmentStatuses].toLowerCase() === status.toLowerCase()) || '',
            required: false,
        },
        twitter: {
            ...sharedFields.string,
            displayName: 'Twitter',
            fieldName: 'twitter',
            // isValid: (val: string) => !val || validator.isURL(val + '', { require_protocol: true }),
            // invalidMessage: 'The twitter must be a valid URL',
            required: false,
        },
        facebook: {
            ...sharedFields.string,
            displayName: 'Facebook',
            fieldName: 'facebook',
            // isValid: (val: string) => !val || validator.isURL(val + '', { require_protocol: true }),
            // invalidMessage: 'The facebook must be a valid URL',
            required: false,
        },
        linkedin: {
            ...sharedFields.string,
            displayName: 'Linkedin',
            fieldName: 'linkedin',
            // isValid: (val: string) => !val || validator.isURL(val + '', { require_protocol: true }),
            // invalidMessage: 'The linkedin must be a valid URL',
            required: false,
        },
        instagram: {
            ...sharedFields.string,
            displayName: 'Instagram',
            fieldName: 'instagram',
            // isValid: (val: string) => !val || validator.isURL(val + '', { require_protocol: true }),
            // invalidMessage: 'The instagram must be a valid URL',
            required: false,
        },
        contacts: {
            ...sharedFields.string,
            displayName: 'Contacts',
            fieldName: 'contacts',
            isValid: (val: string) =>
                !val ||
                val
                    .split('/')
                    .map((x) => x.trim())
                    .filter((x) => x)
                    .every((id) => isObjectId(id)),
            invalidMessage: 'The contacts must be a slash separated list of valid ObjectId strings',
            required: false,
        },
    };

    // The order of the columns in the CSV file
    columnOrder = Object.keys(this.base_columns) as (keyof InvestmentMasterUploadHandlerCreateType)[];

    // sort the columns based on the order in columnOrder, or the default order if not provided
    getColumns = (
        columnOrder: (keyof InvestmentMasterUploadHandlerCreateType)[] = this.columnOrder
    ): { [key in keyof InvestmentMasterUploadHandlerCreateType]: BulkUploadColumn } => {
        // Sort the columns based on the given column order or the default order.
        const sortedColumns = this._sortColumns(this.base_columns, columnOrder);
        return sortedColumns;
    };

    // check a single row of data to see if it is valid
    isDataValid = (data: { [key in keyof InvestmentMasterUploadHandlerCreateType]: string }, columnOrder?: (keyof InvestmentMasterUploadHandlerCreateType)[]) => {
        const columns = this.getColumns(columnOrder);
        const isDataValid = this._isColumnDataValid(columns, data, columnOrder);
        // any additional validation goes here
        return isDataValid;
    };

    /**
     * Parses a single line from a CSV file into an object with the correct fields.
     * Does not handle validation.
     * @param {string} line A single line from a CSV file.
     * @returns {Object} An object with the correct fields for the line.
     */
    parseSingleCsvLine = (line: string, columnOrder?: (keyof InvestmentMasterUploadHandlerCreateType)[]): InvestmentMasterUploadHandlerCreateType => {
        // if not enough commas are included, the fields will be empty strings
        const expectedColumns = this.getColumns(columnOrder);
        const parsedValues = this._parseSingleCsvLine(line, columnOrder);

        // set up special conditions based on the config and data type
        return {
            ticker: parsedValues.ticker,
            name: parsedValues.name,
            asset_manager: parsedValues.asset_manager,
            description: parsedValues.description,
            type: parsedValues.type && expectedColumns.type ? expectedColumns.type.format(parsedValues.type) : '',
            sub_type: parsedValues.sub_type && expectedColumns.sub_type ? expectedColumns.sub_type.format(parsedValues.sub_type) : '',
            website: parsedValues.website,
            currency: parsedValues.currency && expectedColumns.currency ? expectedColumns.currency.format(parsedValues.currency) : '',
            price: parsedValues.price,
            exchange: parsedValues.exchange,
            vintage_year: parsedValues.vintage_year,
            status: parsedValues.status && expectedColumns.status ? expectedColumns.status.format(parsedValues.status) : '',
            twitter: parsedValues.twitter,
            facebook: parsedValues.facebook,
            linkedin: parsedValues.linkedin,
            instagram: parsedValues.instagram,
            contacts: parsedValues.contacts,
        };
    };

    // Parse the text file results into a FileParseResult
    parseTextFileResult = async (
        textFileResult: FileTextResult,
        columnOrder?: (keyof InvestmentMasterUploadHandlerCreateType)[]
    ): Promise<FileParseResult<InvestmentMasterUploadHandlerCreateType>> => {
        try {
            const data = textFileResult.lines.map((line) => this.parseSingleCsvLine(line, columnOrder));
            return {
                success: true,
                message: 'File parsed successfully',
                file: textFileResult.file,
                data,
            } as FileParseResult<InvestmentMasterUploadHandlerCreateType>;
        } catch (err: any) {
            return {
                success: false,
                message: `Error parsing file: ${err.message}`,
                file: textFileResult.file,
            } as FileParseResult<InvestmentMasterUploadHandlerCreateType>;
        }
    };

    // Get the notes for the bulk upload type
    getNotes = (): string[] => {
        let notes: string[] = [];
        if (this.config.investment) {
            notes = [...notes];
        }
        return notes;
    };

    // function to create the object in the database from the parsed data
    create = async (
        columnObj: { [key: string]: BulkUploadColumn },
        data: { [key: string]: any },
        columnOrder?: (keyof InvestmentMasterUploadHandlerCreateType)[]
    ): Promise<BulkUploadRequestResult> => {
        // Ensure the data is valid
        if (!this.isDataValid(data, columnOrder)) {
            return { success: false, message: 'Invalid data' };
        }

        try {
            const createBody: InvestmentMasterUploadHandlerCreateType = {
                ticker: data.ticker,
                name: data.name || undefined,
                asset_manager: data.asset_manager || undefined,
                description: data.description || undefined,
                type: data.type,
                sub_type: data.sub_type || undefined,
                website: data.website || undefined,
                currency: data.currency || Currency.USD,
                price: data.price || undefined,
                exchange: data.exchange,
                vintage_year: data.vintage_year || undefined,
                status: data.status || undefined,
            };

            // Add contacts if they exist, from a slash separated list of contact ids.
            if (data.contacts) {
                const contacts = data.contacts
                    .split('/')
                    .map((x: string) => x.trim())
                    .filter((x: string) => x);
                createBody.contacts = contacts;
            }

            // Add socials if they exist
            if (data.twitter || data.facebook || data.linkedin || data.instagram) {
                createBody.socials = {
                    twitter: data.twitter || undefined,
                    facebook: data.facebook || undefined,
                    linkedin: data.linkedin || undefined,
                    instagram: data.instagram || undefined,
                };
            }

            // Attempt to create a single investment_master from the current object.
            // const createRes = await apiClient.investment_masters.create(createBody);
            const createRes = await api.post('/investmentmasters', createBody, {}, true);

            if (createRes.success && createRes.investment?._id) {
                return {
                    success: true,
                    message: 'InvestmentMaster created successfully',
                    id: createRes.investment?._id,
                };
            } else {
                return {
                    success: false,
                    message: `Error creating investment master.`,
                };
            }
        } catch (err) {
            // Log and return null in case of an error.
            console.log('error creating investment master:', err);
            return {
                success: false,
                message: `Error creating investment master: ${(err as any)?.response?.data?.message || (err as any).message}`,
            };
        }
    };

    // function to delete the object from the database
    delete = async (id: string): Promise<BulkUploadRequestResult> => {
        try {
            await api.post(
                `/investmentmasters/delete`,
                {
                    _id: id,
                },
                {},
                true
            );
            return {
                success: true,
                message: 'InvestmentMaster deleted successfully',
            };
        } catch (err) {
            console.log('error deleting investment_master:', err);
            return {
                success: false,
                message: `Error deleting investment_master: ${(err as any)?.response?.data?.message || (err as any).message}`,
            };
        }
    };
}

// getter for the InvestmentMasterUploadHandler
export const getInvestmentMasterUploadHandler: (config: InvestmentMasterUploadHandlerConfig) => InvestmentMasterUploadHandler = (config: InvestmentMasterUploadHandlerConfig) =>
    new InvestmentMasterUploadHandler(config);

export default InvestmentMasterUploadHandler;
