// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import _ from 'lodash';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import '../../styles/widget.css';
import '../../styles/widget-AllocationTable.css';
import formatCurrency from '../../utilities/format/formatCurrency';
import { isFiniteNonzero } from '../../utilities/validation/numericalValidation';
import { EM_DASH } from '../../constants/constantStrings';
import { calcTotalValue } from '../../utilities/calculate/financeCalculations';

class AllocationTable extends Component {
    state = {
        types: [],
    };

    componentDidMount = () => {
        this.loadData();
    };

    componentDidUpdate = (prev) => {
        if (!_.isEqual(this.props.investments, prev.investments)) this.loadData();
    };

    loadData = () => {
        const investments = this.props.investments;
        if (!investments?.length) {
            this.setState({ types: [] });
            return;
        }

        this.props.loading(320, 'allocationTable');

        const investmentTypes = new Set(investments.map((i) => i.type));

        const totalInvested = calcTotalValue(investments);

        const typesInvested = {};

        for (const type of Array.from(investmentTypes)) {
            let typeInvestments = investments.filter((i) => i.type === type);
            let typeInvested = calcTotalValue(typeInvestments);

            let accountPercentage = typeInvested / totalInvested;
            let displayInvested = formatCurrency(typeInvested);
            let displayPercentage = isFiniteNonzero(accountPercentage) ? (100 * accountPercentage).toFixed(1) + '%' : EM_DASH;

            typesInvested[type] = {
                invested: displayInvested,
                percent: displayPercentage,
            };
        }

        // if (!this.props.advisorVue)
        //     if (!investments || !investments.length) return;

        const typeDataArray = [];
        // Now compile allocation %s
        for (const type in typesInvested) {
            typeDataArray.push({
                type: type,
                invested: typesInvested[type].invested,
                percent: typesInvested[type].percent,
            });
        }

        this.setState({ types: typeDataArray });

        this.props.loaded('allocationTable');
    };

    render = () => {
        return (
            <div className="widget allocTblComponent">
                <h1 className="widget-header">Invested by Type</h1>
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Invested</th>
                            <th>% of Account</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.types.map((d, idx) => {
                            return (
                                <tr key={`tr_${idx}`}>
                                    <td>{d.type}</td>
                                    <td>{d.invested}</td>
                                    <td>{d.percent}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    };
}

export default withRouter(AllocationTable);
