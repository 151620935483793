import AccountSearchFilter from '../../../components/Filters/AccountSearchFilter';
import InvestmentSearchFilter from '../../../components/Filters/InvestmentSearchFilter';
import { DocumentOwnershipType } from '../../../constants/documentConstants';
import { capitalizeFirst } from '../../../utilities/format/capitalizeFirst';

export const getDocumentOwnershipFields = ({ userId, ownershipType, onChangeOwnershipType, ownershipIds, onChangeOwnershipIds }: any) => {
    const fields: any = {
        OwnershipType: {
            placeholder: 'Select Ownership Type',
            label: 'Ownership Type',
            fieldName: 'ownership_type',
            type: 'select',
            defaultOptionString: 'User',
            options: Object.values(DocumentOwnershipType),
            optionClearable: true,
            callback: (value: DocumentOwnershipType) => {
                if (value === ownershipType) return;
                onChangeOwnershipType(value);
                // clear ownership ids when ownership type changes
                onChangeOwnershipIds(null);
            },
        },
    };

    if (ownershipType) {
        if (ownershipType === DocumentOwnershipType.account) {
        } else if (ownershipType === DocumentOwnershipType.investment) {
            delete fields.Entity;
        }
        const label = `${capitalizeFirst(ownershipType)}s`;

        const ownershipFilter: any = {};
        if (userId) {
            ownershipFilter.users = [userId];
        }

        fields['OwnershipObjects'] = {
            placeholder: `Select ${label}`,
            label: `${label}`,
            fieldName: 'ownership_ids',
            type: 'custom',
            component: () => {
                return ownershipType === DocumentOwnershipType.account ? (
                    <AccountSearchFilter
                        filter={ownershipFilter}
                        selected={ownershipIds ?? []}
                        onChange={(ownershipIds: any) => {
                            onChangeOwnershipIds(ownershipIds);
                            // updateDocDiff({ ownershipIds });
                        }}
                        defaultOptions={!!userId}
                        width={'100%'}
                        isMulti={true}
                    />
                ) : (
                    <InvestmentSearchFilter
                        filter={ownershipFilter}
                        selected={ownershipIds ?? []}
                        onChange={(ownershipIds: any) => {
                            onChangeOwnershipIds(ownershipIds);
                            // updateDocDiff({ ownershipIds });
                        }}
                        showInvestmentLink={true}
                        defaultOptions={!!userId}
                        width={'100%'}
                        isMulti={true}
                    />
                );
            },
        };
    }

    return fields;
};
