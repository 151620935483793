// See LICENSE.md file in project root directory

import axios from 'axios';

import * as openApiClientLib from './openApiClient/index';
import { init as initApm } from '@elastic/apm-rum';
import { VUES } from './constants/constantStrings';

const AUTH_STR = 'AUTH';
const AUTH_OVERRIDE_STR = `${AUTH_STR}_OVERRIDE`;
const AUTH_OVERRIDE_PATH_STR = `${AUTH_OVERRIDE_STR}_PATH`;

// construct API and UI URLs based on the host
let uiUrl, apiUrl, environment;
const hostHas = (x) => window.location.hostname.includes(x);
if (hostHas('localhost')) {
    uiUrl = 'http://localhost:3000';
    apiUrl = 'http://localhost:8001';
    environment = 'local';
} else if (hostHas('dev.altexchange.com')) {
    uiUrl = 'https://app.dev.altexchange.com';
    apiUrl = 'https://api.dev.altexchange.com';
    environment = 'development';
} else if (hostHas('staging.altexchange.com') || hostHas('.altexchange.pages.dev')) {
    uiUrl = `https://${window.location.hostname}`;
    apiUrl = 'https://api.staging.altexchange.com';
    environment = 'staging';
} else {
    // Production
    uiUrl = 'https://app.altexchange.com';
    apiUrl = 'https://api.altexchange.com';
    environment = 'production';
}

const apiUrlV2 = `${apiUrl}/v2`;

let apm;
if (environment !== 'local') {
    apm = initApm({
        // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
        serviceName: 'altx-ui',

        // Set custom APM Server URL (default: http://localhost:8200)
        serverUrl: 'https://0668387b1b9e4ad0af5298fbceecab41.apm.us-central1.gcp.cloud.es.io:443',

        environment: environment,

        // Set service version (required for sourcemap feature)
        serviceVersion: process.env.REACT_APP_VERSION ?? '0.0.1',
    });
}

let headers = {};
// // Try to load authentication credentials from local storage
// try {
//     headers = JSON.parse(localStorage.getItem(AUTH_STR));
// }
// catch (error) {
//     console.log("Could not load cached authentication token.");
//     console.log(error);
// }
// try {
//     let override = JSON.parse(localStorage.getItem(AUTH_OVERRIDE_STR));
//     if (override) {
//         headers = override;
//     }
// }
// catch (error) {
//     console.log("No auth override loaded");
//     console.log(error);
// }

const getHeaders = () => {
    if (headers && headers['Authorization']) return headers;
    return {};
};
const getAccessToken = () => {
    const authHeader = getHeaders()['Authorization'];
    return authHeader ? authHeader.replace('Bearer ', '') : null;
};

const loadAuth = () => {
    try {
        // load authentication credentials from local storage
        let hd = JSON.parse(localStorage.getItem(AUTH_STR));
        // attempt to load override credentials
        let override = JSON.parse(localStorage.getItem(AUTH_OVERRIDE_STR));
        if (override) {
            hd = override;
        }
        headers = hd;
    } catch (error) {
        console.log('Could not load cached authentication token.');
        console.log(error);
    }
    return headers;
};

loadAuth();

const unauth = (reason = undefined) => {
    let override = JSON.parse(localStorage.getItem(AUTH_OVERRIDE_STR));
    let override_path = localStorage.getItem(AUTH_OVERRIDE_PATH_STR);
    localStorage.removeItem(AUTH_OVERRIDE_PATH_STR);
    if (override) {
        localStorage.removeItem(AUTH_OVERRIDE_STR);
    } else {
        localStorage.removeItem(AUTH_STR);
    }
    let tk = loadAuth();
    if (!tk) {
        let destination = reason ? '/login?reason=' + reason : '/login';
        window.location.href = destination;
    } else if (override_path) {
        window.location.href = override_path;
    } else {
        window.location.href = '/';
    }
    // clean up session storage
    sessionStorage.removeItem('USER');
    sessionStorage.removeItem('ADVISORY_GROUP');
};

class RequestError extends Error {
    constructor(status, statusText, message, data = {}) {
        super(message);
        this.status = status;
        this.statusText = statusText;
        this.data = data;
    }
    // override print
    toString() {
        return JSON.stringify({
            status: this.status,
            statusText: this.statusText,
            message: this.message,
            data: this.data,
        });
    }
}

// Errors must be a map of status codes to callbacks that accept the error response data
const handleError = async (request, errors = {}) => {
    try {
        // attempt the request
        const res = await request();
        return {
            success: true,
            status: res.status,
            statusText: res.statusText,
            data: res.data,
        };
    } catch (error) {
        // Check if the token has expired
        const isOnLoginPage = window.location.pathname.endsWith('/login');
        if (isTokenExpired() && !isOnLoginPage) {
            const decodedToken = decodeToken();
            if (decodedToken.role === VUES.ASSET_MANAGER) {
                window.location.href = `/assetmanager/${decodedToken.id}/login`;
            } else {
                window.location.href = '/login';
            }
        }
        if (axios.isAxiosError(error)) {
            console.log('Axios error encountered', error);
            // Execute error handler based on status code if exists
            const status = error.response?.status;
            if (errors[status]) {
                return errors[status](error.response.data);
            }
            // Default error handling
            throw new RequestError(error.response?.status, error.response?.statusText, error.response?.data?.message ?? error.message, error.response?.data);
        } else {
            console.log('Non-Axios error encountered', error);
            throw error; // Rethrow if it's not an axios error
        }
    }
};

const decodeToken = () => {
    try {
        const token = getHeaders()['Authorization'].split(' ')[1];
        let decode = JSON.parse(atob(token.split('.')[1]));
        return decode;
    } catch (e) {
        return null;
    }
};

const isTokenExpired = () => {
    try {
        const decodedData = decodeToken();
        if (decodedData) {
            // exp is in UTC, but now is local
            const now = new Date();
            const exp = new Date(decodedData.exp * 1000);
            const isExpired = now > exp;
            return isExpired;
        }
    } catch (error) {
        console.log('error checking token expiration', error);
    }
    return false;
};

const paginateApiRoute = async (fetch_function) => {
    let page = 1;
    let results = [];
    let response_data = await fetch_function({
        limit: 100,
        skip: (page - 1) * 100,
    });
    results = results.concat(response_data);
    while (response_data.length === 100) {
        page += 1;
        response_data = await fetch_function({
            limit: 100,
            skip: (page - 1) * 100,
        });
        results = results.concat(response_data);
    }
    return results;
};

const openApiClientConfig = new openApiClientLib.Configuration({
    accessToken: getAccessToken,
    basePath: apiUrl,
});

// Add a response interceptor
axios.interceptors.response.use(
    (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            const isOnLoginPage = window.location.pathname.endsWith('/login');
            if (!isOnLoginPage) {
                const decodedToken = decodeToken();
                if (decodedToken?.role !== VUES.ASSET_MANAGER) {
                    window.location.href = '/login';
                } else {
                    window.location.href = `/assetmanager/${decodedToken.id}/login`;
                }
            }
        }
        // Rethrow the error for further handling if needed
        return Promise.reject(error);
    }
);

const api2 = {
    uiUrl,
    apiUrl,
    environment,

    clear_auth: () => {
        localStorage.removeItem(AUTH_STR);
        localStorage.removeItem(AUTH_OVERRIDE_STR);
        localStorage.removeItem(AUTH_OVERRIDE_PATH_STR);
        loadAuth();
    },

    decodeToken,

    // Set auth headers from account
    auth: (token) => {
        let val = {
            Authorization: 'Bearer ' + token,
        };

        // Save headers to local cache
        localStorage.setItem(AUTH_STR, JSON.stringify(val));
        loadAuth();
    },

    // Clear auth headers
    unauth,

    override: (token, return_path) => {
        if (!return_path) {
            return_path = window.location.href;
        }
        let val = {
            Authorization: 'Bearer ' + token,
        };
        localStorage.setItem(AUTH_OVERRIDE_STR, JSON.stringify(val));
        localStorage.setItem(AUTH_OVERRIDE_PATH_STR, return_path);
        loadAuth();
    },

    openApiClientConfig,

    client: {
        // Export instances of all of the openApiClient apis that are used in the ui
        UserApi: new openApiClientLib.UserApi(openApiClientConfig),
        AccountApi: new openApiClientLib.AccountApi(openApiClientConfig),
        AdminTeamApi: new openApiClientLib.AdminTeamApi(openApiClientConfig),
        CommitmentApi: new openApiClientLib.CommitmentApi(openApiClientConfig),
        ConnectionApi: new openApiClientLib.ConnectionApi(openApiClientConfig),
        ConnectionDataApi: new openApiClientLib.ConnectionDataApi(openApiClientConfig),
        DocumentApi: new openApiClientLib.DocumentApi(openApiClientConfig),
        InvestmentApi: new openApiClientLib.InvestmentApi(openApiClientConfig),
        IntegrationApi: new openApiClientLib.IntegrationApi(openApiClientConfig),
        TransactionApi: new openApiClientLib.TransactionApi(openApiClientConfig),
        ValuationApi: new openApiClientLib.ValuationApi(openApiClientConfig),
        AssetManagerPortalApi: new openApiClientLib.AssetManagerPortalApi(openApiClientConfig),
        RALRequestApi: new openApiClientLib.RALRequestApi(openApiClientConfig),
        RALRequestTemplateApi: new openApiClientLib.RALRequestTemplateApi(openApiClientConfig),
        DefaultDocumentImportSettingApi: new openApiClientLib.DefaultDocumentImportSettingApi(openApiClientConfig),
    },

    paginateApiRoute,

    get: async (endpoint, errors = {}, source = {}) => {
        return await handleError(async () => await axios.get(apiUrlV2 + endpoint, { headers: getHeaders(), cancelToken: source.token }), errors);
    },

    getBlob: async (endpoint, errors = {}, source = {}) => {
        return await handleError(async () => await axios.get(endpoint, { responseType: 'blob' }), errors);
    },

    post: async (endpoint, data = {}, errors = {}) => {
        return await handleError(async () => await axios.post(apiUrlV2 + endpoint, data, { headers: getHeaders() }), errors);
    },

    patch: async (endpoint, data = {}, errors = {}) => {
        return await handleError(async () => await axios.patch(apiUrlV2 + endpoint, data, { headers: getHeaders() }), errors);
    },

    delete: async (endpoint, errors = {}) => {
        return await handleError(async () => await axios.delete(apiUrlV2 + endpoint, { headers: getHeaders() }), errors);
    },
};
export default api2;
