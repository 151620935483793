import api2 from '../../api2';

// if signingName and signingEmail are null, then the user's data will be used
const sendRalToSign = async (ral_request_id, send_silent = false) => {
    try {
        return await api2.client.RALRequestApi.sendRALRequest({
            SendRALRequestRequest: {
                ral_request_id,
                send_silent,
            },
        });
    } catch (e) {
        throw e;
    }
};

export default sendRalToSign;
