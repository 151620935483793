import React, { Component } from 'react';
import '../../styles/HoldButton.css'; // assuming you'll have a stylesheet for the component

class HoldButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPressing: false,
            pressProgress: 0,
        };
        this.startTime = null;
        this.intervalRef = null;
    }

    shouldHold = () => {
        return this.props.holdDuration > 0;
    };

    handleMouseDown = () => {
        this.setState({ isPressing: true });
        this.startTime = Date.now();

        if (this.shouldHold()) {
            this.intervalRef = setInterval(() => {
                const elapsed = Date.now() - this.startTime;
                const pressProgress = Math.min((elapsed / (this.props.holdDuration ?? 2000)) * 100, 100);
                this.setState({ pressProgress });

                if (pressProgress === 100) {
                    this.handleActionTriggered();
                }
            }, 50); // update 20 times a second
        } else {
            this.handleActionTriggered();
        }
    };

    handleMouseUp = () => {
        if (this.intervalRef) {
            clearInterval(this.intervalRef);
            this.intervalRef = null;
        }
        this.setState({ isPressing: false, pressProgress: 0 });
    };

    handleActionTriggered = () => {
        if (this.intervalRef) {
            clearInterval(this.intervalRef);
            this.intervalRef = null;
        }
        this.setState({ isPressing: false, pressProgress: 0 });
        this.props.action();
    };

    render() {
        const { text, className } = this.props;
        const { pressProgress } = this.state;

        const gradientStyle = {
            background: `conic-gradient(rgba(255, 255, 255, 0.5) 0% ${pressProgress}%, transparent ${pressProgress}% 100%)`,
        };

        return (
            <button className={`hold-button ${className}`} onMouseDown={this.handleMouseDown} onMouseUp={this.handleMouseUp} onMouseLeave={this.handleMouseUp}>
                {this.shouldHold() && <div className="tooltip">(hold)</div>}
                {text}
                <div className="hold-progress" style={gradientStyle}></div>
            </button>
        );
    }
}

export default HoldButton;
