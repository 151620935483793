import React from 'react';
import Scrollbars from 'react-custom-scrollbars';

import dateToUTCMidnight from '../../utilities/date/dateToUTCMidnight';

import '../../styles/keyValuePairList.css';

export const KeyValuePairList = (props) => {
    const [entries, setEntries] = React.useState([]);
    const [newEntries, setNewEntries] = React.useState([]);

    // set initial data
    React.useEffect(() => {
        if (!props.data) return;

        // convert each date value to the right format for <input type="date" /> => YYYY-MM-DD
        let data = props.data.map((datum) => {
            props.keyList.forEach((key) => {
                if (key.type === 'delete') return;
                datum[key.field] = key.type === 'date' ? dateToUTCMidnight(datum[key.field]).toISOString() : datum[key.field];
            });
            return datum;
        });

        setEntries(data);
        setNewEntries([]);
        if (props.handleChange) {
            props.handleChange(data);
        }
    }, [JSON.stringify(props.data)]);

    const addNewEntry = () => {
        const newEntry = Object.values(props.keyList)
            .map((key) => key.field)
            .reduce((res, field) => ((res[field] = ''), res), {});
        setNewEntries([...newEntries, newEntry]);
    };

    const getStyle = () => {
        const n = props.keyList.length ?? 0;
        const style = {
            '--kvp-num-keys': n,
            '--kvp-item-width': `calc(${100 / n}% - ${14 * n}px`,
        };
        return style;
    };

    return (
        <div className="kvp_component" style={getStyle()}>
            <div className="kvp_header">
                {props.keyList &&
                    props.keyList.map((key, keyIdx) => (
                        <div className="kvp_headerItem" key={'kvp_headerItem' + keyIdx}>
                            {key.title}
                        </div>
                    ))}
            </div>

            <div className="kvp_container">
                <Scrollbars style={{}}>
                    {entries &&
                        entries.map((entry, entryIdx) => {
                            return (
                                <div className="kvp_datum" key={'kvp_datum' + entryIdx} style={{ display: 'flex', alignItems: 'center' }}>
                                    {props.keyList.map((key, keyIdx) => {
                                        return key.type !== 'delete' ? (
                                            <input
                                                data-date-format="MMMM DD YYYY"
                                                type={key.type}
                                                className={'kvp_newEntry-datumItem ' + (props.errors[key.field + entryIdx]?.length > 0 ? 'kvp_newEntry-dataItem_error' : '')}
                                                key={'kvp_newEntry-datumItem' + keyIdx}
                                                placeholder={key.placeholder}
                                                value={
                                                    key.type === 'date' && entry[key.field] !== ''
                                                        ? dateToUTCMidnight(entry[key.field]).toISOString().split('T')[0]
                                                        : (entry[key.field] ?? '')
                                                }
                                                onChange={(e) => {
                                                    const _entries = JSON.parse(JSON.stringify(entries));
                                                    _entries[entryIdx][key.field] = e.target.value;
                                                    setEntries(_entries);
                                                    const allEntries = _entries.concat(newEntries);
                                                    let _ = props.handleChange?.(allEntries);
                                                }}
                                            />
                                        ) : (
                                            <img
                                                alt="cancel icon"
                                                key={'kvp_newEntry-cancelItem' + keyIdx}
                                                className="kvp_cancel"
                                                src="/images/icons/closeGray.png"
                                                onClick={async (e) => {
                                                    await props?.removeEntry?.(entry?._id);
                                                }}
                                                style={{ width: '20px', height: '20px', cursor: 'pointer' }}
                                            />
                                        );
                                    })}
                                    {/* {props.keyList.map((key, keyIdx) => <div className='kvp_datumItem' key={'kvp_datumKey' + keyIdx}>
                                {key.format ? key.format(datum[key.field]) : datum[key.field]}
                            </div>)} */}
                                </div>
                            );
                        })}
                </Scrollbars>
            </div>

            <div className="kvp_newEntry-container">
                <div className="kvp_newEntry-header">
                    <div>New Entry</div>
                    <img src="/images/icons/plus.png" onClick={() => addNewEntry()} />
                </div>
                {newEntries.map((entry, entryIdx) => {
                    // console.log('new entry: ', entry)
                    return (
                        <div className="kvp_newEntry-datum" key={'kvp_newEntry-datum' + entryIdx} style={{ display: 'flex', alignItems: 'center' }}>
                            {props.keyList.map((key, keyIdx) => {
                                return key.type !== 'delete' ? (
                                    <input
                                        data-date-format="MMMM DD YYYY"
                                        type={key.type}
                                        className={'kvp_newEntry-datumItem ' + (props.errors[key.field + entryIdx]?.length > 0 ? 'kvp_newEntry-dataItem_error' : '')}
                                        key={'kvp_newEntry-datumItem' + keyIdx}
                                        placeholder={key.placeholder}
                                        value={
                                            key.type === 'date' && entry[key.field] !== ''
                                                ? dateToUTCMidnight(entry[key.field]).toISOString().split('T')[0]
                                                : (entry[key.field] ?? '')
                                        }
                                        onChange={(e) => {
                                            const _newEntries = JSON.parse(JSON.stringify(newEntries));
                                            _newEntries[entryIdx][key.field] = e.target.value;
                                            setNewEntries(_newEntries);
                                            const allEntries = entries.concat(_newEntries);
                                            let _ = props.handleChange?.(allEntries);
                                        }}
                                    />
                                ) : (
                                    <img
                                        alt="cancel icon"
                                        key={'kvp_newEntry-cancelItem' + keyIdx}
                                        className="kvp_cancel"
                                        src="/images/icons/closeGray.png"
                                        onClick={(e) => {
                                            const updatedNewEntries = JSON.parse(JSON.stringify(newEntries));
                                            updatedNewEntries.splice(entryIdx, 1);
                                            setNewEntries(updatedNewEntries);
                                            const allEntries = entries.concat(updatedNewEntries);
                                            let _ = props.handleChange?.(allEntries);
                                        }}
                                        style={{ width: '20px', height: '20px', cursor: 'pointer' }}
                                    />
                                );
                            })}
                        </div>
                    );
                })}
                {newEntries.length > 0 && <div style={{ clear: 'both', height: 14 }} />}
            </div>
        </div>
    );
};
