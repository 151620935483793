import { AdvisoryGroup } from './AdvisoryGroup';
import { AssetManager } from './AssetManager';
import { DocumentType } from './Document';
import { Integration } from './Integration';

export enum DocumentImportSetting {
    Enabled = 'enabled',
    Disabled = 'disabled',
    Unset = 'unset',
}

export interface DocumentTypeSettings {
    CapitalCall: 'enabled' | 'disabled';
    Distribution: 'enabled' | 'disabled';
    NetCapitalCall: 'enabled' | 'disabled';
    NetDistribution: 'enabled' | 'disabled';
    HedgeFund: 'enabled' | 'disabled';
    Statement: 'enabled' | 'disabled';
    TaxDocument: 'enabled' | 'disabled';
}

export const ExtractionEnabledDocumentTypes = [
    DocumentType.CapitalCall,
    DocumentType.Distribution,
    DocumentType.NetCapitalCall,
    DocumentType.NetDistribution,
    DocumentType.HedgeFund,
    DocumentType.Statement,
    DocumentType.TaxDocument,
].sort() as DocumentType[];

export interface DefaultDocumentImportSetting {
    integration?: string | Integration;
    asset_manager?: string | AssetManager; // Assuming Schema.Types.ObjectId is represented as string in TypeScript
    advisory_group?: string | AdvisoryGroup;
    global_default_extraction_setting: 'enabled' | 'disabled' | 'unset';
    global_default_auto_sort_setting: 'enabled' | 'disabled' | 'unset';
    global_default_transaction_setting: 'enabled' | 'disabled' | 'unset';
    default_transaction_settings: { [P in keyof typeof ExtractionEnabledDocumentTypes]: DocumentImportSetting };
    //   default_transaction_settings: { [key: string]: 'enabled' | 'disabled' };
    created_at: Date;
    updated_at: Date;
    deleted_at?: Date;
}
