export interface RALRequestUser {
    _id: string;
    name: string;
}

export interface RALRequestOwnership {
    _id: string;
    name: string;
    type: string;
    currency: string;
    user: string | RALRequestUser | null;
    account: string | { _id: string; name: string } | null;
}

export interface RALRequestSignedRal {
    _id: string;
    name: string;
    status: string;
}

export interface RALRequestSignatory {
    first_name: string;
    last_name: string;
    role: string;
    email: string;
}

export interface RALRequestTokens {
    [key: string]: string;
}

export interface RALRequestRalTemplate {
    _id: string;
    name: string;
}

export enum RALPandaDocStatus {
    Unstarted = 'unstarted',
    Uploaded = 'document.uploaded',
    Draft = 'document.draft',
    Sent = 'document.sent',
    Viewed = 'document.viewed',
    WaitingApproval = 'document.waiting_approval',
    Rejected = 'document.rejected',
    Approved = 'document.approved',
    WaitingPay = 'document.waiting_pay',
    Paid = 'document.paid',
    Completed = 'document.completed',
    Voided = 'document.voided',
    Declined = 'document.declined',
}

export const RAL_STATUSES_WITH_URL = [RALPandaDocStatus.Sent, RALPandaDocStatus.Viewed, RALPandaDocStatus.Completed, RALPandaDocStatus.Declined];

export interface RALRequest {
    _id: string;
    user: string | RALRequestUser | null;
    ownership_ids: (string | RALRequestOwnership | null)[];
    ownership_type: string;
    signed_ral: string | RALRequestSignedRal | null;
    signatories: RALRequestSignatory[] | null;
    tokens: RALRequestTokens;
    ral_template: string | RALRequestRalTemplate | null;
    ral_panda_doc_id: string;
    ral_panda_doc_status: RALPandaDocStatus;
    sent_at: string | null; // Assuming date-time string
    signed_at: string | null; // Assuming date-time string
    created_at: string; // Assuming date-time string
    updated_at: string; // Assuming date-time string
    deleted_at: string | null; // Assuming date-time string
}
