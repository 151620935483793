import React, { useState } from 'react';
import Dropdown from './Dropdown';
import ReactSelect from 'react-select';

/**
 * Enum for dropdown types.
 */
export enum DropdownType {
    Select = 'select',
    Dropdown = 'dropdown',
}

/**
 * Interface for dropdown option.
 * @template T The type of the option value.
 */
interface Option<T = any> {
    label: string;
    value: T;
}

/**
 * Props for the ClickableDropdown component.
 * @template T The type of the option value.
 */
interface ClickableDropdownProps<T = any> {
    options: T[]; // Array of options for the dropdown.
    selection: T; // Currently selected option.
    select: (optionValue: T) => void; // Function to call when an option is selected.
    style: React.CSSProperties; // Style to apply to the dropdown control.
    containerStyle: React.CSSProperties; // Style to apply to the dropdown container.
    closeOnSelect?: boolean; // Whether to close the dropdown when an option is selected.
    getOptionLabel?: (option: T) => string; // Function to get the label for an option.
    dropdownType?: DropdownType; // The type of dropdown to render.
}

/**
 * A dropdown component that can be toggled open or closed, allowing the user to select from a list of options.
 *
 * This component takes several props:
 * - `options` (Option[]): Array of options for the dropdown.
 * - `selection` (string): Currently selected option.
 * - `select` (function): Callback function to call when an option is selected.
 * - `style` (React.CSSProperties): Style to apply to the dropdown control.
 * - `containerStyle` (React.CSSProperties): Style to apply to the dropdown container.
 * - `closeOnSelect` (boolean): Optional. Defaults to true. Whether to close the dropdown when an option is selected.
 * - `getOptionLabel` (function): Optional. Function to get the label for an option.
 * - `dropdownType` (DropdownType): Optional. Defaults to DropdownType.Select. The type of dropdown to render.
 */
const ClickableDropdown = <T = any,>({
    options,
    selection,
    select,
    style,
    containerStyle,
    closeOnSelect = true,
    getOptionLabel,
    dropdownType = DropdownType.Select,
}: ClickableDropdownProps<T>) => {
    const [open, setOpen] = useState(false);

    const hasOptions = options && options.length > 0;
    const showDropdown = open && hasOptions;

    /**
     * Renders a ReactSelect dropdown.
     */
    const renderSelect = () => {
        const selectOptions = options.map((option) => ({
            value: option,
            label: getOptionLabel ? getOptionLabel(option) : String(option),
        }));

        return (
            <ReactSelect
                value={selectOptions.find((option) => option.value === selection)}
                onChange={(selectedOption) => {
                    if (selectedOption) {
                        select(selectedOption.value);
                        if (closeOnSelect) setOpen(false);
                    }
                }}
                options={selectOptions}
                styles={{
                    control: (base) => ({
                        ...base,
                        minWidth: 'max-content',
                        ...style,
                    }),
                }}
            />
        );
    };

    /**
     * Renders a custom Dropdown component.
     */
    const renderDropdown = () => {
        return (
            <Dropdown
                options={options}
                selection={selection}
                disableDefaultOption={true}
                select={(optionValue: any) => {
                    select(optionValue);
                    if (closeOnSelect) setOpen(false);
                }}
                style={style}
            />
        );
    };

    return (
        <div style={{ width: 'min-content', whiteSpace: 'nowrap', ...containerStyle }}>
            {!showDropdown ? (
                <span
                    className="a"
                    style={{ textDecoration: 'none' }}
                    onClick={() => {
                        setOpen(true);
                    }}
                >
                    {getOptionLabel ? getOptionLabel(selection) : String(selection)}
                </span>
            ) : (
                <>{dropdownType === DropdownType.Dropdown ? renderDropdown() : renderSelect()}</>
            )}
        </div>
    );
};

export default ClickableDropdown;
