import React from 'react';
import '../../styles/DocumentToolbar.css';
import { ToolbarAction } from '../../pages/DocumentsPage/Toolbar';

class FloatingButton extends React.Component {
    render() {
        return (
            <div
                className="ToolbarContainer"
                style={{
                    userSelect: 'none',
                    transform: 'none',
                    bottom: 'unset',
                    left: 'unset',
                    right: 'unset',
                    ...this.props.containerStyle,
                }}
            >
                <ToolbarAction
                    className="toolbarTool"
                    onClick={this.props.onClick}
                    children={
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: '1.5rem',
                                color: 'white',
                                ...this.props.buttonStyle,
                            }}
                        >
                            {this.props.children}
                        </div>
                    }
                    disabled={this.props.disabled}
                />
            </div>
        );
    }
}

export default FloatingButton;
