import React from 'react';
import Select from 'react-select';

import '../../styles/objectFilterSelect.css';

export const ObjectFilterSelect = (props) => {
    const options = [
        ...props.objects?.map((obj) => ({
            value: obj,
            label: props.getLabel(obj),
        })),
    ];

    if (!props.noDefaultOption) {
        options.unshift({
            value: null,
            label: props.defaultLabel ?? 'All',
        });
    }

    const displayValue = props.matchProperty
        ? options.find((option) => option.value?.[props.matchProperty] === props.selected?.[props.matchProperty])
        : options.find((option) => option.value === props.selected);

    return (
        <div style={{ position: 'relative', width: props.width, height: props.height, ...props.containerStyle }}>
            <Select
                className={'userFilterSelect-select'}
                placeholder={props.placeholder ?? 'Filter by user'}
                options={options}
                value={displayValue}
                onChange={props.onChange}
                // style={{...props.selectStyle, width: '200px'}}
                styles={{
                    // width
                    control: (provided, state) => ({
                        ...provided,
                        width: props.width,
                        height: props.height,
                    }),
                    menu: (provided, state) => ({
                        ...provided,
                        width: props.width,
                        // height: props.height,
                    }),
                    valueContainer: (provided, state) => ({
                        ...provided,
                        height: `${props.height} !important`,
                        padding: '0 0 0 10px',
                    }),
                    input: (provided, state) => ({
                        ...provided,
                        height: `${props.height} !important`,
                        margin: '0',
                        top: 0,
                    }),
                }}
                filterOption={props.filterOptionFunc}
                isDisabled={props.isDisabled}
            />
        </div>
    );
};
