import React, { Component } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import Dropzone from 'react-dropzone';
import randomAscii from '../../utilities/string/randomAscii';
import api from '../../api';
import api2 from '../../api2';

import dateToUTCMidnight from '../../utilities/date/dateToUTCMidnight';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';
import getTransactionTypes, { findTransactionTypeFromString } from '../../utilities/apiHelpers/getTransactionTypes';
import formatCurrency from '../../utilities/format/formatCurrency';
import { formatUTCDate } from '../../utilities/format/formatDate';

import '../../styles/bulkTransactionUploader.css';

const ALLOWED_FILE_TYPES = ['text/csv', 'application/vnd.ms-excel'];

export default class BulkTransactionUploader extends Component {
    fileRefs = [];

    state = {
        fileTransactionMap: {},
        dragging: false,
    };

    dropDiv = React.createRef();
    draggingOffRequest = React.createRef(false);
    draggingTimeout = null;

    componentDidMount = async () => {
        this.load();
    };

    load = async () => {};

    // this assumes we're in the investment details page and the investments is known
    getCvsColsWithKnownInvestment = () => {
        return {
            type: {
                fieldName: 'type',
                required: true,
                isValid: (val) => {
                    const s = this.state;
                    if (!getTransactionTypes()) {
                        console.log('no transaction types');
                        return false;
                    }
                    const type = findTransactionTypeFromString(val);
                    return !!type;
                },
                format: (val) => {
                    const type = findTransactionTypeFromString(val);
                    return type?.transaction_type_name;
                },
            },
            description: {
                fieldName: 'description',
            },
            amount: {
                fieldName: 'amount',
                required: true,
                isValid: (val) => !isNaN(val),
                format: (val) => parseFloat(val),
            },
            date: {
                fieldName: 'date',
                required: true,
                isValid: (val) => !isNaN(new Date(val)),
                format: (val) => new Date(val),
            },
        };
    };

    // this will include the investment in the CSV file
    getCsvColsWithAnyInvestment = () => {
        return {
            ...this.getCvsColsWithKnownInvestment(),
            investment: {
                fieldName: 'investment',
                required: true,
            },
        };
    };

    // check csv columns against this to make sure they're valid
    // colsToUse is either CSV_COLS_WITH_INVESTMENT or CSV_COLS_WITHOUT_INVESTMENT
    isCsvColValid = (value, colObject) => {
        if (colObject.required && !value) return false;
        return colObject.isValid ? colObject.isValid(value) : true;
    };

    createTransactions = async () => {
        const s = this.state;
        const p = this.props;
        const transactionList = this.getTranListFromMap();

        if (!transactionList.length) {
            window.alert('No transactions to save.');
            return;
        }

        // set transaction type, it was the pretty version
        transactionList.forEach((tran) => {
            const type = findTransactionTypeFromString(tran.type);
            tran.type = type?.transaction_type;
        });

        const bulkCreateReqBody = {
            transactions: transactionList,
        };

        let createdIds = [];
        const updateRes = await Promise.all(
            transactionList.map(async (tran) => {
                try {
                    const createdId = (
                        await api2.client.TransactionApi.createTransaction({
                            CreateTransactionRequest: {
                                ...tran,
                                date: tran.date instanceof Date ? tran.date.toISOString().split('T')[0] : tran.date,
                            },
                        })
                    ).data.transaction_id;
                    createdIds.push(createdId);
                    return true;
                } catch (err) {
                    console.log('error creating transaction', err);
                    return false;
                }
            })
        );

        if (updateRes.every((r) => r)) {
            setDismissableAlert(p.setAlert, 'Success: Transactions created.', false);
            p.reloadData?.();
            p.close();
        } else {
            setDismissableAlert(p.setAlert, 'Error: Transactions not created.', true);
            try {
                await Promise.all(
                    createdIds.map(async (id) => {
                        return await api2.client.TransactionApi.deleteTransaction({
                            transaction_id: id,
                        });
                    })
                );
            } catch (err) {
                console.log('error deleting created transactions', err);
            }
        }
    };

    getTranListFromMap = () => {
        const s = this.state;
        const tranList = [];
        Object.values(s.fileTransactionMap).forEach((tranListToAdd) => {
            tranList.push(...tranListToAdd);
        });
        return tranList;
    };

    hasChangesToSave = () => {
        return this.getTranListFromMap().length > 0;
    };

    renderHeader = (includeDescription = false) => {
        return (
            <>
                <div className="bulkTrans-header-title">Add New Transactions</div>
                {includeDescription && (
                    <>
                        <div style={{ height: '20px' }} />
                        <div style={{ marginBottom: '8px' }}>Drag & Drop your CSV file here</div>
                        <div>or</div>
                    </>
                )}
                {this.renderBrowseFiles()}
                {includeDescription && this.getAllowedTypesString()}
                <br />
                {includeDescription && this.getCSVFormatHelperString()}
            </>
        );
    };

    renderBrowseFiles = () => (
        <div className="a" style={{ marginTop: '8px', marginBottom: '14px' }} onClick={(e) => this.dropDiv.current?.click(e)}>
            Browse Files
        </div>
    );

    renderDragging = () => (
        <div className="bulkTrans-dragging">
            <div className="bulkTrans-docIcon-white" />
            Drop your transactions CSV here
        </div>
    );

    renderInitStage = () => (
        <>
            <div className="bulkTrans-docIcon-blue" />
            {this.renderHeader(true)}
        </>
    );

    renderSaveButton = () => {
        return (
            <button className="drawer-submit-button" onClick={this.createTransactions} style={{ marginTop: '20px', width: '175px' }}>
                Create Transaction(s)
            </button>
        );
    };

    renderDocListStage = () => {
        const s = this.state;
        const tranMap = s.fileTransactionMap;
        const fileCount = Object.keys(tranMap).length;
        const tranCount = Object.values(tranMap).reduce((acc, cur) => acc + (cur?.length || 0), 0);
        return (
            <>
                {this.renderHeader(false)}
                <div className="bulkTrans-docList" style={{ marginTop: '15px', marginBottom: '10px' }}>
                    <div className="bulkTrans-docList-header">
                        <div>
                            {tranCount} transaction{tranCount !== 1 ? 's' : ''} from {fileCount} file{fileCount !== 1 ? 's' : ''}
                        </div>
                    </div>
                    <div className="bulkTrans-docList-body">
                        <Scrollbars>
                            {Object.entries(tranMap).map(([filename, tranList], i) => {
                                return this.renderFileTransactionGrouping(filename, tranList);
                            })}
                        </Scrollbars>
                    </div>
                </div>
                <span>{this.getAllowedTypesString()}</span>
                {this.hasChangesToSave() ? this.renderSaveButton() : null}
            </>
        );
    };

    deleteTransaction = (filename, i) => {
        if (i < -1) return;
        if (i === -1) {
            delete this.state.fileTransactionMap[filename];
            this.setState({ fileTransactionMap: this.state.fileTransactionMap });
            return;
        }
        const fileTransactionMap = { ...this.state.fileTransactionMap };
        const tranList = fileTransactionMap[filename];
        tranList.splice(i, 1);
        if (tranList.length === 0) {
            delete fileTransactionMap[filename];
        } else {
            fileTransactionMap[filename] = tranList;
        }
        this.setState({ fileTransactionMap });
    };

    renderFileTransactionGrouping = (filename, tranList) => {
        return (
            <>
                <div className="bulkTrans-row" key={`bulkDoc_${filename}`}>
                    <div className="bulkTrans-docIcon-blue" style={{ padding: '16px 11px', marginRight: '15px' }} />
                    <div className="bulkTrans-row-name">
                        <strong>{filename}</strong>
                    </div>
                    <div style={{ position: 'absolute', right: 30 }}>
                        <div className="bulkTrans-deleteIcon" style={{ width: '20px', height: '20px', cursor: 'pointer' }} onClick={() => this.deleteTransaction(filename, -1)} />
                    </div>
                </div>
                {tranList.map((tran, i) => {
                    return (
                        <>
                            <div className="bulkTrans-row" key={`bulkDoc_${filename}_${i}`}>
                                <div className="bulkTrans-row-name">
                                    <strong>{tran.type}</strong>
                                    {` of `}
                                    <strong>{formatCurrency(tran.amount, this.props.investment.currency)}</strong>
                                    {` on `}
                                    <strong>{formatUTCDate(tran.date)}</strong>
                                    {!this.props.investment && (
                                        <>
                                            {` for investment `}
                                            <strong>{tran.investment}</strong>
                                        </>
                                    )}
                                </div>
                                <div style={{ position: 'absolute', right: 30 }}>
                                    <div
                                        className="bulkTrans-deleteIcon"
                                        style={{ width: '20px', height: '20px', cursor: 'pointer' }}
                                        onClick={() => this.deleteTransaction(filename, i)}
                                    />
                                </div>
                            </div>
                        </>
                    );
                })}
            </>
        );
    };

    getAllowedTypesString = () => {
        const listStr = ALLOWED_FILE_TYPES.map((t) => (t.includes('/') ? t.split('/')[1] : t)).join(', ');
        return <span style={{ fontSize: '12px' }}>{`Supported formats: ${listStr}`}</span>;
    };

    getCSVFormatHelperString = () => {
        return (
            <>
                <div style={{ fontSize: '14px', marginBottom: '5px' }}>{`CSV format:`}</div>
                {this.props.investment && (
                    <>
                        <div style={{ fontSize: '12px', fontWeight: 'bold' }}>
                            {Object.entries(this.getCvsColsWithKnownInvestment())
                                .map(([key, value]) => value.fieldName)
                                .join(', ')}
                        </div>
                        <div style={{ fontSize: '12px' }}>OR</div>
                    </>
                )}
                <div style={{ fontSize: '12px', fontWeight: 'bold' }}>{`${Object.entries(this.getCsvColsWithAnyInvestment())
                    .map(([key, value]) => value.fieldName)
                    .join(', ')}`}</div>
                {this.props.investment && (
                    <>
                        <div style={{ fontSize: '12px', marginTop: '5px' }}>Note: If you include the investment in the CSV, it must match the investment ID.</div>
                    </>
                )}
            </>
        );
    };

    getTransactionDataFromCSV = async (csvFile) => {
        return await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const text = e.target.result;
                const transDataList = this.parseCSV(text, csvFile.name);
                resolve(transDataList);
            };
            reader.onerror = (e) => {
                console.log('Error reading file', e);
                reject(false);
            };
            reader.readAsText(csvFile);
        });
    };

    parseCSV = (text, fileName) => {
        const lines = text.split('\n');
        const transList = [];
        const errors = [];

        lines.forEach((line, i) => {
            const columns = line
                .split(',')
                .map((c) => c.trim())
                .filter((c) => c);
            if (columns.length === 0) return; // skip empty lines

            const newTransaction = {};

            const useHeadersWithoutInvestment = this.props.investment && columns.length === Object.entries(this.getCvsColsWithKnownInvestment()).length;
            const matchesHeadersWithInvestmentColumnLength = columns.length === Object.entries(this.getCsvColsWithAnyInvestment()).length;
            const useHeadersWithInvestment = matchesHeadersWithInvestmentColumnLength;

            if (useHeadersWithoutInvestment) {
                Object.entries(this.getCvsColsWithKnownInvestment()).forEach(([key, colObj], i) => {
                    const column = columns[i];
                    if (!this.isCsvColValid(column, colObj)) {
                        errors.push(`Invalid value for "${colObj.fieldName}" on line ${i + 1} of "${fileName}"`);
                        return;
                    }
                    newTransaction[colObj.fieldName] = colObj.format ? colObj.format(column) : column;
                });
                newTransaction.investment = this.props.investment._id;
            } else if (useHeadersWithInvestment) {
                Object.entries(this.getCsvColsWithAnyInvestment()).forEach(([key, colObj], i) => {
                    const column = columns[i];
                    if (!this.isCsvColValid(column, colObj)) {
                        errors.push(`Invalid value for "${colObj.fieldName}" on line ${i + 1} of "${fileName}"`);
                        return;
                    }
                    newTransaction[colObj.fieldName] = colObj.format ? colObj.format(column) : column;

                    // if props.investment is present, the investment in the csv has to match the props.investment
                    if (this.props.investment) {
                        if (colObj.fieldName === 'investment' && column !== this.props.investment._id) {
                            errors.push(`Invalid value for "${colObj.fieldName}" on line ${i + 1} of "${fileName}". Must match the investment's _id.`);
                            return;
                        }
                    }
                });
            } else {
                errors.push(`Invalid CSV format on line ${i + 1} of "${fileName}"`);
                return;
            }

            transList.push(newTransaction);
        });

        if (errors.length > 0) {
            console.log('errors', errors);
            window.alert(`Error:\n\n${errors.join('\n\n')}`);
            return null;
        }

        return transList;
    };

    onDrop = async (files) => {
        this.setDragging(false, true);
        const unsupportedFileNames = [];
        const erroredFileNames = [];
        const fileTransactionMap = { ...this.state.fileTransactionMap };

        for (let i = 0; i < files.length; i++) {
            if (ALLOWED_FILE_TYPES.includes(files[i].type)) {
                const transDataList = await this.getTransactionDataFromCSV(files[i]);
                if (!transDataList) {
                    erroredFileNames.push(files[i].name);
                    continue;
                }

                // add to map
                if (fileTransactionMap[files[i].name]) {
                    fileTransactionMap[files[i].name] = [...fileTransactionMap[files[i].name], ...transDataList];
                } else {
                    fileTransactionMap[files[i].name] = transDataList;
                }
            } else {
                unsupportedFileNames.push(files[i].name);
                // remove file from list
                files.splice(i, 1);
                continue;
            }

            files[i].id = randomAscii(15);
        }

        let errStr = '';

        if (unsupportedFileNames.length > 0) {
            errStr += `\nError: ${unsupportedFileNames.join(', ')} ${unsupportedFileNames.length === 1 ? 'is' : 'are'} the wrong file type.`;
        }

        if (errStr) {
            window.alert(errStr);
            return;
        }

        this.setState({ fileTransactionMap });
    };

    // without this timer system, the dragOver and dragLeave functions continuously get called, causing flickering
    setDragging = (dragging, force = false) => {
        if (force) {
            this.setState({ dragging });
            this.draggingOffRequest.current = null;
            clearTimeout(this.draggingTimeout);
        } else {
            // attempt to limit flickering
            const THRESHOLD = 1000;
            const TIMEOUT = 100;
            const nowUtc = dateToUTCMidnight();
            if (dragging) {
                // turn on dragging
                this.setState({ dragging });
                this.draggingOffRequest.current = null;
            } else if (!dragging && this.draggingOffRequest.current && nowUtc.getTime() - this.draggingOffRequest.current > THRESHOLD) {
                // turn off dragging, clear timer and timeout
                this.setState({ dragging });
                this.draggingOffRequest.current = null;
                if (this.draggingTimeout) clearTimeout(this.draggingTimeout);
            } else if (!dragging && !this.draggingOffRequest.current) {
                // reset timer and set timeout
                this.draggingOffRequest.current = nowUtc.getTime();
                if (this.draggingTimeout) clearTimeout(this.draggingTimeout);
                this.draggingTimeout = setTimeout(() => this.setDragging(dragging), TIMEOUT);
            } else {
                // call timeout again
                this.draggingTimeout = setTimeout(() => this.setDragging(dragging), TIMEOUT);
            }
        }
    };

    render = () => {
        const s = this.state;
        const p = this.props;

        return (
            <Dropzone onDrop={this.onDrop} multiple={true} onDragEnter={() => this.setDragging(true)} onDragLeave={() => this.setDragging(false)}>
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps({ className: 'bulkTrans-component' })} ref={this.dropDiv} onMouseOut={!s.dragging ? null : () => this.setDragging(false, true)}>
                        <div onClick={(e) => e.stopPropagation()} className="bulkTrans-innerComponent">
                            <div
                                className="bulkDoc-close"
                                onClick={() => {
                                    if (!this.hasChangesToSave() || window.confirm('You have unsaved changes. Are you sure you want to close?')) p.close();
                                }}
                            />
                            {!this.hasChangesToSave() ? this.renderInitStage() : this.renderDocListStage()}
                            {s.dragging && this.renderDragging()}
                        </div>
                    </div>
                )}
            </Dropzone>
        );
    };
}

export const openBulkTransactionUploader = (openModal, investment, setAlert, reloadData, open = true) => {
    if (!open) openModal();
    return openModal(
        <BulkTransactionUploader investment={investment} close={openModal} setAlert={setAlert} reloadData={reloadData} />,
        true,
        'bulk-upload-modal',
        {
            containerStyle: { position: 'fixed', backgroundColor: 'rgba(42, 66, 102, 0.55)' },
            noClose: true,
        },
        true,
        false
    );
};
