import React, { Component } from 'react';
import api from '../../api';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';
import Multitoggle from './Multitoggle';
import { UserPermissions } from '../../utilities/AdvisorVue/permissions';
import UTCDatePicker from '../Inputs/UTCDatePicker/UTCDatePicker';
import api2 from '../../api2';

const OPTIONS = ['No', 'Yes'];

export default class InvestmentHiddenToggle extends Component {
    render = () => {
        const { investment, loading, loaded, onChange, onChangeDate, setAlert } = this.props;

        const curOption = OPTIONS[Number(investment.hidden)];

        return (
            <>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', ...this.props.containerStyle }}>
                    <div>Hidden Investment</div>
                    <Multitoggle
                        containerStyle={{ width: '70px', marginLeft: '10px' }}
                        options={OPTIONS}
                        selection={curOption}
                        // zIndex={-1}
                        onSelect={async (opt) => {
                            if (!UserPermissions().canUpdateAnyInvestments) return;
                            const newValue = !!OPTIONS.indexOf(opt);
                            if (newValue === investment.hidden) return;

                            loading?.(0, 'hiddenToggleUpdate');
                            try {
                                await api2.client.InvestmentApi.updateInvestment({
                                    investment_id: investment._id,
                                    UpdateInvestmentRequest: { hidden: newValue },
                                });
                                if (setAlert) {
                                    setDismissableAlert(setAlert, `Investment set to ${newValue ? '' : 'Not '}Hidden.`);
                                }
                                await onChange?.(newValue);
                            } catch (err) {
                                setDismissableAlert(setAlert, `Error setting investment to ${newValue ? '' : 'Not '}Hidden.`, true);
                            }
                            loaded?.('hiddenToggleUpdate');
                        }}
                        optionStyle={{ cursor: UserPermissions().canUpdateAnyInvestments ? null : 'default' }}
                    />
                </div>
            </>
        );
    };
}
