import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import validator from 'validator';

import api2 from '../../api2';
import renderInputError from '../../components/renderInputError';
import { renderInputComponentList } from '../../components/DrawerComponents/renderInputComponentList';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';
import isExtendedAscii from '../../utilities/string/isExtendedAscii';

import '../../styles/addEditAdvisorGroupDrawer.css';
import AdvisoryGroupSearchFilter from '../../components/Filters/AdvisoryGroupSearchFilter';

interface AddEditAdminTeamDrawerProps {
    adminTeamId?: string;
    close: () => void;
    reloadData: () => void;
    loading: (progress: number, key: string) => void;
    loaded: (key: string) => void;
    setAlert: (alert: string, alertId?: string | null, alertError?: boolean) => void;
}

interface AdvisoryGroup {
    _id: string;
    name: string;
    plan: {
        _id: string;
        name: string;
    };
}

interface TeamType {
    type: string;
    name: string;
}

interface CommonData {
    name?: string;
    type?: TeamType;
    advisory_groups?: AdvisoryGroup[];
}

interface AddEditAdminTeamDrawerState {
    commonData: CommonData;
    errors: {
        [key: string]: string | undefined;
    };
}

export const TeamTypes: TeamType[] = [
    { type: 'account_management', name: 'Account Management' },
    { type: 'onboarding', name: 'Onboarding' },
];

class AddEditAdminTeamDrawer extends Component<AddEditAdminTeamDrawerProps, AddEditAdminTeamDrawerState> {
    state: AddEditAdminTeamDrawerState = {
        commonData: {},
        errors: {},
    };

    componentDidMount = () => {
        this.load();
    };

    componentDidUpdate = (prevProps: AddEditAdminTeamDrawerProps) => {
        if (!_.isEqual(prevProps.adminTeamId, this.props.adminTeamId)) {
            this.load();
        }
    };

    load = async () => {
        const p = this.props;
        this.props.loading(0, 'AddEditAdminTeamDrawer');

        if (p.adminTeamId) {
            const adminTeam = (await api2.client.AdminTeamApi.getAdminTeam({ admin_team_id: p.adminTeamId, populate_advisory_groups: true })).data.admin_team;

            if (!adminTeam) {
                setDismissableAlert(this.props.setAlert, 'Error loading admin team.', true);
                this.props.loaded('AddEditAdminTeamDrawer');
                this.props.close();
                return;
            }

            this.setState({
                commonData: {
                    name: adminTeam.name,
                    type: TeamTypes.find((type) => type.type === adminTeam.type) ?? TeamTypes[0],
                    advisory_groups: adminTeam.advisory_groups?.map((group: any) => group) ?? [],
                },
            });
        } else {
            this.setState({
                commonData: {
                    name: '',
                    type: TeamTypes[0],
                    advisory_groups: [],
                },
            });
        }

        this.props.loaded('AddEditAdminTeamDrawer');
    };

    getCommonDataFields = () => {
        let fields: any = {};

        fields['Name'] = {
            placeholder: 'Enter Name',
            label: 'Name',
            fieldName: 'name',
            type: 'text',
            required: true,
            isValid: (value: string) => {
                if (!value) return true;
                return validator.isLength(value + '', { min: 2, max: 140 }) && isExtendedAscii(value + '');
            },
            validRequirements: 'Name must be between 2-140 ascii characters',
        };

        fields['Type'] = {
            placeholder: 'Select Type',
            label: 'Type',
            fieldName: 'type',
            type: 'select',
            optionField: 'name',
            options: TeamTypes,
            required: true,
        };
        fields['Advisory Groups'] = {
            type: 'custom',
            component: (
                <AdvisoryGroupSearchFilter
                    filter={undefined}
                    defaultLabel="Select Advisory Group"
                    selected={this.state.commonData.advisory_groups ?? []}
                    onChange={(advisoryGroups) => this.setStateValue('advisory_groups', advisoryGroups, 'commonData')}
                    width={'100%'}
                    isMulti={true}
                    defaultOptions={true}
                />
            ),
            isValid: (value: AdvisoryGroup[]) => {
                if (!value) return true;
                return Array.isArray(value) && value.every((group) => validator.isMongoId(group._id));
            },
            validRequirements: 'Advisory Group IDs must be valid MongoDB Object IDs',
        };

        return fields;
    };

    checkCommonData = (data: CommonData, errors: { [key: string]: string | undefined }) => {
        // check required fields
        Object.values(this.getCommonDataFields()).forEach((field: any) => {
            const fieldValue = data[field.fieldName as keyof CommonData];
            if (field.required && (!fieldValue || (Array.isArray(fieldValue) && fieldValue.length === 0))) {
                errors[field.fieldName] = 'This field is required.';
            }
        });

        // check validity if "isValid" is defined
        Object.values(this.getCommonDataFields()).forEach((field: any) => {
            const fieldValue = data[field.fieldName as keyof CommonData];
            if (fieldValue && field.isValid && !field.isValid(fieldValue)) {
                errors[field.fieldName] = field.validRequirements ?? 'Invalid value.';
            }
        });
    };

    isSafeToSave = (setErrors = true) => {
        let errors: { [key: string]: string | undefined } = {};
        this.checkCommonData(this.state.commonData, errors);
        const isSafe = Object.keys(errors).length === 0;
        if (setErrors) {
            errors.submit = isSafe ? undefined : 'There are missing/invalid required fields.';
            this.setState({ errors: isSafe ? {} : errors });
        }
        return isSafe;
    };

    createOrUpdateAdminTeam = async () => {
        // check field validity and set errors
        if (!this.isSafeToSave()) return;
        this.props.loading(0, 'AddEditAdminTeamDrawer');

        let commonData = JSON.parse(JSON.stringify(this.state.commonData));

        const adminTeamBody = {
            name: commonData.name,
            type: commonData.type,
            advisory_groups: commonData.advisory_groups,
        };

        // create admin team
        if (!this.props.adminTeamId) {
            try {
                const adminTeamUpdateRes = await api2.client.AdminTeamApi.createAdminTeam({
                    CreateAdminTeamRequest: {
                        type: adminTeamBody.type.type,
                        name: adminTeamBody.name,
                        advisory_groups: adminTeamBody.advisory_groups.map((group: any) => group._id),
                    },
                });
                setDismissableAlert(this.props.setAlert, `Created new admin team.`);
                this.props.reloadData();
                this.props.loaded('AddEditAdminTeamDrawer');
                this.props.close();
            } catch (err: any) {
                this.setState({ errors: { submit: err?.response?.data?.message ?? 'Error Creating Admin Team' } });
                setDismissableAlert(this.props.setAlert, 'Error creating new admin team.', true);
                this.props.loaded('AddEditAdminTeamDrawer');
                return;
            }

            // edit existing
        } else {
            try {
                const adminTeamUpdateRes = await api2.client.AdminTeamApi.updateAdminTeam({
                    UpdateAdminTeamRequest: {
                        type: adminTeamBody.type.type,
                        name: adminTeamBody.name,
                        advisory_groups: adminTeamBody.advisory_groups.map((group: any) => group._id),
                    },
                    admin_team_id: this.props.adminTeamId,
                });

                setDismissableAlert(this.props.setAlert, 'Admin team updated.');
                this.props.reloadData();
                this.props.loaded('AddEditAdminTeamDrawer');
                this.props.close();
            } catch (err: any) {
                this.setState({ errors: { submit: err?.response?.data?.message ?? 'Error updating admin team' } });
                setDismissableAlert(this.props.setAlert, 'Error updating admin team.', true);
                this.props.loaded('AddEditAdminTeamDrawer');
            }
        }
    };

    clearError = (keys: (keyof AddEditAdminTeamDrawerState['errors'])[]) => {
        let errors = {
            ...this.state.errors,
        };
        keys.forEach((k) => (errors[k] = undefined));
        errors.submit = this.isSafeToSave(false) ? undefined : 'There are missing required fields';
        this.setState({ errors });
    };

    // generically set state[stateField][key] to the given value
    setStateValue = <K extends keyof CommonData>(key: K, value: CommonData[K], stateField: keyof AddEditAdminTeamDrawerState) => {
        let update: Partial<AddEditAdminTeamDrawerState> = {};
        update[stateField] = JSON.parse(JSON.stringify(this.state[stateField] ?? {}));
        (update[stateField] as any)[key] = value;
        this.setState(update as AddEditAdminTeamDrawerState, () => this.clearError([key]));
    };

    deleteAdminTeam = async () => {
        const p = this.props;
        const s = this.state;
        if (!this.props.adminTeamId) return;
        if (!window.confirm(`Are you sure you want to delete ${s.commonData.name}?`)) return;
        this.props.loading(0, 'AddEditAdminTeamDrawer');
        try {
            const deleteRes = await api2.client.AdminTeamApi.deleteAdminTeam({
                admin_team_id: this.props.adminTeamId,
            });
            if (deleteRes?.data?.success === true) {
                setDismissableAlert(this.props.setAlert, 'Admin team deleted.');
                this.props.reloadData();
                this.props.loaded('AddEditAdminTeamDrawer');
                this.props.close();
            } else {
                setDismissableAlert(this.props.setAlert, 'Error deleting admin team.', true);
            }
        } catch (err: any) {
            this.props.loaded('AddEditAdminTeamDrawer');
            setDismissableAlert(this.props.setAlert, 'Error deleting admin team.', true);
        }
    };

    renderSubmitButton = () => {
        return (
            <div>
                <button className="drawer-submit-button" onClick={this.createOrUpdateAdminTeam}>
                    {this.props.adminTeamId ? 'Save Changes' : 'Create'}
                </button>
                {renderInputError(this.state.errors, 'submit')}
                <div style={{ clear: 'both', height: '20px' }} />
            </div>
        );
    };

    renderCancelButton = () => {
        return (
            <>
                <div className="addNewAdvisorGroup-cancel">
                    <span className="a" onClick={this.props.close}>
                        Cancel
                    </span>
                </div>
                <div style={{ clear: 'both', height: '20px' }} />
            </>
        );
    };

    renderDeleteButton = () => {
        if (!this.props.adminTeamId) return null;
        return (
            <>
                <div className="addNewAdvisorGroup-cancel">
                    <span className="a" onClick={this.deleteAdminTeam} style={{ color: 'var(--color-dark-red' }}>
                        Delete
                    </span>
                </div>
                <div style={{ clear: 'both', height: '20px' }} />
            </>
        );
    };

    render = () => {
        const s = this.state;
        return (
            <>
                <div className="addNewAdvisorGroup-drawer">
                    <h1>{this.props.adminTeamId ? `Edit Admin Team` : `Create Admin Team`}</h1>
                    <div>
                        {renderInputComponentList(
                            this.getCommonDataFields(),
                            (key: keyof CommonData, value: CommonData[keyof CommonData]) => this.setStateValue(key, value, 'commonData'),
                            s.commonData,
                            s.errors,
                            true,
                            false,
                            true
                        )}
                    </div>
                    {this.renderSubmitButton()}
                    {this.renderCancelButton()}
                    {this.renderDeleteButton()}
                </div>
                <div style={{ clear: 'both', height: '300px' }} />
            </>
        );
    };
}

export default AddEditAdminTeamDrawer;
