import React from 'react';
import moment from 'moment';

import '../../styles/clientListStatus.css';
import { getClientStatusColor } from '../../utilities/AdvisorVue/getClientStatusColor';
import rephraseClientStatus from '../../utilities/AdvisorVue/rephraseClientStatus';

export const ClientListStatus = (props) => {
    return (
        <>
            {/* status */}
            {!props.client.isConnectionRequest && (
                <div className="clientCard-status" style={{ color: getClientStatusColor(props.client) ?? 'blue' }}>
                    {rephraseClientStatus(props.client.status)}
                </div>
            )}

            {/* relevant date depending on status/isConnectionRequest */}
            {props.client.isConnectionRequest ? (
                <div>
                    <span className="clientCard-date">
                        {/* date received if connection request */}
                        {props.client.currentAdvisor?.invited_on ? `Received ${moment(props.client.currentAdvisor?.invited_on)?.format('MM-DD-YYYY')}` : 'No Received Date'}
                    </span>
                </div>
            ) : props.client.status === 'Active' ? (
                <div className="clientCard-date">
                    {/* {props.client?.sessions?.length > 0 
                    ? `Last Login ${moment(props.client.sessions[props.client.sessions.length - 1].login_at)?.format('MM-DD-YYYY')}`
                    : 'Never Logged In'} */}
                    {props.client?.last_login_at ? `Last Login ${moment(props.client.last_login_at)?.format('MM-DD-YYYY')}` : 'Never Logged In'}
                </div>
            ) : props.client.status === 'Invite Pending' ? (
                <div className="clientCard-date">{props.client.updated_at ? `Sent ${moment(props.client.updated_at)?.format('MM-DD-YYYY')}` : 'Never Sent'}</div>
            ) : props.client.status === 'Deactivated' ? (
                <div className="clientCard-date">
                    {props.client.currentAdvisor?.revoked_on ? `Removed ${moment(props.client.currentAdvisor?.revoked_on)?.format('MM-DD-YYYY')}` : 'No Removed Date'}
                </div>
            ) : null}
        </>
    );
};
