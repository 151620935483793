// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import _ from 'lodash';
import React, { Component } from 'react';

import '../../styles/clientDonutChart.css';

import { Doughnut } from 'react-chartjs-2';
// eslint-disable-next-line
import { Chart as ChartJS } from 'chart.js/auto';

import { getColorFromIndex } from '../../utilities/AdvisorVue/getColorFromIndex';
import { EM_DASH } from '../../constants/constantStrings';

export class ClientDonutChart extends Component {
    state = {
        types: [],
        data: null,
        sum: 0,
        sumMultStr: '',
    };

    componentDidMount = () => {
        this.loadData();
    };

    componentDidUpdate = (prev) => {
        if (!_.isEqual(this.props.amountsByType, prev.amountsByType)) this.loadData();
    };

    loadData = () => {
        const p = this.props;
        const investments = p.amountsByType;

        const types = investments ?? [];
        let sum = types.reduce((x, i) => x + i.commitmentAmount, 0);

        let sumStr;
        let sumMultStr = '';

        const BILLION = 1000000000;
        const MILLION = 1000000;
        const THOUSAND = 1000;
        if (!Number.isFinite(sum)) {
            sumStr = EM_DASH;
        } else if (sum >= BILLION) {
            sum /= BILLION;
            sumMultStr = 'billion';
            sumStr = sum.toFixed(1);
        } else if (sum >= MILLION) {
            sum /= MILLION;
            sumMultStr = 'million';
            sumStr = sum.toFixed(1);
        } else if (sum >= THOUSAND) {
            sum /= THOUSAND;
            sumStr = sum.toFixed(1);
            sumMultStr = 'thousand';
        } else {
            // ensure 2 decimals if not "00"
            sumStr = sum.toFixed(2);
        }

        // donut chart data and labels
        const datasets = [
            {
                type: 'doughnut',
                backgroundColor: p.blank || sum === 0 ? '#E0E0E0' : types.map((_, i) => getColorFromIndex(i)),
                data: p.blank || sum === 0 ? [1] : types.map((t) => t.commitmentAmount),
            },
        ];

        this.setState({
            types,
            data: {
                // labels: types.map(t => t.name),
                datasets,
            },
            sumStr,
            sumMultStr,
        });
    };

    getChartOptions = () => ({
        cutout: '65%',
        animation: false,
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltips: {
                enabled: false,
            },
        },
    });

    render = () => {
        return (
            <div className="clientDonutComponent">
                <div className="clientDonutGraph">
                    {this.state.data && (
                        <Doughnut
                            style={{ width: '100%', height: '100%', borderColor: 'red', position: 'relative', backgroundColor: 'transparent' }}
                            type="doughnut"
                            data={this.state.data}
                            options={this.getChartOptions()}
                        />
                    )}
                    <div className="clientDonutText">
                        {!this.props.blank && (
                            <>
                                <div>{(this.state.sumStr === EM_DASH ? '' : '$') + this.state.sumStr}</div>
                                <div>{this.state.sumMultStr}</div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    };
}
