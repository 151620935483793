// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import api from '../../../api';

import '../../../styles/widget.css';
import '../../../styles/widget-notes.css';

import _ from 'lodash';
import DOMPurify from 'dompurify';
import { Letter } from 'react-letter';
import { UserPermissions } from '../../../utilities/AdvisorVue/permissions';

const UNEXPANDED_SHOW_COUNT = 3;

class NotesWidget extends Component {
    editorRef = React.createRef();

    state = {
        notes: [],
        showAll: false,
        openNoteIdxs: {},

        editingNote: null,
        editorState: null,
        contentState: null,

        deleteConfirmIdx: null,
    };

    componentDidMount = async () => {
        this.loadData();
    };

    componentDidUpdate = async (prev) => {
        if (
            !_.isEqual(this.props.userId, prev.userId) ||
            !_.isEqual(this.props.type, prev.type) ||
            !_.isEqual(this.props.typeId, prev.typeId) ||
            !_.isEqual(this.props.breadcrumbs, prev.breadcrumbs)
        ) {
            this.loadData();
        }
    };

    // extract, convert and add editorState the given note
    addNoteEditorState = (note) => {
        const contentBlock = htmlToDraft(note.body);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            note.editorState = editorState;
        }
        return note;
    };

    loadData = async () => {
        const s = this.state;
        const p = this.props;
        // userId and type are required
        if (!p.type) return;
        await this.props.loading(320);

        const addQueryStrToRoute = (route, newQueryStr) => {
            if (!newQueryStr) return route;
            return route + (route.includes('?') ? '&' : '?') + newQueryStr;
        };

        const userIdQueryStr = p.userId ? `userId=${p.userId}` : '';
        const typeQueryStr = p.type ? `type=${p.type}` : '';
        const typeIdQueryStr = p.typeId ? `${p.type}=${p.typeId}` : '';

        let queryStr = '/notes';
        queryStr = addQueryStrToRoute(queryStr, userIdQueryStr); // add userId to queryStr
        queryStr = addQueryStrToRoute(queryStr, typeQueryStr); // add type to queryStr
        queryStr = addQueryStrToRoute(queryStr, typeIdQueryStr); // add typeId to queryStr

        // add breadcrumbs to queryStr
        if (p.breadcrumbs) {
            for (let key in p.breadcrumbs) {
                queryStr = addQueryStrToRoute(queryStr, `${key}=${p.breadcrumbs[key]}`);
            }
        }

        let notes = (await api.get(queryStr)) ?? [];

        // add editorState to each note
        notes = notes.map(this.addNoteEditorState);

        this.setState({ notes, editingNote: null, openNoteIdxs: {} });
        this.props.loaded();
    };

    // update the note at the given index with the given diff
    updateNote = async (diff, idx) => {
        const notes = this.state.notes;
        notes[idx] = { ...notes[idx], ...diff };
        this.setState({ notes });
    };

    // open or close the note at the given index
    toggleNote = async (idx) => this.openNote(idx, !this.state.openNoteIdxs[idx]);
    openNote = async (idx, open = true) => {
        const openNoteIdxs = this.state.openNoteIdxs;
        openNoteIdxs[idx] = open;
        this.setState({ openNoteIdxs });
    };

    // add a new note at the top of the list if not already adding a new note
    addNote = async () => {
        const notes = this.state.notes;
        if (this.state.editingNote === 0 && !notes[0]._id) return;
        notes.unshift(
            this.addNoteEditorState({
                body: ``,
                date: new Date(),
                // date: dateToUTCMidnight(new Date()),
                open: true,
            })
        );
        this.setState({ notes, editingNote: 0 });
    };

    // delete the note at the given index
    deleteNote = async (idx) => {
        const p = this.props;
        const note = this.state.notes[idx];
        // delete from server if already saved
        if (note._id) {
            p.loading(320, 'delete_note');
            await api.delete(`/notes/${note._id}`);
            p.loaded('delete_note');
            this.loadData();
        } else {
            // delete from state if not yet saved
            const notes = this.state.notes.filter((n, i) => i !== idx);
            this.setState({ notes, editingNote: null });
        }
    };

    // save the note at the given index
    saveNote = async (idx) => {
        const p = this.props;
        let note = this.state.notes[idx];
        if (!note.user && this.props.userId) {
            note.user = this.props.userId;
        }
        note[this.props.type] = this.props.typeId;
        note.type = this.props.type;

        note = {
            ...note,
            ...p.breadcrumbs, // fields to make note unique
        };

        // update or create on server
        if (note._id) {
            p.loading(320, 'save_note');
            await api.patch(`/notes/${note._id}`, note);
            p.loaded('save_note');
        } else {
            p.loading(320, 'save_note');
            await api.post(`/notes`, note);
            p.loaded('save_note');
        }
        this.loadData();
        this.setState({ editingNote: null });
    };

    renderNote = (note, noteIdx, notes) => {
        const p = this.props;
        const s = this.state;

        const isOpen = s.openNoteIdxs[noteIdx];
        const isEditing = s.editingNote === noteIdx;
        const hasBody = note.body?.length > 0;
        const deleteConfirm = s.deleteConfirmIdx === noteIdx;

        return (
            <div key={`noticeRow${noteIdx}`} className={`notes_row ${isEditing ? 'notes_editing' : 'notes_notEditing'}`} onClick={() => this.toggleNote(noteIdx)}>
                {/* show text editor if editing this note */}
                {isEditing && (
                    <div style={{ padding: '20px' }}>
                        <Editor
                            editorRef={(ref) => (this.editorRef = ref)}
                            toolbarClassName="notes_toolbarClassName"
                            wrapperClassName="notes_wrapperClassName"
                            editorClassName="notes_editorClassName"
                            editorState={note.editorState}
                            onEditorStateChange={async (editorState) => {
                                // convert editorState to html and save as body to note
                                const body = draftToHtml(convertToRaw(editorState.getCurrentContent()));
                                await this.updateNote({ body, editorState }, noteIdx);
                            }}
                            toolbar={{
                                options: p.minimal ? ['inline', 'list', 'fontSize'] : ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'emoji'],
                                inline: {
                                    options: p.minimal ? ['bold', 'italic', 'underline'] : ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript'],
                                },
                                list: {
                                    inDropdown: false,
                                    options: ['unordered', 'ordered'], // Only include 'unordered' (bullet points) and 'ordered' (numbered list)
                                },
                            }}
                        />

                        <div className="notes_editBtns">
                            <div
                                style={{ marginRight: '10px', color: 'var(--color-dark-red)' }}
                                className="a"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    // confirm delete
                                    if (deleteConfirm) {
                                        this.deleteNote(noteIdx);
                                    } else {
                                        this.setState({ deleteConfirmIdx: noteIdx }, () => setTimeout(() => this.setState({ deleteConfirmIdx: null }), 2000));
                                    }
                                }}
                            >
                                {deleteConfirm ? 'Confirm' : 'Delete'}
                            </div>

                            <div
                                style={{ marginRight: '10px' }}
                                className="a"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    // if new note, delete it
                                    if (!note._id && noteIdx === 0) {
                                        this.deleteNote(noteIdx);
                                    } else {
                                        // otherwise cancel edit
                                        this.setState({ editingNote: null });
                                    }
                                }}
                            >
                                Cancel
                            </div>

                            <div
                                style={{ marginRight: '10px', color: !hasBody ? 'var(--color-light-gray)' : null }}
                                className={hasBody ? 'a' : ''}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    // save if body is not empty
                                    if (hasBody) this.saveNote(noteIdx);
                                }}
                            >
                                Save
                            </div>
                        </div>

                        <div style={{ clear: 'both', height: '20px' }} />
                    </div>
                )}

                {/* show note body and date if not editing */}
                {!isEditing && (
                    <div className={`notes_view notes_${isOpen ? 'open' : 'closed'}`}>
                        {/* body */}
                        <div className="notes_row_body">
                            <Letter html={note.body} />
                        </div>
                        {/* edit button and date */}
                        <div className={'notes_row_dueDate'}>
                            {/* only show edit button if not already editing a note */}
                            {UserPermissions().canUpdateAnyInvestments && (
                                <div
                                    className={'edit-icon'}
                                    title="edit"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.setState({ editingNote: noteIdx });
                                    }}
                                />
                            )}
                            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                                {note.editor?.access && note.editor?.access !== 'investor' && p.type !== 'admin_audit_result' && (
                                    <span
                                        style={{
                                            marginRight: '15px',
                                            color: 'var(--color-light-gray)',
                                        }}
                                    >
                                        {note.editor?.access}
                                    </span>
                                )}
                                <span>{moment(note.date).format('MMM DD, YYYY')}</span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    render = () => {
        const s = this.state;
        const p = this.props;
        // only show the first 3 notes by default
        const notes = s.showAll ? s.notes : s.notes.slice(0, UNEXPANDED_SHOW_COUNT);

        return (
            <div id="notes_component" className="widget notes_component" style={{ ...p.containerStyle }}>
                {/* header */}
                <div className="notes_header">
                    {
                        <div className="notes_headerText">
                            {/* {notes.length} note{notes.length !== 1 ? 's' : ''} */}
                            There are {notes.length} note{notes.length !== 1 ? 's' : ''}
                        </div>
                    }
                    {UserPermissions().canUpdateAnyInvestments && <div className="plus-icon" onClick={this.addNote} />}
                </div>
                {/* note list */}
                {notes.map(this.renderNote)}
                {/* see all button */}
                {s.notes.length > UNEXPANDED_SHOW_COUNT && (
                    <div className="notes_SeeAll">
                        <span onClick={() => this.setState({ showAll: !s.showAll })}>Show {s.showAll ? 'less' : 'all'}</span>
                    </div>
                )}
            </div>
        );
    };
}

export default withRouter(NotesWidget);
