import React, { Component } from 'react';
import _ from 'lodash';
import { getDateTimeString } from '../../utilities/format/formatDate';
import ReactJson from 'react-json-view';

import '../../styles/viewConnectionErrors.css';

export class ViewConnectionErrors extends Component {
    state = {
        expanded: false,
    };

    async componentDidMount() {}

    async componentDidUpdate(prevProps) {}

    render() {
        const p = this.props;
        const s = this.state;

        const most_recent_import = p?.most_recent_import;
        const most_recent_sync = p?.most_recent_sync;

        if (!most_recent_import && !most_recent_sync) {
            return <div className="connectionErrors-component"></div>;
        }
        // which one is most recent?
        const most_recent_type = most_recent_import?.created_at > most_recent_sync?.created_at ? 'Import' : 'Sync';

        return (
            <>
                <div
                    style={{
                        marginTop: '10px',
                    }}
                >
                    {most_recent_import && s.expanded && (
                        <div className="connectionErrors-component">
                            <div className="connectionErrors-item">
                                <div className="connectionErrors-item-header">
                                    <span className="connectionErrors-item-type">Import</span>
                                    <span className="connectionErrors-item-status">{most_recent_import?.exit_status}</span>
                                </div>
                                <div className="connectionErrors-item-info">
                                    <div>ID: {most_recent_import._id}</div>
                                    <div>Request ID: {most_recent_import?.job_id}</div>
                                    <div>Created: {most_recent_import.created_at ? getDateTimeString(most_recent_import.created_at) : null}</div>
                                    <div>Completed: {most_recent_import.completed_at ? getDateTimeString(most_recent_import.completed_at) : null}</div>
                                    <div>
                                        Duration:{' '}
                                        {most_recent_import.completed_at && most_recent_import.created_at
                                            ? `${(new Date(Date.parse(most_recent_import.completed_at)) - new Date(Date.parse(most_recent_import.created_at))) / 1000} seconds`
                                            : 'N/A'}
                                    </div>
                                    <div>Status: {most_recent_import.exit_status}</div>
                                </div>
                                {most_recent_import?.completion_result && (
                                    <div className="connectionErrors-item-result">
                                        <ReactJson src={most_recent_import.completion_result} name={false} displayDataTypes={false} theme={'ashes'} collapsed={true} />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {most_recent_sync && s.expanded && (
                        <div className="connectionErrors-item">
                            <div className="connectionErrors-item-header">
                                <span className="connectionErrors-item-type">Sync</span>
                                <span className="connectionErrors-item-status">{most_recent_sync?.exit_status}</span>
                            </div>
                            <div className="connectionErrors-item-info">
                                <div>ID: {most_recent_sync._id}</div>
                                <div>Request ID: {most_recent_sync?.job_id}</div>
                                <div>Created: {most_recent_sync.created_at ? getDateTimeString(most_recent_sync.created_at) : null}</div>
                                <div>Completed: {most_recent_sync.completed_at ? getDateTimeString(most_recent_sync.completed_at) : null}</div>
                                <div>
                                    Duration:{' '}
                                    {most_recent_sync.completed_at && most_recent_sync.created_at
                                        ? `${(new Date(Date.parse(most_recent_sync.completed_at)) - new Date(Date.parse(most_recent_sync.created_at))) / 1000} seconds`
                                        : 'N/A'}
                                </div>
                                <div>Status: {most_recent_sync.exit_status}</div>
                            </div>
                            {most_recent_sync?.error_report && (
                                <div className="connectionErrors-item-result">
                                    <ReactJson src={most_recent_sync.error_report} name={false} displayDataTypes={false} theme={'ashes'} collapsed={true} />
                                </div>
                            )}
                        </div>
                    )}
                </div>

                <div className="connectionErrors-controls">
                    <a
                        href="#"
                        onClick={() => {
                            this.setState({ expanded: !s.expanded });
                        }}
                    >
                        {s.expanded ? 'Close' : 'Show Recent Jobs'}
                    </a>
                </div>
            </>
        );
    }
}
