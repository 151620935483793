import React, { Component } from 'react';
import api from '../../api';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';
import Multitoggle from './Multitoggle';
import { UserPermissions } from '../../utilities/AdvisorVue/permissions';
import UTCDatePicker from '../Inputs/UTCDatePicker/UTCDatePicker';
import api2 from '../../api2';
import { TimelessDate } from '../../types/TimelessDate';

const OPTIONS = ['No', 'Yes'];

export default class InvestmentInactiveToggle extends Component {
    render = () => {
        const { investment, loading, loaded, onChange, onChangeDate, setAlert } = this.props;

        const curOption = OPTIONS[Number(!investment.inactive)];

        return (
            <>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', ...this.props.containerStyle }}>
                    <div>Active Investment</div>
                    <Multitoggle
                        containerStyle={{ width: '70px', marginLeft: '10px' }}
                        options={OPTIONS}
                        selection={curOption}
                        // zIndex={-1}
                        onSelect={async (opt) => {
                            if (!UserPermissions().canUpdateAnyInvestments) return;
                            const newValue = !OPTIONS.indexOf(opt);
                            if (newValue === investment.inactive) return;
                            loading?.(0, 'inactiveToggleUpdate');
                            try {
                                await api2.client.InvestmentApi.updateInvestment({
                                    investment_id: investment._id,
                                    UpdateInvestmentRequest: { inactive: newValue },
                                });
                                if (setAlert) {
                                    setDismissableAlert(setAlert, `Investment set to ${newValue ? 'in' : ''}active.`);
                                }
                                await onChange?.(newValue);
                            } catch (err) {
                                setDismissableAlert(setAlert, `Error setting investment to ${newValue ? 'in' : ''}active.`, true);
                            }
                            loaded?.('inactiveToggleUpdate');
                        }}
                        optionStyle={{ cursor: UserPermissions().canUpdateAnyInvestments ? null : 'default' }}
                    />
                </div>
                {this.props.allowEditDate && investment.inactive_date && investment.inactive && (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '20px', ...this.props.containerStyle }}>
                        <div style={{ marginRight: '5px' }}>Inactive Date</div>
                        <UTCDatePicker
                            placeholderText="MM/DD/YYYY"
                            dateFormat="MM-dd-yyyy"
                            selected={investment.inactive_date ? TimelessDate.parse(investment.inactive_date) : null}
                            onChange={async (newDate) => {
                                loading?.(0, 'inactiveDateUpdate');
                                try {
                                    await api2.client.InvestmentApi.updateInvestment({
                                        investment_id: investment._id,
                                        UpdateInvestmentRequest: {
                                            inactive_date: newDate ? newDate.toString() : null,
                                        },
                                    });
                                    if (setAlert) {
                                        setDismissableAlert(setAlert, `Investment inactive date updated.`);
                                    }
                                    await onChangeDate?.(newDate);
                                } catch (err) {
                                    setDismissableAlert(setAlert, `Error updating investment inactive date.`, true);
                                }
                                loaded?.('inactiveDateUpdate');
                            }}
                        />
                    </div>
                )}
            </>
        );
    };
}
