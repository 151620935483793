import React, { Component } from 'react';
import DropdownObjects from '../Dropdowns/DropdownObjects';
import Checkbox from '../Inputs/Checkbox';
import DropdownSection from './DropdownSection';
import Multitoggle from '../Toggles/Multitoggle';
import BlockoutComponent from './BlockoutComponent';

import '../../styles/drawerContent.css';

export default class DrawerContent extends Component {
    /**
     * Fills the drawer on the manage investments/manage connections page.
     *
     * required props:
     *  - inputs: array of items to be represented as input fields. Each Element should contain
     *  the following fields:
     *      - title: text for input field label
     *      - alt: alt text description of input field
     *      - kind: 'text', 'password', or 'checkbox' (defaults to 'text')
     *      - defaultValue: default value for field, str for text/password, bool for checkbox.
     *      - onChange: function to be executed on change of input field. Accept Value of input as argument.
     *      - options: {title} options for the dropdown. title will be displayed.
     *
     *  - subheader: text to place beneath header
     *  - onSubmit: function to be called upon submission of field.
     * optional props:
     *  - title: title for the drawer
     *  - subtitle: subtitle for the drawer
     *
     *  - submitTitle: text on submit button. defaults to "Submit"
     *
     *  - onCancel: function to be called upon cancellation of form. Does not display button if not provided.
     *  - cancelTitle: text for the cancel button.
     */

    state = {
        confirmDelete: false,
    };

    renderField = (input, idx) => {
        let idPrefix = this.props.idPrefix + '_input+' + idx;

        return (
            <React.Fragment key={idPrefix}>
                {input.type === 'custom' ? (
                    input.component
                ) : (
                    <>
                        {!['checkbox', 'dropdown_section', 'toggle'].includes(input.type) && <label>{input.title}</label>}
                        {input.type === 'dropdown_section' ? (
                            <DropdownSection
                                title={input.title}
                                options={input.options}
                                renderOption={input.renderOption ?? (input.optionField ? (i) => i?.[input.optionField] : (i) => i.title)}
                                selection={input.selection}
                                defaultSelection={input.defaultSelection}
                                select={(newSelection) => input.onChange(newSelection)}
                                disableDefaultOption={input.disableDefaultOption ?? true}
                            />
                        ) : input.type === 'dropdown' ? (
                            <DropdownObjects
                                defaultSelection={input.defaultValue}
                                selection={input.value}
                                excludeNull={input.excludeNull}
                                idPrefix={idPrefix}
                                sticky={false}
                                options={input.options}
                                select={(newlySelected, prevSelected) => {
                                    input.onChange(newlySelected, prevSelected);
                                }}
                            />
                        ) : input.type === 'checkbox' ? (
                            <div style={{ display: 'flex', gap: '8px', alignItems: 'center', marginTop: '10px', marginBottom: '10px' }}>
                                <Checkbox id={idPrefix} setChecked={input.onChange} checked={input.defaultValue ?? input.value} style={{ width: '12px', height: '12px' }} />
                                <p style={{ fontSize: '14px' }}>{input.title}</p>
                            </div>
                        ) : input.type === 'toggle' ? (
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '25px', justifyContent: 'space-between' }}>
                                <p style={{ fontSize: '16px', marginRight: '5px' }}>{input.title}</p>
                                <Multitoggle
                                    keyStr={idPrefix}
                                    options={input.options ?? ['Off', 'On']}
                                    selection={(input.options ?? ['Off', 'On'])[(input.defaultValue ?? input.value) ? 1 : 0]}
                                    // zIndex={-1}
                                    onSelect={(val) => input.onChange(!!(input.options ?? ['Off', 'On']).indexOf(val))}
                                    containerStyle={{ width: '70px', height: '24px', ...input.style }}
                                />
                            </div>
                        ) : (
                            <input
                                id={idPrefix}
                                title={input.alt}
                                type={input.type ?? 'text'}
                                placeholder={input.placeholder}
                                defaultValue={input.defaultValue}
                                onChange={(e) => input.onChange(e.target.value)}
                                style={{ height: '28px', fontSize: '16px' }}
                            />
                        )}
                    </>
                )}
            </React.Fragment>
        );
    };

    // If the delete button is clicked, the user must click it again within 3 seconds to confirm the deletion.
    onDelete = async () => {
        if (this.state.confirmDelete) {
            this.props.onDelete();
        } else {
            this.setState({ confirmDelete: true }, () => {
                setTimeout(() => {
                    this.setState({ confirmDelete: false });
                }, 3000);
            });
        }
    };

    render = () => {
        const p = this.props;
        const title = p.title;
        const subtitle = p.subtitle;
        let inputs = p.inputs ?? [];
        let bottomInputs = p.bottomInputs ?? [];
        return (
            <div className="fix-connection-drawer" key={(p.idPrefix ?? '') + 'fix_connection_drawer'}>
                <div style={{ position: 'relative' }}>
                    {p.readOnly && BlockoutComponent()}
                    {title && <h1>{title}</h1>}
                    {subtitle && <p>{subtitle}</p>}
                    <div className="inputs">{inputs.map((input, inputIdx) => this.renderField(input, inputIdx)) ?? []}</div>
                    {!p.readOnly && (
                        <button
                            className={`fix-connection-drawer-button ${p.submitDisabled ? 'disabled' : ''}`}
                            onClick={p.submitDisabled ? undefined : p.onSubmit}
                            style={{ marginTop: '30px' }}
                        >
                            {p.submitTitle ?? 'Submit'}
                        </button>
                    )}
                </div>
                {p.onCancel && (
                    <div style={{ marginTop: '20px' }} className="a" onClick={p.onCancel}>
                        {p.cancelTitle ?? 'Cancel'}
                    </div>
                )}
                {p.onDelete && (
                    <button style={{ marginTop: '20px', backgroundColor: 'var(--color-dark-red)', color: 'white' }} onClick={this.onDelete}>
                        {this.state.confirmDelete ? `Confirm` : (p.deleteTitle ?? 'Delete')}
                    </button>
                )}
                <div className="inputs">{bottomInputs.map((input, inputIdx) => this.renderField(input, inputIdx)) ?? []}</div>
            </div>
        );
    };
}
