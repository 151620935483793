import React, { useState, useEffect, useRef } from 'react';
import { createHash } from 'crypto';
import AsyncSelect from 'react-select/async';
import { CSSProperties } from 'react';
import '../../styles/objectFilterSelect.css';

// Interface for the options used in the dropdown
export interface Option<T> {
    value: T;
    label: string;
}

// Interface for the props accepted by the ObjectSearchSelect component
export interface ObjectSearchSelectProps<T> {
    key?: string;
    defaultLabel?: string;
    loadOptions?: (inputValue: string) => Promise<T[]>;
    getLabel: (obj: T) => string | null | undefined;
    noDefaultOption?: boolean;
    defaultOptions?: Option<T>[] | boolean;
    selected?: T | T[];
    onChange: (newValue: T | T[]) => void;
    containerStyle?: CSSProperties;
    textAlign?: string;
    width?: string;
    height?: string;
    placeholder?: string;
    style?: CSSProperties;
    filterOptionFunc?: (option: any, rawInput: string) => boolean;
    isMulti?: boolean;
    formatOptionLabel?: (data: Option<T>) => React.ReactNode | string;
    matchProperty?: string;
    label?: string | JSX.Element;
    additionalOptions?: any[] | undefined;
    isDisabled?: boolean;
}

// Main functional component for ObjectSearchSelect
export default function ObjectSearchSelectTs<T>({
    key,
    defaultLabel = 'All',
    loadOptions,
    getLabel,
    noDefaultOption = false,
    defaultOptions,
    selected,
    onChange,
    containerStyle,
    textAlign,
    width = 'unset',
    height,
    placeholder = 'Filter by user',
    style,
    filterOptionFunc,
    isMulti = false,
    formatOptionLabel,
    matchProperty,
    label,
    additionalOptions, // not working
    isDisabled = false,
}: ObjectSearchSelectProps<T>) {
    const [componentKey, setComponentKey] = useState(0);
    const loadOptionsTimeout = useRef<NodeJS.Timeout | null>(null);

    // Effect to update component key to force re-render when key prop changes
    useEffect(() => {
        if (key) {
            setComponentKey(componentKey + 1);
        }
    }, [key]);

    // Default option configuration
    const defaultOption: Option<T | null>[] = [
        {
            value: null,
            label: defaultLabel,
        },
    ];

    // Function to load options asynchronously based on user input
    const loadOptionsAsync = async (inputValue: string) => {
        let results: T[] | undefined = await loadOptions?.(inputValue);
        let options: Option<T | null>[] =
            results?.map((obj: T) => ({
                value: obj,
                label: getLabel(obj) || placeholder,
            })) ?? [];

        // Include default option if applicable
        if (!noDefaultOption && !isMulti) {
            options = [...defaultOption, ...options];
        }

        // Include additional options if applicable
        if (additionalOptions) {
            options = [
                ...options,
                ...additionalOptions.map(
                    (option, optionIdx) =>
                        ({
                            label: option?.(),
                            value: String(optionIdx),
                        }) as Option<T>
                ),
            ];
        }

        return options;
    };

    // Function to determine the default options based on props
    const loadDefaultOptions = (): any => {
        if (defaultOptions === false) {
            if (!noDefaultOption && !isMulti) {
                return defaultOption;
            }
            return false;
        } else if (defaultOptions !== undefined) {
            return defaultOptions;
        }
        return true;
    };

    // Determine the value to be shown in the select based on the selected prop
    let value: Option<T> | Option<T>[] | null = null;

    if (isMulti) {
        value = ((selected as T[]) || []).map((obj) => ({
            value: obj,
            label: getLabel(obj) ?? placeholder,
        }));
    } else {
        // const matchedProperty = matchProperty ? (selected as any)?.[matchProperty] : selected;
        value = {
            value: selected,
            label: getLabel(selected as T) ?? placeholder,
        } as Option<T>;
    }

    // Render the AsyncSelect component with appropriate configurations
    return (
        <div
            style={{
                position: 'relative',
                ...containerStyle,
                textAlign: textAlign as any,
                width: width,
                lineHeight: height,
                boxShadow: 'var(--box-shadow)',
            }}
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <div>{label}</div>
            <AsyncSelect
                key={componentKey}
                className={'userFilterSelect-select'}
                placeholder={placeholder}
                loadOptions={(inputValue, callback) => {
                    if (loadOptionsTimeout.current) {
                        clearTimeout(loadOptionsTimeout.current);
                    }
                    loadOptionsTimeout.current = setTimeout(() => {
                        loadOptionsAsync(inputValue).then((options) => callback(options as any));
                    }, 700);
                }}
                value={value}
                onChange={(newSelection) => {
                    if (isMulti) {
                        onChange((newSelection as Option<T>[])?.map((o: any) => o.value));
                    } else {
                        onChange((newSelection as Option<T>)?.value);
                    }
                }}
                styles={{
                    control: (provided, state) => ({
                        ...provided,
                        width: width,
                        height: height,
                        ...style,
                    }),
                    menu: (provided, state) => ({
                        ...provided,
                        width: width,
                        ...style,
                    }),
                }}
                filterOption={filterOptionFunc}
                defaultOptions={loadDefaultOptions()}
                isMulti={isMulti}
                formatOptionLabel={(option) => {
                    if (option.value !== null && !isNaN(option.value as any)) return option.label;
                    return formatOptionLabel ? formatOptionLabel?.(option as any) : option.label;
                }}
                isDisabled={isDisabled}
            />
        </div>
    );
}
