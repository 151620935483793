import { FileParseResult, FileTextResult, FileTypes } from '../../../../constants/file';
import { parseCsvLine } from '../../../../utilities/csv/parseCsvLine/parseCsvLine';
import { BulkUploadColumn, BulkUploadRequestResult, BulkUploadType } from '../types/bulkUploadTypes';

export interface BulkUploadHandlerConfigOptions {
    investment?: string;
}

// type used as the objects being managed by the bulk upload
export type BulkUploadHandlerCreateType = Record<string, any>;

export type BulkUploaderHandlerDataType = { [key in keyof BulkUploadHandlerCreateType]: string };
export type BulkUploaderHandlerColumnType = { [key in keyof BulkUploadHandlerCreateType]: BulkUploadColumn };

// type for the config object that is passed to the handler
// includes any additional data that is needed to determine the required columns
// eg. an investment id to that will automatically fill in the investment column, so it is not required
export type BulkUploadHandlerConfig = Partial<Record<keyof BulkUploadHandlerConfigOptions, any>>;

// pass an async function into the constructor to load any additional data needed for the handler.
// You will be passed the handler object to set the state
// must include the state generic type CreateOptionState

abstract class BulkUploadHandler<BulkUploadHandlerCreateType, CreateOptionConfig = BulkUploadHandlerConfig, CreateOptionState = null> {
    // variable input data, like an investment id, that can be used to determine if a column is required, or for any other reason
    config: CreateOptionConfig;

    state?: CreateOptionState;

    // base columns that are required for the bulk upload
    // will be edited and modified in the getColumn method
    abstract base_columns: BulkUploaderHandlerColumnType;

    /**
     * The order of the columns in the CSV file
     * @type {string[]}
     */
    abstract columnOrder: (keyof BulkUploadHandlerCreateType)[];

    /**
     * Sets the order of columns. Subclasses can override this method with more specific types for `columnOrder`.
     * @param columnOrder An array of keys from BulkUploadHandlerCreateType, specifying the order of columns.
     */
    setColumnOrder(columnOrder: (keyof BulkUploadHandlerCreateType)[]): void {
        if (!columnOrder.every((col) => this.columnOrder.includes(col)) || columnOrder.length !== this.columnOrder.length) {
            throw new Error('Invalid column order. Must include all columns.');
        }
        this.columnOrder = columnOrder;
    }

    /**
     * sort the columns based on the order in columnOrder, or the default order if not provided
     */
    _sortColumns = (columns: BulkUploaderHandlerColumnType, columnOrder?: (keyof BulkUploadHandlerCreateType)[]) => {
        const columnOrderToUse = columnOrder || this.columnOrder;
        const sortedColumnResult: BulkUploaderHandlerColumnType = columnOrderToUse.reduce((acc, key) => {
            if (!columns[key as keyof BulkUploaderHandlerColumnType]) return acc;
            acc[key as keyof BulkUploaderHandlerColumnType] = columns[key as keyof BulkUploaderHandlerColumnType];
            return acc;
        }, {} as BulkUploaderHandlerColumnType);
        return sortedColumnResult;
    };

    /**
     * The type of the bulk upload, eg. 'commitment' or 'valuation'
     */
    abstract type: BulkUploadType;

    /**
     * The allowed file types for the bulk upload
     * can be overridden by the subclass
     */
    allowedFileTypes: FileTypes[] = [FileTypes.csv];

    constructor(config: CreateOptionConfig, loadState?: (thisHandler: BulkUploadHandler<BulkUploadHandlerCreateType, CreateOptionConfig, CreateOptionState>) => Promise<void>) {
        this.config = config || ({} as CreateOptionConfig);

        if (loadState) {
            loadState(this);
        }
    }

    /**
     *  check a single row of data to see if it is valid
     */
    abstract isDataValid: (data: BulkUploaderHandlerDataType, columnOrder?: (keyof BulkUploadHandlerCreateType)[]) => boolean;

    /**
     * Shared logic to check if the data is valid
     * Checks that all required fields are present and that all existing fields are valid.
     *
     * @param data The data to check
     * @param columnOrder The order of the columns
     * @returns {boolean} True if the data is valid, false otherwise.
     */
    _isColumnDataValid = (columns: BulkUploaderHandlerColumnType, data: BulkUploaderHandlerDataType, columnOrder?: (keyof BulkUploadHandlerCreateType)[]) => {
        // const thisColumnOrder = columnOrder || Object.keys(this.getColumns());

        // const columns = this.getColumns(columnOrder);

        // Check if all required fields are present.
        for (const key in Object.keys(columns)) {
            if (columns[key as keyof BulkUploaderHandlerColumnType]?.required && !data[key as keyof BulkUploaderHandlerDataType]) {
                return false;
            }
        }

        // Check if all existing fields are valid.
        for (const key in data) {
            if (
                data[key as keyof BulkUploaderHandlerDataType] &&
                columns[key as keyof BulkUploaderHandlerColumnType] &&
                columns[key as keyof BulkUploaderHandlerColumnType]?.isValid &&
                !columns[key as keyof BulkUploaderHandlerColumnType]?.isValid?.(data[key as keyof BulkUploaderHandlerDataType] || '', data)
            ) {
                return false;
            }
        }

        return true;
    };

    /**
     * Defines the columns for CSV files
     * @returns {Object} An object mapping each column to its validation and formatting rules defined in the BulkUploadColumn type.
     */

    // setColumnOrder(columnOrder: (keyof BulkUploadHandlerCreateType)[]): void {

    abstract getColumns: (columnOrder?: (keyof BulkUploadHandlerCreateType)[]) => BulkUploaderHandlerColumnType;

    /**
     * Get the notes for the bulk upload type
     * @returns {string[]} An array of strings that represent the notes for the bulk upload type.
     */
    abstract getNotes: () => string[];

    /**
     * Parse the text file results into a FileParseResult
     */
    abstract parseTextFileResult: (results: FileTextResult, columnOrder?: (keyof BulkUploadHandlerCreateType)[]) => Promise<FileParseResult<BulkUploadHandlerCreateType>>;

    /**
     * shared logic to parse a single line from a CSV file into an object with the correct fields
     */
    _parseSingleCsvLine = (line: string, columnOrder?: (keyof BulkUploadHandlerCreateType)[]): Record<keyof BulkUploadHandlerCreateType, string> => {
        // if not enough commas are included, the fields will be empty strings
        const columnOrderToUse = columnOrder || this.columnOrder;

        const lineSplit = parseCsvLine(line);

        const values = {} as Record<keyof BulkUploadHandlerCreateType, string>;

        for (let i = 0; i < columnOrderToUse.length; i++) {
            values[columnOrderToUse[i]] = lineSplit[i] || '';
        }

        return values;
    };

    /**
     * function to create the object from the parsed data
     */
    abstract create: (
        columnObj: { [key: string]: BulkUploadColumn },
        data: { [key: string]: any },
        columnOrder?: (keyof BulkUploadHandlerCreateType)[]
    ) => Promise<BulkUploadRequestResult>;

    /**
     * function to delete the object from the parsed data
     */
    abstract delete: (id: string) => Promise<BulkUploadRequestResult>;
}

export default BulkUploadHandler;
