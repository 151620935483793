import React from 'react';

import { ADVISOR_CLIENT_LIST_VIEW_MODES } from '../../constants/advisorVueConstants';

// table type toggle at top right of ClientList page
export const ClientListTableToggle = (props) => (
    <div className="clientList-filterRow-tableTypeToggle">
        {/* 4 green boxes */}
        <div
            className={
                'clientList-filterRow-tableTypeToggleCardView' + (props.viewMode === ADVISOR_CLIENT_LIST_VIEW_MODES.card ? ' clientList-filterRow-tableTypeToggleCardActive' : '')
            }
            title="Card View"
            onClick={() => props.setViewMode(ADVISOR_CLIENT_LIST_VIEW_MODES.card)}
        >
            <div>
                <div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <div>
                <div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
        {/* 3 gray bars */}
        <div
            className={
                'clientList-filterRow-tableTypeToggleGridView' + (props.viewMode === ADVISOR_CLIENT_LIST_VIEW_MODES.table ? ' clientList-filterRow-tableTypeToggleGridActive' : '')
            }
            title="Grid View"
            onClick={() => props.setViewMode(ADVISOR_CLIENT_LIST_VIEW_MODES.table)}
        >
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
);
