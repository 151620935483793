// returns a section of the page by id/class/etc as a dataUrl

import html2canvas from 'html2canvas';

// pdf is a jsPDF instance
// querySelector: string like '#id' or '.class'
// width: number, width of the output image, if null, it will be the natural width of the element
export const addCapturedSectionToPdf = async (pdf, querySelector, padding = 10, opts = {}) => {
    let outputWidth = await pdf.internal.pageSize.getWidth();
    const element = await document.querySelector(querySelector);
    console.log('element', element, querySelector);
    if (element) {
        const canvas = await html2canvas(element, opts);
        outputWidth = outputWidth - padding * 2;
        let outputHeight = canvas.height;
        if (outputWidth) {
            const widthRatio = outputWidth / canvas.width;
            outputHeight = canvas.height * widthRatio;
        }
        const imgDataUrl = canvas.toDataURL('image/png');
        console.log('capture', element, imgDataUrl.substring(0, 200), querySelector);
        await pdf.addImage(imgDataUrl, 'PNG', padding, padding, outputWidth, outputHeight);
    }
};
