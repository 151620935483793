import { BulkUploadColumn } from '../types/bulkUploadTypes';
import { SingleFileParseBulkUploadEditResult, BulkUploadStatus } from '../types/bulkUploadTypes';

// VALIDATION FUNCTIONS
// validate the data for each column in the given singleDatum row
export const validateData = <T,>(singleDatum: T, columns: { [key: string]: BulkUploadColumn }): [boolean, string[]] => {
    let isValid = true;
    const errors: string[] = [];
    Object.values(columns).forEach((columnData) => {
        const value = singleDatum[columnData.fieldName as keyof T];
        // check if the column is required and if the data is missing
        if (!value && columnData.required) {
            isValid = false;
            errors.push(`The column "${columnData.fieldName}" is required.`);
        }
        // check if the data exists and is valid
        if (value && columnData.isValid && !columnData.isValid(value as string, singleDatum)) {
            isValid = false;
            errors.push(`The "${columnData.fieldName}" ${columnData.invalidMessage || 'is invalid'}.`);
        }
    });
    return [isValid, errors];
};

// DATA GETTERS
export const getDeletedResults = <T,>(editResults: SingleFileParseBulkUploadEditResult<T>[]) => {
    return editResults.filter((editResult) => editResult.status === BulkUploadStatus.Deleted);
};

export const getSubmittedResults = <T,>(editResults: SingleFileParseBulkUploadEditResult<T>[]) => {
    return editResults.filter((editResult) => editResult.status === BulkUploadStatus.Submitted);
};

export const getPendingResults = <T,>(editResults: SingleFileParseBulkUploadEditResult<T>[]) => {
    return editResults.filter((editResult) => editResult.status === BulkUploadStatus.Pending);
};

export const getErroredResults = <T,>(editResults: SingleFileParseBulkUploadEditResult<T>[]) => {
    return editResults.filter((editResult) => editResult.status === BulkUploadStatus.Error);
};

export const canSubmitTable = <T,>(editResults: SingleFileParseBulkUploadEditResult<T>[]) => {
    // get pending and valid results for the file
    const pendingResults = getPendingResults(editResults);
    return pendingResults.length > 0 && pendingResults.some((editResult) => editResult.isValid && editResult.errors.length === 0);
    // const pendingValidResults = pendingResults.filter((editResult) => editResult.isValid && editResult.errors.length === 0);
    // return pendingValidResults.length > pendingResults.length;
};

export const canUnsubmitTable = <T,>(editResults: SingleFileParseBulkUploadEditResult<T>[]) => {
    const submittedResults = getSubmittedResults(editResults);
    return submittedResults.length > 0;
};

export const canDeleteTable = <T,>(editResults: SingleFileParseBulkUploadEditResult<T>[]) => {
    const pendingResults = getPendingResults(editResults);
    const erroredResults = getErroredResults(editResults); // Assuming getErroredResults is a method similar to getPendingResults
    return pendingResults.length > 0 || erroredResults.length > 0;
};

export const canRestoreTable = <T,>(editResults: SingleFileParseBulkUploadEditResult<T>[]) => {
    const deletedResults = getDeletedResults(editResults);
    return deletedResults.length > 0;
};

export const canDeleteRow = <T,>(editResult: SingleFileParseBulkUploadEditResult<T>) => {
    return [BulkUploadStatus.Pending, BulkUploadStatus.Error].includes(editResult.status);
};

export const canRestoreRow = <T,>(editResult: SingleFileParseBulkUploadEditResult<T>) => {
    return editResult.status === BulkUploadStatus.Deleted;
};

export const canSubmitRow = <T,>(editResult: SingleFileParseBulkUploadEditResult<T>) => {
    return editResult.status === BulkUploadStatus.Pending && editResult.isValid && editResult.errors.length === 0;
};

export const canUnsubmitRow = <T,>(editResult: SingleFileParseBulkUploadEditResult<T>) => {
    return editResult.status === BulkUploadStatus.Submitted;
};
