import getTransactionTypes from '../../../../utilities/apiHelpers/getTransactionTypes';
import { BulkUploadColumnBase, BulkUploadDataType } from './bulkUploadTypes';

export const isObjectId = (val: string) => {
    const isObjectId = /^[0-9a-fA-F]{24}$/.test(val);
    console.log('isObjectId', isObjectId);

    return isObjectId;
};

const sharedFields: {
    [key: string]: BulkUploadColumnBase;
} = {
    boolean: {
        dataType: BulkUploadDataType.boolean,
        isValid: (val: boolean | string, data) => typeof val === 'boolean' || ['true', 'false', 'yes', 'no'].includes(val.toLowerCase()),
        format: (val: string | boolean) => val === true || val === 'true' || val === 'yes',
        invalidMessage: 'must be "true" or "false"',
        style: {
            width: '100px',
        },
    } as BulkUploadColumnBase<boolean>,
    number: {
        dataType: BulkUploadDataType.number,
        format: (val: string) => parseFloat(val.trim().replace(/[^-0-9.]+/g, '')), // Function to format the value as a number, removing any characters except digits, an initial "-", and a decimal point.
        defaultValue: 0, // Default value for the field.
        isValid: (val, data) => {
            // possibly negative, digits, and possibly a decimal point, then digits
            return /^-?\d+(\.\d+)?$/.test(val.toString());
        },
        invalidMessage: 'must be a number',
        style: {
            width: '100px',
        },
    } as BulkUploadColumnBase<number>,

    date: {
        dataType: BulkUploadDataType.date,
        format: (val: string) => new Date(val).toISOString(), // Function to format the value as a Date object.
        isValid: (val: string, data) => {
            // Validation function to check if the value is a valid date and, for valuations, not in the future.
            if (isNaN(Date.parse(val))) return false;
            return true;
        },
        invalidMessage: 'must be a valid date',
        style: { width: '115px' },
    } as BulkUploadColumnBase<string>,

    object_id: {
        dataType: BulkUploadDataType.id,
        isValid: (val: string, data) => isObjectId(val),
        format: (val: string) => val,
        invalidMessage: 'must be a valid ObjectId',
        style: {
            width: '195px',
        },
    } as BulkUploadColumnBase<string>,

    transaction_type: {
        dataType: BulkUploadDataType.text,
        isValid: (val: string, data) => {
            const types = getTransactionTypes().typeArr.map((type: any) => type.transaction_type);
            const typeNames = getTransactionTypes().typeArr.map((type: any) => type.transaction_type_name);
            return types.includes(val) || typeNames.includes(val);
        },
        format: (val: string) => val,
        invalidMessage: 'must be a valid transaction type',
        style: {},
    } as BulkUploadColumnBase<string>,

    string: {
        dataType: BulkUploadDataType.text,
        isValid: (val: string, data) => val.length > 0,
        format: (val: string) => val,
        invalidMessage: 'must not be empty',
        style: { width: '120px' },
    } as BulkUploadColumnBase<string>,
};

export const sharedFieldEnum = (options: string[]): BulkUploadColumnBase<string> => ({
    dataType: BulkUploadDataType.option,
    // isValid: (val: string, data): boolean => options.includes(val),
    isValid: (val: string, data): boolean => options.map((option) => option.toLowerCase()).includes(val.toLowerCase()),
    format: (val: string): string => options.find((option) => option.toLowerCase() === val.toLowerCase()) || '',
    invalidMessage: `must be one of: ${options.join(', ')}`,
    options,
    getEnumOptions: (data: any) => options,
    style: {
        width: '195px',
    },
});

export default sharedFields;
