import React from 'react';

export const ConfirmButton = ({ onClick, text, confirmText, timeout, color }) => {
    const [confirm, setConfirm] = React.useState(false);

    React.useEffect(() => {
        if (confirm) {
            setTimeout(() => setConfirm(false), timeout ?? 3000);
        }
    }, [confirm, timeout]);

    const handleAction = () => {
        if (!confirm) {
            setConfirm(true);
        } else {
            onClick();
        }
    };

    return (
        <>
            <div style={{ color, cursor: 'pointer', textDecoration: 'underline' }} onClick={handleAction}>
                {confirm ? (confirmText ?? 'Confirm') : (text ?? 'Go!')}
            </div>
        </>
    );
};
