// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import inputKey from '../../utilities/inputKey';

import '../../styles/modal.css';

export default class Modal extends React.Component {
    componentDidMount = () => {
        document.addEventListener('keydown', this.handleKeyDown);
    };

    componentWillUnmount = () => {
        document.removeEventListener('keydown', this.handleKeyDown);
    };

    handleKeyDown = (e) => {
        const p = this.props;
        return inputKey(e, {
            Escape: () => (p.closeOnEscape ? this.closeModal() : null),
        });
    };

    closeModal = () => this.props.modal(<></>, false, '', {}, this.props.fullScreen);

    renderContent = () => {
        if (this.props && this.props.content) {
            return (
                <div className={this.props.className}>
                    {!this.props.modalProps.noClose && (
                        <div className="modal-back" onClick={() => this.props.modal()}>
                            ← Back
                        </div>
                    )}
                    {this.props.content}
                </div>
            );
        } else return <></>;
    };

    render = () => {
        return (
            <div className="modal-container" style={{ ...this.props.modalProps.containerStyle }}>
                <Scrollbars>{this.renderContent()}</Scrollbars>
            </div>
        );
    };
}
