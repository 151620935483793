import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCircleInfo, faInfo, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import styles from '../../styles/iconTooltip.module.scss';

interface IconTooltipProps {
    text: string | JSX.Element;
    icon?: IconDefinition;
    containerStyle?: React.CSSProperties;
    iconStyle?: React.CSSProperties;
    popupStyle?: React.CSSProperties;
}

const IconTooltip: React.FC<IconTooltipProps> = ({ text, icon = faCircleInfo, containerStyle, iconStyle, popupStyle }) => {
    const [isHovered, setIsHovered] = useState(false);
    const tooltipRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handlePosition = () => {
            if (tooltipRef.current) {
                const tooltipRect = tooltipRef.current.getBoundingClientRect();

                const gap = 20;

                let rightExpected = window.innerWidth - gap;
                let rightOverflow = tooltipRect.right - rightExpected;
                if (rightOverflow > 0) {
                    tooltipRef.current.style.marginLeft = `-${rightOverflow}px`;
                }

                let leftExpected = gap;
                let leftOverflow = leftExpected - tooltipRect.left;
                if (leftOverflow > 0) {
                    tooltipRef.current.style.marginLeft = `${leftOverflow}px`;
                }

                let topExpected = gap * 3;
                let topOverflow = topExpected - tooltipRect.top;
                if (topOverflow > 0) {
                    tooltipRef.current.style.marginTop = `${topOverflow}px`;
                }

                let bottomExpected = window.innerHeight - gap;
                let bottomOverflow = tooltipRect.bottom - bottomExpected;
                if (bottomOverflow > 0) {
                    tooltipRef.current.style.marginTop = `-${bottomOverflow}px`;
                }
            }
        };

        if (isHovered) {
            handlePosition();
        }
    }, [isHovered]);

    return (
        <div className={styles.main} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} style={containerStyle}>
            <FontAwesomeIcon
                icon={icon}
                style={{
                    width: 16,
                    height: 16,
                    color: 'var(--color-primary)',
                    cursor: 'pointer',
                    ...iconStyle,
                }}
            />
            {isHovered && (
                <p
                    className={styles.text}
                    ref={tooltipRef}
                    style={{
                        ...popupStyle,
                    }}
                >
                    {text}
                </p>
            )}
        </div>
    );
};

export default IconTooltip;
