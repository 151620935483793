import { Component } from 'react';
import Select from 'react-select';
import { capitalizeFirstEveryWord } from '../../../../utilities/format/capitalizeFirst';
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import IconButton, { CopyButton } from '../../../Buttons/IconButton';
import DropzoneContainer, { DropzoneContainerChildrenProps } from '../DropzoneContainer/DropzoneContainer';
import { FileTypes, FileParseResult, FileTextResult, getMimeTypes } from '../../../../constants/file';
import { BulkUploadFileResult, BulkUploadHeaderComponent, BulkUploadType, BulkUploadTypeNameMap } from '../types/bulkUploadTypes';
import { BulkUploadColumn } from '../types/bulkUploadTypes';
import BulkUploaderReviewStage from '../BulkUploaderReviewStage/BulkUploaderReviewStage';
import { DraggableColumnHeaders } from '../components/DraggableColumnHeaders';
import BulkUploadHandler from '../bulkUploadHandlers/BulkUploadHandler';
import { reorderColumns } from '../utilities/helpers';

import styles from './BulkUploaderDropzone.module.scss';
import BulkUploaderFileRectifyStage from '../BulkUploaderFileRectifyStage/BulkUploaderFileReconcileStage';
import Checkbox from '../../../Inputs/Checkbox';
import IconTextButton from '../../../Buttons/IconTextButton';

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { parseCsvLine } from '../../../../utilities/csv/parseCsvLine/parseCsvLine';

const GLOBAL_NOTES = [
    'Drag and drop file column names in the table to reorder them.',
    'CSV values containing commas should be surrounded by quotes.',
    'To add quotes in the value, add two quotes. Eg. "value1, ""value2"", value3".',
];

const ALLOWED_FILE_TYPES = [FileTypes.csv];

interface BulkUploaderDropzoneState<T = any> {
    headerComponents: Record<string, BulkUploadHeaderComponent[]>;
    fileResults: BulkUploadFileResult<T>[];
    excludeTopRow: boolean;
}

interface BulkUploaderDropzoneProps<T = any> {
    bulkUploadType?: BulkUploadType;
    reloadData?: () => void;
    closeCallback?: () => void;
    close?: () => void;
    handler: BulkUploadHandler<T, any> | null;
    setHandler: (bulkUploadType: BulkUploadType | null) => void;
}

export default class BulkUploaderDropzone<T = any> extends Component<BulkUploaderDropzoneProps<T>, any> {
    state: BulkUploaderDropzoneState<T> = {
        // textFileResults: [],
        // fileParsingResults: [],
        headerComponents: {},
        fileResults: [],
        excludeTopRow: false,
    };

    componentDidMount = async () => {
        this.load();
    };

    load = async () => {};

    openFileDialog: () => void = () => {};

    /**
     * allowed file types, like csv/excel, have a mime type held in the constants file get allowed file types for the data from props, filtered to only include allowed types for this uploader
     */
    getAllowedFileTypes = () => this.props.handler?.allowedFileTypes.filter((type: any) => ALLOWED_FILE_TYPES.includes(type)) || [];

    // get allowed file types as a string for the above function
    getAllowedFileMimeTypes = () => getMimeTypes(this.getAllowedFileTypes());

    showBulkUploadTypeDropdown = () => {
        return !this.props.handler;
    };

    addHeaderComponent = (key: string, component: BulkUploadHeaderComponent) => {
        this.setState((state: BulkUploaderDropzoneState) => {
            const headerComponents = state.headerComponents || {};
            headerComponents[key] = headerComponents[key] || [];
            headerComponents[key].push(component);
            return { headerComponents };
        });
    };

    renderTopRightButtons = () => {
        return (
            <div className={styles.topRightButtons}>
                {this.renderHeaderComponents()}
                {this.props.close && (
                    <IconButton
                        faIcon={faXmarkCircle}
                        size="25px"
                        color="var(--color-primary)"
                        onClick={() => {
                            if (!this.props.close) return;
                            if (!this.props.handler || this.state.fileResults.length === 0 || window.confirm('Are you sure you want to close?')) this.props.close();
                        }}
                    />
                )}
            </div>
        );
    };

    removeHeaderComponent = (key: string) => {
        this.setState((state: BulkUploaderDropzoneState) => {
            const headerComponents = state.headerComponents || {};
            delete headerComponents[key];
            return { headerComponents };
        });
    };

    renderBackButton = (onClick: () => void) => {
        return (
            <div
                style={{
                    position: 'absolute',
                    top: '20px',
                    left: '20px',
                }}
            >
                <IconTextButton faIcon={faArrowLeft} onClick={onClick} />
            </div>
        );
    };

    // Renders the header section of the uploader, optionally including a description
    renderHeader = (subtitle?: string | JSX.Element, onClickBack?: () => void | null) => {
        // const bulkUploadType = this.getBulkUploadType();
        return (
            <>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        position: 'relative',
                        padding: '20px',
                    }}
                >
                    {onClickBack && this.renderBackButton(onClickBack)}

                    {/* document icon */}
                    {/* <div className={`${styles.bulk_mngr_docIcon_blue}`} /> */}
                    <img src={'/images/icons/document_blue.png'} style={{ width: '80px', height: '80px' }} />

                    {/* Title of the uploader */}
                    <div className={`${styles.bulk_mngr_header_title}`}>
                        Upload Bulk {this.props.handler?.type && `${capitalizeFirstEveryWord(this.props.handler?.type.toString().replace(/_/g, ' '))}s`}
                    </div>

                    {/* Subtitle */}
                    {subtitle && <div className={`${styles.bulk_mngr_subtitle}`}>{subtitle}</div>}
                </div>
            </>
        );
    };

    renderHeaderComponents = () => {
        if (Object.keys(this.state.headerComponents).length === 0) {
            return null;
        }
        return (
            <>
                {Object.entries(this.state.headerComponents).map(([key, components]) => {
                    return components.map((component, index) => {
                        return (
                            <div
                                key={`${key}-${index}`}
                                style={{
                                    position: 'absolute',
                                    ...component.style,
                                }}
                            >
                                {component.renderChildren()}
                            </div>
                        );
                    });
                })}
            </>
        );
    };

    // render the column table for the data type
    renderColumnTable = (columns: { [key: string]: BulkUploadColumn }) => {
        const csvHeaderStr = Object.values(columns)
            .map((column) => column.displayName + (column.required ? '*' : ''))
            .join(', ');
        return (
            <div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '5px',
                        fontWeight: 'bold',
                    }}
                >
                    <div
                        style={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        Fields
                        <CopyButton text={csvHeaderStr} title={`Copy ID to Clipboard (${csvHeaderStr})`} size={'14px'} />
                    </div>

                    <div
                        style={{
                            fontSize: '12px',
                            marginTop: '5px',
                            width: '100%',
                            textAlign: 'right',
                        }}
                    >
                        * required
                    </div>
                </div>

                <table className={`${styles.bulkTrans_fieldname_table}`}>
                    <thead>
                        <tr>
                            <DraggableColumnHeaders
                                columns={columns}
                                onDrop={(source: string, target: string) => {
                                    if (!this.props.handler) return;
                                    // on stage 1, this changes the column order in the handler, so applies to all tables
                                    const newColumnOrder = reorderColumns(source, target, this.props.handler.columnOrder as string[]) as (keyof T)[];
                                    this.props.handler.setColumnOrder(newColumnOrder);
                                }}
                            />
                        </tr>
                    </thead>
                </table>
            </div>
        );
    };

    renderNotes = (notes: string[]) => {
        return (
            <ul>
                {notes.map((note) => {
                    return <li>{note}</li>;
                })}
            </ul>
        );
    };

    renderTypeSelectStage = () => {
        if (!this.showBulkUploadTypeDropdown() || this.props.handler) return null;
        return (
            <div
                className={styles.stage}
                style={{
                    height: 'auto',
                }}
            >
                {this.renderHeader(`Select the type of data you would like to upload`)}

                <div style={{ height: '20px' }} />
                <div className={styles.typeDropdown}>
                    <Select
                        options={Object.keys(BulkUploadType).map((type) => {
                            return { value: type, label: BulkUploadTypeNameMap[type as BulkUploadType] || type };
                        })}
                        onChange={(selected) => this.props.setHandler((selected?.value as BulkUploadType) || null)}
                    />
                </div>
            </div>
        );
    };

    // Initial stage rendering, showing the document icon and header with description
    renderDropStage = () => {
        if (this.state.fileResults.length > 0 || !this.props.handler) return null;

        const columns = this.props.handler.getColumns();

        return (
            <div
                className={styles.stage}
                style={{
                    height: 'auto',
                }}
            >
                {this.renderHeader('Drag & Drop your file here or click to browse', () => this.props.setHandler(null))}
                {/* {this.renderHeaderComponents()} */}
                <div style={{ height: '10px' }} />
                <div className="a" style={{ marginTop: '0px', marginBottom: '0px' }} onClick={this.openFileDialog}>
                    Browse Files
                </div>

                <div style={{ fontSize: '12px' }}>{`Supported formats: ${this.getAllowedFileTypes().join(', ')}`}</div>

                <div style={{ height: '20px' }} />

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                    }}
                >
                    <Checkbox checked={this.state.excludeTopRow} setChecked={(excludeTopRow: boolean) => this.setState({ excludeTopRow })} />
                    <span>Exclude top header row</span>
                </div>

                <div style={{ height: '10px' }} />

                {this.renderNotes(GLOBAL_NOTES)}
                {this.renderColumnTable(columns)}

                <div style={{ height: '20px' }} />

                {this.renderNotes(this.props.handler.getNotes())}

                <div style={{ height: '20px' }} />
            </div>
        );
    };

    renderFileRectifyStage = () => {
        if (!this.props.handler) return null;
        const unrectifiedFileResultIdx = this.state.fileResults.findIndex((fileResult) => !fileResult.textFileResult);
        if (unrectifiedFileResultIdx === -1) return null;
        const unrectifiedFileResult = this.state.fileResults[unrectifiedFileResultIdx];
        const filename = unrectifiedFileResult.rawTextFileResult.file.name;
        return (
            <div className={styles.stage}>
                {this.renderHeader('Reconcile file column headers.')}
                <BulkUploaderFileRectifyStage<T>
                    rawFileTextResult={unrectifiedFileResult.rawTextFileResult}
                    setRectifiedFileTextResult={async (rectifiedFileResult) => {
                        if (!this.props.handler) return;
                        // add the rectified file result to the file results at the same index
                        const fileResults = this.state.fileResults;
                        const fileResult = fileResults[unrectifiedFileResultIdx];
                        fileResult.textFileResult = rectifiedFileResult;
                        // parse the rectified file result
                        fileResult.fileParsingResult = await this.props.handler.parseTextFileResult(rectifiedFileResult);
                        this.setState({ fileResults });
                    }}
                    columns={this.props.handler.getColumns()}
                />
            </div>
        );
    };

    // Stage 2: edit and verify data
    renderReviewStage = () => {
        const hasUnrectifiedFiles = this.state.fileResults.some((fileResult) => !fileResult.textFileResult);
        if (hasUnrectifiedFiles || !this.props.handler) return null;

        const fileParsingResults = this.state.fileResults.map((fileResult) => fileResult.fileParsingResult) as FileParseResult<T>[];

        const fileTextResults = this.state.fileResults.map((fileResult) => fileResult.textFileResult) as FileTextResult[];

        return (
            <div className={styles.stage}>
                {this.renderHeader('Review and edit your data', () => {
                    this.setState({ fileResults: [] });
                })}

                <div style={{ height: '20px' }} />

                {this.renderNotes(GLOBAL_NOTES)}

                <div style={{ height: '20px' }} />

                <BulkUploaderReviewStage
                    fileTextResults={fileTextResults}
                    fileParsingResults={fileParsingResults}
                    setFileParsingResults={(fileParsingResults) => this.setState({ fileParsingResults })}
                    handler={this.props.handler}
                    getFileParsingResults={this.getFileParsingResults}
                    addHeaderComponent={this.addHeaderComponent}
                    removeHeaderComponent={this.removeHeaderComponent}
                />
            </div>
        );
    };

    getLinesFromCsvFile = async (csvFile: any): Promise<string[] | false> => {
        return await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const text = e.target?.result;
                if (typeof text !== 'string') {
                    console.log('Error reading file');
                    reject(false);
                    return;
                }
                const lines = text
                    .split('\n')
                    .map((line) => line.trim())
                    .filter((line) => line.length > 0);
                resolve(lines);
            };
            reader.onerror = (e) => {
                console.log('Error reading file', e);
                reject(false);
            };
            reader.readAsText(csvFile);
        });
    };

    /**
     * Handles the drop event for files. It filters out unsupported files, processes the supported ones,
     * and updates the component's state with the new file data.
     *
     * @param {File[]} files - The files dropped into the dropzone.
     */
    onDrop = async (files: File[]) => {
        if (!this.props.handler) {
            window.alert('No handler');
            return;
        }

        // check file types, and reject if not allowed
        if (files.some((file) => !this.getAllowedFileMimeTypes().includes(file.type))) {
            window.alert(`These files have an invalid type:\n\n${files.map((file) => file.name).join('\n')}`);
            return;
        }

        // check for and exlude duplicate files
        const filenames = files.map((file) => file.name);
        const existingFilenames = this.state.fileResults.map((result) => result.rawTextFileResult.file.name);
        const duplicateFiles = filenames.filter((filename) => existingFilenames.includes(filename));
        const newFiles = files.filter((file) => !duplicateFiles.includes(file.name));

        // alert the user of duplicate files
        if (duplicateFiles.length > 0) {
            window.alert(`These files are already loaded:\n\n${duplicateFiles.join('\n')}`);
        }

        // read all files and turn them into a TextFileResult object
        // each has a success flag, a message, the file, and the lines array of the file
        const rawTextFileResults: FileTextResult[] = await Promise.all(
            newFiles.map(async (file) => {
                const lines = await this.getLinesFromCsvFile(file);

                // remove the first line if the checkbox is checked to exclude the top row
                if (lines && this.state.excludeTopRow) {
                    lines.shift();
                }

                return {
                    success: !!lines,
                    message: lines ? 'Success' : 'Error reading file',
                    file,
                    lines,
                } as FileTextResult;
            })
        );

        // make fileResults, which have the raw text, the rectified text, and the parsing result
        // the parsing result only gets filled if the file is rectified and has the correct number of columns
        const fileResults = await Promise.all(
            rawTextFileResults.map(async (rawTextFileResult) => {
                let fileResult: BulkUploadFileResult<T> = {
                    rawTextFileResult,
                    textFileResult: null,
                    fileParsingResult: null,
                };

                if (!this.props.handler) {
                    return fileResult;
                }

                // check if the number of columns in the file is the same as the number of columns in the handler
                const numColumnsExpected = Object.keys(this.props.handler.getColumns()).length;
                const numColumns = Math.max(...rawTextFileResult.lines.map((line) => parseCsvLine(line).length));

                // if the number of columns is the same as the number of columns expected, the file is already rectified
                if (numColumns <= numColumnsExpected) {
                    fileResult.textFileResult = rawTextFileResult;
                    fileResult.fileParsingResult = await this.props.handler.parseTextFileResult(rawTextFileResult);
                }

                return fileResult;
            })
        );

        // parse the text file results into the FileParsingResult object
        // each has a success flag, a message, the file, and the data array of type T of the file
        // this is handled with all lines simultaneously
        // usually, one line is one object, but it's up to the data type object to decide

        // loop through each file and parse the data
        // const fileParsingResults: FileParseResult<T>[] = await this.getFileParsingResults(textFileResults);

        this.setState({
            // textFileResults,
            // fileParsingResults: [...this.state.fileParsingResults, ...fileParsingResults],
            fileResults: [...this.state.fileResults, ...fileResults],
        });
    };

    getFileParsingResults = async (textFileResults: FileTextResult[], columnOrder?: string[]): Promise<FileParseResult<T>[]> => {
        if (!this.props.handler) {
            return [] as FileParseResult<T>[];
        } else {
            return await Promise.all(
                textFileResults.map(async (textFileResult) => {
                    if (!this.props.handler) {
                        return {
                            success: false,
                            message: 'No handler',
                            file: textFileResult.file,
                        } as FileParseResult<T>;
                    }
                    // if was not read successfully, return the same error, converted to a FileParsingResult
                    if (!textFileResult.success || !textFileResult.lines) {
                        return textFileResult as FileParseResult<T>;
                    }
                    return await this.props.handler.parseTextFileResult(textFileResult, columnOrder as (keyof T)[]);
                })
            );
        }
    };

    render = () => {
        const s = this.state;
        const p = this.props;

        return (
            <DropzoneContainer className={styles.main} disabled={!this.props.handler} allowedFileTypes={this.props.handler?.allowedFileTypes || []} onDrop={this.onDrop}>
                {({ openFileDialog }: DropzoneContainerChildrenProps) => {
                    // Store the open file dialog function for later use.
                    if (!this.openFileDialog || this.openFileDialog !== openFileDialog) {
                        this.openFileDialog = openFileDialog;
                    }
                    return (
                        <>
                            {this.renderTopRightButtons()}

                            {this.renderTypeSelectStage() || this.renderDropStage() || this.renderFileRectifyStage() || this.renderReviewStage()}
                        </>
                    );
                }}
            </DropzoneContainer>
        );
    };
}
