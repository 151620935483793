import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import validator from 'validator';

import api2 from '../../api2';
import renderInputError from '../../components/renderInputError';
import { renderInputComponentList } from '../../components/DrawerComponents/renderInputComponentList';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';
import isExtendedAscii from '../../utilities/string/isExtendedAscii';

import '../../styles/addEditAdvisorGroupDrawer.css';
import AdvisoryGroupSearchFilter from '../../components/Filters/AdvisoryGroupSearchFilter';
import { AppProps } from '../../types/props/AppProps';
import { IntegrationSearchFilter } from '../../components/Filters/GenericSearchFilter';
import { Integration } from '../../types/Integration';
import { AssetManager } from '../../types/AssetManager';
import AssetManagerSearchFilter from '../../components/Filters/AssetManagerSearchFilter';
import Multitoggle from '../../components/Toggles/Multitoggle';
import { capitalizeFirstEveryWord } from '../../utilities/format/capitalizeFirst';
import IconTooltip from '../../components/Icons/IconTooltip';
import { ExtractionEnabledDocumentTypes, DocumentImportSetting, DefaultDocumentImportSetting } from '../../types/DefaultDocumentImportSetting';
import IconButton from '../../components/Buttons/IconButton';
import { faX, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { AdvisoryGroup } from '../../types/AdvisoryGroup';
import { format } from 'path';

const documentImportSettingOptions = { ...DocumentImportSetting };

interface AddEditDocumentImportSettingsDrawerProps extends Pick<AppProps, 'loading' | 'loaded' | 'setAlert'> {
    importSettingId?: string;
    close: () => void;
    reloadData: () => void;
}

interface CommonData extends Pick<DefaultDocumentImportSetting, 'default_transaction_settings'> {
    integration?: Integration;
    asset_manager?: AssetManager;
    advisory_group?: AdvisoryGroup;
    global_default_extraction_setting?: DocumentImportSetting;
    global_default_auto_sort_setting?: DocumentImportSetting;
    global_default_transaction_setting?: DocumentImportSetting;
}

interface AddEditDocumentImportSettingsDrawerState {
    commonData: CommonData;
    errors: {
        [key: string]: string | undefined;
    };
}

class AddEditDocumentImportSettingsDrawer extends Component<AddEditDocumentImportSettingsDrawerProps, AddEditDocumentImportSettingsDrawerState> {
    state: AddEditDocumentImportSettingsDrawerState = {
        commonData: {} as CommonData,
        errors: {},
    };

    componentDidMount = () => {
        this.load();
    };

    componentDidUpdate = (prevProps: AddEditDocumentImportSettingsDrawerProps) => {
        if (!_.isEqual(prevProps.importSettingId, this.props.importSettingId)) {
            this.load();
        }
    };

    load = async () => {
        const p = this.props;
        this.props.loading(0, 'AddEditDocumentImportSettingsDrawer');

        if (p.importSettingId) {
            const importSetting = (
                await api2.client.DefaultDocumentImportSettingApi.getDefaultDocumentImportSetting({
                    default_document_import_setting_id: p.importSettingId,
                    populate_asset_manager: true,
                    populate_integration: true,
                    populate_advisory_group: true,
                })
            ).data.default_document_import_setting;

            if (!importSetting) {
                setDismissableAlert(this.props.setAlert, 'Error loading Import Setting.', true);
                this.props.loaded('AddEditDocumentImportSettingsDrawer');
                this.props.close();
                return;
            }

            this.setState({
                commonData: {
                    integration: importSetting.integration as Integration,
                    asset_manager: importSetting.asset_manager as unknown as AssetManager,
                    advisory_group: importSetting.advisory_group as unknown as AdvisoryGroup,
                    global_default_extraction_setting: importSetting.global_default_extraction_setting as DocumentImportSetting,
                    global_default_auto_sort_setting: importSetting.global_default_auto_sort_setting as DocumentImportSetting,
                    global_default_transaction_setting: importSetting.global_default_transaction_setting as DocumentImportSetting,
                    default_transaction_settings: importSetting.default_transaction_settings as CommonData['default_transaction_settings'],
                },
            });
        } else {
            this.setState({
                commonData: {
                    integration: undefined,
                    asset_manager: undefined,
                    advisory_group: undefined,
                    global_default_extraction_setting: documentImportSettingOptions.Enabled,
                    global_default_auto_sort_setting: documentImportSettingOptions.Enabled,
                    global_default_transaction_setting: documentImportSettingOptions.Enabled,
                    default_transaction_settings: {} as CommonData['default_transaction_settings'],
                },
            });
        }

        this.props.loaded('AddEditDocumentImportSettingsDrawer');
    };

    getCommonDataFields = () => {
        let fields: any = {};

        const commonData = this.state.commonData;

        const toPrettyFormat = (str: string) => {
            return str === DocumentImportSetting.Enabled ? 'Enabled' : str === DocumentImportSetting.Disabled ? 'Disabled' : 'Not Set';
        };
        const fromPrettyFormat = (str: string) => {
            return str === 'Enabled' ? DocumentImportSetting.Enabled : str === 'Disabled' ? DocumentImportSetting.Disabled : DocumentImportSetting.Unset;
        };

        const getToggleField = (label: string, fieldName: string, onChange: (value: any) => void, getValue?: (data: any) => any, remove?: (val: string) => void) => {
            return {
                label: label,
                fieldName: fieldName,
                ...(getValue ? { calculateFromData: getValue } : {}),
                type: 'toggle',
                inlineLabel: true,
                labelStyle: { fontWeight: 'normal' },
                options: ['Not Set', 'Disabled', 'Enabled'],
                callback: (val: string) => {
                    onChange(fromPrettyFormat(val));
                },
                format: (val: string) => {
                    return toPrettyFormat(val);
                },
                style: { width: '180px' },
                ...(remove
                    ? {
                          renderRightComponent: () => {
                              return <IconButton faIcon={faXmarkCircle} onClick={() => remove(fieldName)} color="var(--color-dark-red)" />;
                          },
                      }
                    : {}),
            };
        };

        fields['Integration'] = {
            label: 'Integration',
            fieldName: 'integration',
            type: 'custom',
            component: (
                <IntegrationSearchFilter
                    selected={commonData.integration}
                    onChange={(integration) => {
                        this.setStateValue('integration', Array.isArray(integration) ? integration[0] : integration, 'commonData');
                    }}
                    defaultOptions={true}
                />
            ),
        };

        fields['Asset Manager'] = {
            label: 'Asset Manager',
            fieldName: 'asset_manager',
            type: 'custom',
            component: (
                <AssetManagerSearchFilter
                    selected={commonData.asset_manager}
                    onChange={(asset_manager) => {
                        this.setStateValue('asset_manager', Array.isArray(asset_manager) ? asset_manager[0] : asset_manager, 'commonData');
                    }}
                    defaultOptions={true}
                />
            ),
        };

        fields['Advisory Group'] = {
            label: 'Advisory Group',
            fieldName: 'advisory_group',
            type: 'custom',
            component: (
                <AdvisoryGroupSearchFilter
                    selected={commonData.advisory_group}
                    onChange={(advisory_group) => {
                        this.setStateValue('advisory_group', Array.isArray(advisory_group) ? advisory_group[0] : advisory_group, 'commonData');
                    }}
                    defaultOptions={true}
                />
            ),
        };

        fields['Spacer'] = {};

        fields['Default Import Setting Header'] = {
            text: 'Default Import Settings',
            type: 'header',
            renderRightComponent: () => {
                return (
                    <IconTooltip text="If enabled, transactions will be generated for all document types imported by this integration (and for the asset manager if provided), unless the document type is specified with a different setting below." />
                );
            },
        };

        fields['Global Default Extraction Setting'] = getToggleField(
            'Global Extraction Setting',
            'global_default_extraction_setting',
            (newValue: keyof typeof documentImportSettingOptions) => {
                if ((newValue as DocumentImportSetting) === commonData.global_default_extraction_setting) return;
                this.setStateValue('global_default_extraction_setting', newValue as DocumentImportSetting, 'commonData');
            }
        );

        fields['Global Default Auto Sort Setting'] = getToggleField(
            'Global Auto Sort Setting',
            'global_default_auto_sort_setting',
            (newValue: keyof typeof documentImportSettingOptions) => {
                if ((newValue as DocumentImportSetting) === commonData.global_default_auto_sort_setting) return;
                this.setStateValue('global_default_auto_sort_setting', newValue as DocumentImportSetting, 'commonData');
            }
        );

        fields['Global Default Transaction Setting'] = getToggleField(
            'Global Transaction Setting',
            'global_default_transaction_setting',
            (newValue: keyof typeof documentImportSettingOptions) => {
                if ((newValue as DocumentImportSetting) === commonData.global_default_transaction_setting) return;
                this.setStateValue('global_default_transaction_setting', newValue as DocumentImportSetting, 'commonData');
            }
        );

        fields['Document Types'] = {
            placeholder: 'Select Document Types',
            preventOnChangeCallback: true,
            type: 'select',
            options: ExtractionEnabledDocumentTypes.filter((type) => !Object.keys(commonData.default_transaction_settings || {}).includes(type)),
            optionClearable: true,
            callback: (value: any) => {
                if (!value || value.length === 0) return;
                const document_types = { ...commonData.default_transaction_settings };
                if (Object.keys(document_types).includes(value)) return;

                document_types[value] = DocumentImportSetting.Enabled;
                this.setStateValue('default_transaction_settings', document_types, 'commonData');
            },
        };

        Object.keys(commonData.default_transaction_settings || {}).forEach((docType) => {
            fields[docType] = getToggleField(
                docType,
                docType,
                (newValue: keyof typeof documentImportSettingOptions) => {
                    const updatedSettings = { ...commonData.default_transaction_settings };
                    updatedSettings[docType as keyof typeof ExtractionEnabledDocumentTypes] = newValue as DocumentImportSetting;
                    this.setStateValue('default_transaction_settings', updatedSettings, 'commonData');
                },
                (data) => {
                    return commonData.default_transaction_settings[docType as keyof typeof ExtractionEnabledDocumentTypes];
                },
                (fieldName) => {
                    const updatedSettings = { ...commonData.default_transaction_settings };
                    delete updatedSettings[fieldName as keyof typeof ExtractionEnabledDocumentTypes];
                    this.setStateValue('default_transaction_settings', updatedSettings, 'commonData');
                }
            );
        });

        return fields;
    };

    checkCommonData = (data: CommonData, errors: { [key: string]: string | undefined }) => {
        Object.values(this.getCommonDataFields()).forEach((field: any) => {
            const fieldValue = data[field.fieldName as keyof CommonData];
            if (field.required && (!fieldValue || (Array.isArray(fieldValue) && fieldValue.length === 0))) {
                errors[field.fieldName] = 'This field is required.';
            }
        });

        Object.values(this.getCommonDataFields()).forEach((field: any) => {
            const fieldValue = data[field.fieldName as keyof CommonData];
            if (fieldValue && field.isValid && !field.isValid(fieldValue)) {
                errors[field.fieldName] = field.validRequirements ?? 'Invalid value.';
            }
        });
    };

    isSafeToSave = (setErrors = true) => {
        let errors: { [key: string]: string | undefined } = {};
        this.checkCommonData(this.state.commonData, errors);
        const isSafe = Object.keys(errors).length === 0;
        if (setErrors) {
            errors.submit = isSafe ? undefined : 'There are missing/invalid required fields.';
            this.setState({ errors: isSafe ? {} : errors });
        }
        return isSafe;
    };

    createOrUpdateImportSetting = async () => {
        if (!this.isSafeToSave()) return;
        this.props.loading(0, 'AddEditDocumentImportSettingsDrawer');

        let commonData = JSON.parse(JSON.stringify(this.state.commonData));

        const importSettingBody = {
            integration: commonData.integration?._id ?? null,
            asset_manager: commonData.asset_manager?._id ?? null,
            advisory_group: commonData.advisory_group?._id ?? null,
            global_default_extraction_setting: commonData.global_default_extraction_setting,
            global_default_auto_sort_setting: commonData.global_default_auto_sort_setting,
            global_default_transaction_setting: commonData.global_default_transaction_setting,
            default_transaction_settings: commonData.default_transaction_settings,
        };

        if (!this.props.importSettingId) {
            try {
                const setting = (
                    await api2.client.DefaultDocumentImportSettingApi.createDefaultDocumentImportSetting({
                        CreateDefaultDocumentImportSettingRequest: importSettingBody,
                    })
                ).data.default_document_import_setting_id;
                setDismissableAlert(this.props.setAlert, `Created new Import Setting.`);
                this.props.reloadData();
                this.props.loaded('AddEditDocumentImportSettingsDrawer');
                this.props.close();
            } catch (err: any) {
                this.setState({ errors: { submit: err?.response?.data?.message ?? 'Error creating Import Setting' } });
                setDismissableAlert(this.props.setAlert, 'Error creating new Import Setting.', true);
                this.props.loaded('AddEditDocumentImportSettingsDrawer');
                return;
            }
        } else {
            try {
                await api2.client.DefaultDocumentImportSettingApi.updateDefaultDocumentImportSetting({
                    default_document_import_setting_id: this.props.importSettingId,
                    UpdateDefaultDocumentImportSettingRequest: importSettingBody,
                });

                setDismissableAlert(this.props.setAlert, 'Import Setting updated.');
                this.props.reloadData();
                this.load();
                this.props.loaded('AddEditDocumentImportSettingsDrawer');
            } catch (err: any) {
                this.setState({ errors: { submit: err?.response?.data?.message ?? 'Error updating Import Setting' } });
                setDismissableAlert(this.props.setAlert, 'Error updating Import Setting.', true);
                this.props.loaded('AddEditDocumentImportSettingsDrawer');
            }
        }
    };

    clearError = (keys: (keyof AddEditDocumentImportSettingsDrawerState['errors'])[]) => {
        let errors = {
            ...this.state.errors,
        };
        keys.forEach((k) => (errors[k] = undefined));
        errors.submit = this.isSafeToSave(false) ? undefined : 'There are missing required fields';
        this.setState({ errors });
    };

    // generically set state[stateField][key] to the given value
    setStateValue = <K extends keyof CommonData>(key: K, value: CommonData[K], stateField: keyof AddEditDocumentImportSettingsDrawerState) => {
        let update: Partial<AddEditDocumentImportSettingsDrawerState> = {};
        update[stateField] = JSON.parse(JSON.stringify(this.state[stateField] ?? {}));
        (update[stateField] as any)[key] = value;
        this.setState(update as AddEditDocumentImportSettingsDrawerState, () => this.clearError([key]));
    };

    deleteImportSetting = async () => {
        const p = this.props;
        const s = this.state;
        if (!this.props.importSettingId) return;
        if (!window.confirm(`Are you sure you want to delete this import setting?`)) return;
        this.props.loading(0, 'AddEditDocumentImportSettingsDrawer');
        try {
            const deleteRes = await api2.client.DefaultDocumentImportSettingApi.deleteDefaultDocumentImportSetting({
                default_document_import_setting_id: this.props.importSettingId,
            });
            if (deleteRes?.data?.success === true) {
                setDismissableAlert(this.props.setAlert, 'Import Setting deleted.');
                this.props.reloadData();
                this.props.loaded('AddEditDocumentImportSettingsDrawer');
                this.props.close();
            } else {
                setDismissableAlert(this.props.setAlert, 'Error deleting Import Setting.', true);
            }
        } catch (err: any) {
            this.props.loaded('AddEditDocumentImportSettingsDrawer');
            setDismissableAlert(this.props.setAlert, 'Error deleting Import Setting.', true);
        }
    };

    renderSubmitButton = () => {
        return (
            <div>
                <button className="drawer-submit-button" onClick={this.createOrUpdateImportSetting}>
                    {this.props.importSettingId ? 'Save Changes' : 'Create'}
                </button>
                {renderInputError(this.state.errors, 'submit')}
                <div style={{ clear: 'both', height: '20px' }} />
            </div>
        );
    };

    renderCancelButton = () => {
        return (
            <>
                <div className="addNewAdvisorGroup-cancel">
                    <span className="a" onClick={this.props.close}>
                        Cancel
                    </span>
                </div>
                <div style={{ clear: 'both', height: '20px' }} />
            </>
        );
    };

    renderDeleteButton = () => {
        if (!this.props.importSettingId) return null;
        return (
            <>
                <div className="addNewAdvisorGroup-cancel">
                    <span className="a" onClick={this.deleteImportSetting} style={{ color: 'var(--color-dark-red' }}>
                        Delete
                    </span>
                </div>
                <div style={{ clear: 'both', height: '20px' }} />
            </>
        );
    };

    renderGlobalDefaultTransactionSettingToggle = () => {
        return (
            <Multitoggle
                containerStyle={{ width: '70px' }}
                options={Object.keys(documentImportSettingOptions)}
                selection={Object.keys(documentImportSettingOptions).find(
                    (key) => documentImportSettingOptions[key as keyof typeof documentImportSettingOptions] === this.state.commonData.global_default_transaction_setting
                )}
                onSelect={async (opt: keyof typeof documentImportSettingOptions) => {
                    const globalDefaultTransactionSetting = documentImportSettingOptions[opt as keyof typeof documentImportSettingOptions];
                    if (globalDefaultTransactionSetting === this.state.commonData.global_default_transaction_setting) return;
                    this.setStateValue('global_default_transaction_setting', globalDefaultTransactionSetting, 'commonData');
                }}
            />
        );
    };

    render = () => {
        const s = this.state;
        return (
            <>
                <div className="addNewAdvisorGroup-drawer">
                    <h1>{this.props.importSettingId ? `Edit Import Setting` : `Create Import Setting`}</h1>
                    <div>
                        {renderInputComponentList(
                            this.getCommonDataFields(),
                            (key: keyof CommonData, value: CommonData[keyof CommonData]) => this.setStateValue(key, value, 'commonData'),
                            s.commonData,
                            s.errors,
                            true,
                            false,
                            true
                        )}
                    </div>

                    {/* {this.renderGlobalDefaultTransactionSettingToggle()} */}

                    {this.renderSubmitButton()}
                    {this.renderCancelButton()}
                    {this.renderDeleteButton()}
                </div>
                <div style={{ clear: 'both', height: '300px' }} />
            </>
        );
    };
}

export default AddEditDocumentImportSettingsDrawer;
