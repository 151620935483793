// remove field if value is false, otherwise set field to value
export const setQueryParam = (field, value) => {
    const url = new URL(window.location.href);
    if (value) {
        url.searchParams.set(field, value);
    } else {
        url.searchParams.delete(field);
    }
    window.history.replaceState({}, '', url);
};

// clear query params
export const clearQueryParams = (except = []) => {
    const url = new URL(window.location.href);
    const keys = [...url.searchParams.keys()];
    keys.forEach((key) => {
        if (except.includes(key)) return;
        url.searchParams.delete(key);
    });
    window.history.replaceState({}, '', url);

    // const url = new URL(window.location.href)
    // const keys = [...url.searchParams.keys()]
    // keys.forEach(key => url.searchParams.delete(key))
    // window.history.replaceState({}, '', url)
};
