import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faRefresh } from '@fortawesome/free-solid-svg-icons';
import PaginationTable from '../../components/Tables/PaginationTable';
import { setQueryParam, clearQueryParams } from '../../utilities/query/queryParamHelpers';
import { EM_DASH, VUES } from '../../constants/constantStrings';
import AddEditDocumentImportSettingsDrawer from './AddEditDocumentImportSettingsDrawer';
import formatDate, { formatUTCDate, formatUTCDateTime } from '../../utilities/format/formatDate';
import '../../styles/investmentMaster.css';
import IconTextButton from '../../components/Buttons/IconTextButton';
import Search from '../../components/Inputs/Search';
import { format } from 'path';
import { AdminTeam } from '../../types/AdminTeam';
import { AppProps } from '../../types/props/AppProps';
import { DefaultDocumentImportSetting } from '../../types/DefaultDocumentImportSetting';
import { AssetManager } from '../../types/AssetManager';
import { Integration } from '../../types/Integration';
import { capitalizeFirstEveryWord } from '../../utilities/format/capitalizeFirst';
import api from '../../api';
import { toQueryString } from '../../utilities/apiHelpers/queryString';
import { AdvisoryGroup } from '../../types/AdvisoryGroup';
import { CopyButton } from '../../components/Buttons/IconButton';
import { ConnectionSearchFilter, AdvisoryGroupSearchFilter, AssetManagerSearchFilter, IntegrationSearchFilter } from '../../components/Filters/GenericSearchFilter';

interface DocumentImportSettingsTableProps extends Pick<AppProps, 'user' | 'vue' | 'history' | 'loading' | 'loaded' | 'setTitle' | 'closeDrawer' | 'openDrawer' | 'setAlert'> {}

interface DocumentImportSettingsTableState {
    selectedUser?: { _id: string };
    integrationFilter?: Integration;
    assetManagerFilter?: AssetManager;
    advisoryGroupFilter?: AdvisoryGroup;
    connectionFilter?: any; // Add state for connection filter
}

class DocumentImportSettingsTable extends Component<DocumentImportSettingsTableProps, DocumentImportSettingsTableState> {
    childCallables: any = null;

    state: DocumentImportSettingsTableState = {};

    componentDidMount = async () => {
        this.setTitle();
        await this.load();
        this.loadQueryParams();
    };

    componentDidUpdate = (prevProps: DocumentImportSettingsTableProps) => {
        if (prevProps.user?.name !== this.props.user?.name) {
            this.setTitle();
        }
    };

    setTitle = () => {
        this.props.setTitle(
            <>
                <span className="title-account-name">{this.props.user?.name}</span>
                <div className="separator" />
                Default Document Import Settings
            </>
        );
    };

    componentWillUnmount = () => {
        this.props.closeDrawer();
    };

    load = async () => {
        const p = this.props;

        if (p.vue !== VUES.ADMIN) {
            console.log('not admin, redirect to home');
            p.history.push('/');
            return;
        }

        p.loading(0, 'DocumentImportSettingsTable_load');

        this.setState({}, () => {
            this.loadQueryParams();
            p.loaded('DocumentImportSettingsTable_load');
        });
    };

    loadQueryParams = () => {
        const url = new URL(window.location.href);
        const connection = url.searchParams.get('connection');

        this.setState({
            connectionFilter: connection ? { _id: connection } : undefined,
        });
    };

    setChildCallables = (callables: any) => {
        this.childCallables = callables;
    };

    getRoute = () => {
        const query = {
            populate_integration: true,
            populate_asset_manager: true,
            populate_advisory_group: true,
            ...(this.state.integrationFilter?._id && { integration: this.state.integrationFilter?._id }),
            ...(this.state.assetManagerFilter?._id && { asset_manager: this.state.assetManagerFilter?._id }),
            ...(this.state.advisoryGroupFilter?._id && { advisory_group: this.state.advisoryGroupFilter?._id }),
            ...(this.state.connectionFilter?._id && { connection: this.state.connectionFilter?._id }), // Add connection filter to query
        };
        return `/defaultdocumentimportsettings?${toQueryString(query)}`;
    };

    getColumns = () => {
        return [
            {
                title: 'Integration',
                field: 'integration',
                render: (col: any, row: any, items: DefaultDocumentImportSetting[], i: number) => {
                    const setting = items[i];
                    const integ = setting.integration as Integration;
                    return (
                        <td key={'setting_integ' + i} title={`Edit entity`} style={{ width: '50px' }}>
                            {integ && (
                                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                    {integ?.name}
                                    <CopyButton text={integ._id as string} title={`Copy ID to Clipboard (${integ._id})`} />
                                </div>
                            )}
                        </td>
                    );
                },
            },
            {
                title: 'Asset Manager',
                field: 'asset_manager',
                render: (col: any, row: any, items: DefaultDocumentImportSetting[], i: number) => {
                    const setting = items[i];
                    const asset_manager = setting.asset_manager as AssetManager;
                    return (
                        <td key={'setting_mngr' + i} title={`Edit entity`} style={{ width: '50px' }}>
                            {asset_manager && (
                                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                    {asset_manager?.name}
                                    <CopyButton text={asset_manager._id as string} title={`Copy ID to Clipboard (${asset_manager._id})`} />
                                </div>
                            )}
                        </td>
                    );
                },
            },
            {
                title: 'Advisory Group',
                field: 'advisory_group',
                render: (col: any, row: any, items: DefaultDocumentImportSetting[], i: number) => {
                    const setting = items[i];
                    const advisory_group = setting.advisory_group as AdvisoryGroup;
                    return (
                        <td key={'setting_advisory_group' + i} title={`Edit entity`} style={{ width: '50px' }}>
                            {advisory_group && (
                                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                    {advisory_group?.name}
                                    <CopyButton text={advisory_group._id as string} title={`Copy ID to Clipboard (${advisory_group._id})`} />
                                </div>
                            )}
                        </td>
                    );
                },
            },
            {
                title: 'Global Default Transaction Setting',
                field: 'global_default_transaction_setting',
                sort: {
                    field: 'global_default_transaction_setting',
                },
                render: (col: any, row: any, items: DefaultDocumentImportSetting[], i: number) => {
                    const setting = items[i];
                    return (
                        <td key={'setting_global' + i} title={`Edit entity`} style={{ width: '50px' }}>
                            {setting.global_default_transaction_setting === 'unset' ? null : capitalizeFirstEveryWord(setting.global_default_transaction_setting)}
                        </td>
                    );
                },
            },
            {
                title: 'Global Default Sort Setting',
                field: 'global_default_auto_sort_setting',
                sort: {
                    field: 'global_default_auto_sort_setting',
                },
                render: (col: any, row: any, items: DefaultDocumentImportSetting[], i: number) => {
                    const setting = items[i];
                    return (
                        <td key={'setting_global' + i} title={`Edit entity`} style={{ width: '50px' }}>
                            {setting.global_default_auto_sort_setting === 'unset' ? null : capitalizeFirstEveryWord(setting.global_default_auto_sort_setting)}
                        </td>
                    );
                },
            },
            {
                title: 'Global Default Extraction Setting',
                field: 'global_default_extraction_setting',
                sort: {
                    field: 'global_default_extraction_setting',
                },
                render: (col: any, row: any, items: DefaultDocumentImportSetting[], i: number) => {
                    const setting = items[i];
                    return (
                        <td key={'setting_global' + i} title={`Edit entity`} style={{ width: '50px' }}>
                            {setting.global_default_extraction_setting === 'unset' ? null : capitalizeFirstEveryWord(setting.global_default_extraction_setting)}
                        </td>
                    );
                },
            },
            {
                title: 'Default Transaction Settings',
                field: 'default_transaction_settings',
                render: (col: any, row: any, items: DefaultDocumentImportSetting[], i: number) => {
                    const setting = items[i];
                    return (
                        <td key={'setting_default' + i} title={`Edit entity`} style={{ width: '50px' }}>
                            {Object.keys(setting.default_transaction_settings)
                                .map((key) => {
                                    return `${key}: ${setting.default_transaction_settings[key as keyof typeof setting.default_transaction_settings]}`;
                                })
                                .map((s) => (
                                    <div>{capitalizeFirstEveryWord(s)}</div>
                                ))}
                        </td>
                    );
                },
            },
            {
                title: 'Created At',
                field: 'created_at',
                sort: {
                    field: 'created_at',
                },
                render: (col: any, row: any, items: DefaultDocumentImportSetting[], i: number) => {
                    const setting = items[i];
                    return (
                        <td key={'setting_createdAt' + i} title={`Edit entity`} style={{ width: '50px' }}>
                            {formatUTCDate(setting.created_at)}
                        </td>
                    );
                },
            },
            {
                title: 'Edit',
                render: (col: any, row: any, items: AdminTeam[], i: number) => {
                    const setting = items[i];
                    return (
                        <td key={'editEntity' + i} title={`Edit entity`} style={{ width: '50px' }}>
                            <FontAwesomeIcon
                                icon={faPencil}
                                size="xl"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    this.openAddEditImportSettingsDrawer(setting._id);
                                }}
                            />
                        </td>
                    );
                },
            },
        ];
    };

    getBulkActions = () => {
        return [];
    };

    openAddEditImportSettingsDrawer = (teamId?: string) => {
        const p = this.props;
        p.openDrawer(() => (
            <AddEditDocumentImportSettingsDrawer
                importSettingId={teamId}
                close={p.closeDrawer}
                loading={p.loading}
                loaded={p.loaded}
                setAlert={p.setAlert}
                reloadData={this.childCallables.loadData}
            />
        ));
    };

    getSelectFilters = () => {
        const s = this.state;
        const p = this.props;

        let selectFilters = [];

        selectFilters.push({
            label: 'Connection',
            type: 'custom',
            render: () => {
                return (
                    <ConnectionSearchFilter
                        selected={s.connectionFilter}
                        defaultLabel="All Connections"
                        onChange={(connection: any) => {
                            const connectionFilter = Array.isArray(connection) ? connection?.[0] : connection;
                            this.setState({ connectionFilter, integrationFilter: undefined, assetManagerFilter: undefined, advisoryGroupFilter: undefined });
                            clearQueryParams(['integration', 'asset_manager', 'advisory_group']);
                            setQueryParam('connection', connectionFilter?._id);
                        }}
                        defaultOptions={true}
                        width="250px"
                    />
                );
            },
        });

        selectFilters.push({
            label: 'Integration',
            type: 'custom',
            render: () => {
                return (
                    <IntegrationSearchFilter
                        selected={s.integrationFilter}
                        defaultLabel="All Integrations"
                        onChange={(integration: Integration | Integration[]) => {
                            const integrationFilter = Array.isArray(integration) ? integration?.[0] : integration;
                            this.setState({ integrationFilter });
                            setQueryParam('integration', integrationFilter?._id);
                        }}
                        defaultOptions={true}
                        width="250px"
                        disabled={!!s.connectionFilter} // Disable if connection filter is selected
                    />
                );
            },
        });

        selectFilters.push({
            label: 'Asset Manager',
            type: 'custom',
            render: () => {
                return (
                    <AssetManagerSearchFilter
                        defaultLabel={'All Asset Managers'}
                        selected={s.assetManagerFilter}
                        onChange={(asset_manager: AssetManager | AssetManager[]) => {
                            const assetManagerFilter = Array.isArray(asset_manager) ? asset_manager?.[0] : asset_manager;
                            this.setState({ assetManagerFilter });
                            setQueryParam('asset_manager', assetManagerFilter?._id);
                        }}
                        width="250px"
                        defaultOptions={true}
                        noDefaultOption={true}
                        disabled={!!s.connectionFilter} // Disable if connection filter is selected
                    />
                );
            },
        });

        selectFilters.push({
            label: 'Advisory Group',
            type: 'custom',
            render: () => {
                return (
                    <AdvisoryGroupSearchFilter
                        defaultLabel={'All Advisory Groups'}
                        selected={s.advisoryGroupFilter}
                        onChange={(advisory_group: AdvisoryGroup | AdvisoryGroup[]) => {
                            const advisoryGroupFilter = Array.isArray(advisory_group) ? advisory_group?.[0] : advisory_group;
                            this.setState({ advisoryGroupFilter });
                            setQueryParam('advisory_group', advisoryGroupFilter?._id);
                        }}
                        width="250px"
                        defaultOptions={true}
                        noDefaultOption={true}
                        disabled={!!s.connectionFilter} // Disable if connection filter is selected
                    />
                );
            },
        });

        return selectFilters;
    };

    render = () => {
        const s = this.state;
        const p = this.props;
        return (
            <>
                <div className="docProcess-container">
                    <PaginationTable
                        selectFilters={this.getSelectFilters()}
                        noInvestmentDropdown={true}
                        containerStyle={{ margin: '0px' }}
                        name="adminTeams"
                        initialSortField="created_at"
                        initialSortAscending={false}
                        loading={p.loading}
                        loaded={p.loaded}
                        disableDefaultOption={true}
                        noTypeDropdown={true}
                        noDateFilters={true}
                        dateFilterField="created_at"
                        titleStyle={{ margin: 0 }}
                        headerStyle={{
                            alignItems: 'flex-end',
                        }}
                        route={this.getRoute()}
                        title={
                            <>
                                Default Document Import Settings
                                <IconTextButton icon="/images/icons/plus.png" text="New Import Setting" onClick={() => this.openAddEditImportSettingsDrawer()} />
                            </>
                        }
                        columns={this.getColumns()}
                        setCallables={this.setChildCallables}
                        selectedUserId={s.selectedUser?._id}
                        vue={p.vue}
                        disableTypeFilter={true}
                        postProcessData={(data: AdminTeam[]) => {
                            return data;
                        }}
                        enableClickSelect={true}
                        useApi2={true}
                        getResultsFromResponse={(res: any) => res.default_document_import_settings}
                    />
                </div>

                <div style={{ clear: 'both', height: '100px' }} />
            </>
        );
    };
}

export default DocumentImportSettingsTable;
