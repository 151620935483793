import React, { Component } from 'react';
import axios from 'axios';
import { round } from 'lodash';
import Dropzone from 'react-dropzone';

import Scrollbars from 'react-custom-scrollbars';
import { StatusCircle } from '../StatusCircle';
import UTCDatePicker from '../Inputs/UTCDatePicker/UTCDatePicker';

import randomAscii from '../../utilities/string/randomAscii';
import api from '../../api';
import dateToUTCMidnight from '../../utilities/date/dateToUTCMidnight';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';
import { hashFile } from '../../utilities/file/hashFile';
import api2 from '../../api2';
import uploadBlob from '../../utilities/file/uploadBlob';

import '../../styles/bulkDocumentUploader.css';
import { TimelessDate } from '../../types/TimelessDate';

const UPDATE_PROCESSING_CALC = 1000;

const ALLOWED_FILE_TYPES = ['application/pdf', 'text/csv'];

const STATUSES = {
    unstarted: 'unstarted',
    uploading: 'uploading',
    unsupported: 'unsupported file',
    uploadError: 'upload error',
    uploadErrorFileExists: 'upload error: file already exists',
    done: 'done',
    cancelled: 'cancelled',
};

export default class BulkDocumentUploader extends Component {
    fileRefs = [];

    state = {
        docs: [],
        dragging: false,
        currentProgress: {
            uploaded: 0,
            remaining: 0,
            total: 0,
            ratio: 0,
            speed: 0,
            remainingTime: 0,
        },
        focusedDocIdx: null,
        hasChangesIdxs: {},
        currentlyUploading: false,
    };

    dropDiv = React.createRef();
    draggingOffRequest = React.createRef(false);
    draggingTimeout = null;

    startUploadsInterval = null;
    calculateProcessInterval = null;

    componentDidMount = () => {
        // this.startUploadsInterval = setInterval(() => this.startUploads(), 300)
        this.calculateProcessInterval = setInterval(() => this.calculateProcess(), UPDATE_PROCESSING_CALC);
    };

    componentWillUnmount = async () => {
        await this.cancelAllUploads();
        if (this.state.docs.filter((d) => d.status === STATUSES.done).length) {
            let _ = this.props.reloadData?.();
        }
        clearInterval(this.startUploadsInterval);
        clearInterval(this.calculateProcessInterval);
    };

    // check for files to start uploading every time interval set in mount function
    startUploads = () => {
        if (!this.props.investment) return;
        this.state.docs.filter((doc) => doc.status === STATUSES.unstarted).forEach(this.uploadFile);
    };

    uploadFile = async (doc) => {
        this.setState({ currentlyUploading: true });
        this.setDocValue(doc.id, 'status', STATUSES.uploading);

        const hash = await hashFile(doc.blob);
        const userId = this.props.investment.user._id ?? this.props.investment.user;

        // upload doc to AltX api
        try {
            // create document
            const docDate = doc.date ?? new TimelessDate();
            const createDocRes = await api2.client.DocumentApi.createDocument({
                CreateDocumentRequest: {
                    ownership_type: 'Investment',
                    ownership_ids: [this.props.investment._id],
                    user: userId,
                    name: doc.name,
                    hash,
                    type: null,
                    date: docDate?.toString(),
                    metadata: [],
                },
            });
            const newDocId = createDocRes.data.document_id;

            // setup ability to abort
            const abortController = new AbortController();
            this.setDocValue(doc.id, 'controller', abortController);
            this.setDocValue(doc.id, 'altX_id', newDocId);

            // upload doc to S3
            const awsUploadRes = await uploadBlob(
                createDocRes.data.upload_url,
                doc.blob,
                doc.type,
                (progressEvent) => {
                    this.setDocValue(doc.id, 'amtUploaded', progressEvent.loaded);
                },
                abortController.signal
            );

            // if successful and not cancelled
            if (awsUploadRes.status === 200 && this.state.docs.filter((d) => d.id === doc.id && d.status === STATUSES.cancelled).length === 0) {
                // mark document as done so it can be processed
                this.setDocValue(doc.id, 'status', STATUSES.done);
                try {
                    await api2.client.DocumentApi.markDocumentAsUploaded({ document_id: newDocId });
                } catch (err) {
                    console.error('Error marking document as uploaded', err);
                    this.setDocValue(doc.id, 'status', STATUSES.uploadError);
                }
            } else {
                this.setDocValue(doc.id, 'status', STATUSES.uploadError);
            }
        } catch (err) {
            console.error('Error uploading document', err);
            this.setDocValue(doc.id, 'status', STATUSES.uploadError);
        }

        this.setState({ currentlyUploading: false });
    };

    cancelAllUploads = async () => {
        const toCancel = this.state.docs.filter((d) => d.status === STATUSES.uploading);
        for (let i = 0; i < toCancel.length; i++) {
            await this.cancelUpload(toCancel[i]);
        }
    };

    cancelUpload = async (doc, docIdx) => {
        if (doc.controller.abort) {
            doc.controller.abort();
            this.setDocValue(doc.id, 'status', STATUSES.cancelled);
            // const deleteRes = await api.delete(`/documents/${doc.altX_id}`)
            try {
                await api2.client.DocumentApi.deleteDocument({ document_id: doc.altX_id });
            } catch (err) {
                console.error('Error deleting document', err);
            }
        } else {
            console.log('Cannot abort');
        }
    };

    hasChangesToSave = () => this.state.docs.filter((doc) => doc.status === STATUSES.unstarted).length > 0;

    renderHeader = (includeDescription = false) => {
        return (
            <>
                <div className="bulkDoc-header-title">Add New Documents</div>
                {includeDescription && (
                    <>
                        <div style={{ height: '20px' }} />
                        <div style={{ marginBottom: '8px' }}>Drag & Drop your document here</div>
                        <div>or</div>
                    </>
                )}
                {this.renderBrowseFiles()}
                {includeDescription && this.getAllowedTypesString()}
            </>
        );
    };

    renderBrowseFiles = () => (
        <div className="a" style={{ marginTop: '8px', marginBottom: '14px' }} onClick={(e) => this.dropDiv.current?.click(e)}>
            Browse Files
        </div>
    );

    renderDragging = () => (
        <div className="bulkDoc-dragging">
            <div className="bulkDoc-docIcon-white" />
            Drop your document here
        </div>
    );

    renderInitStage = () => (
        <>
            <div className="bulkDoc-docIcon-blue" />
            {this.renderHeader(true)}
        </>
    );

    renderSaveButton = () => {
        return (
            <button
                className="drawer-submit-button"
                onClick={this.startUploads}
                // onClick={this.saveChanges}
                style={{ marginTop: '20px', width: '175px' }}
            >
                Upload Document(s)
            </button>
        );
    };

    renderDocListStage = () => {
        const s = this.state;
        const nUploading = this.state.docs.filter((d) => d.status === STATUSES.uploading).length;
        return (
            <>
                {this.renderHeader(false)}
                <div className="bulkDoc-docList" style={{ marginTop: '15px', marginBottom: '10px' }}>
                    <div className="bulkDoc-docList-header">
                        <div>
                            Uploading {nUploading} file{this.state.length !== 1 ? 's' : ''}
                        </div>
                        {nUploading > 0 && <div>{this.getTimeRemaining()}</div>}
                        {nUploading > 0 && (
                            <div style={{ position: 'absolute', right: '20px', margin: 0 }} className="a" onClick={this.cancelAllUploads}>
                                Cancel
                            </div>
                        )}
                    </div>
                    <div className="bulkDoc-docList-body">
                        <Scrollbars>{this.state.docs.map(this.renderDocRow)}</Scrollbars>
                    </div>
                </div>
                <span>{this.getAllowedTypesString()}</span>
                {this.hasChangesToSave() ? this.renderSaveButton() : null}
            </>
        );
    };

    renderDocRow = (doc, docIdx) => {
        return (
            <div className="bulkDoc-docRow" key={`bulkDoc_${docIdx}`}>
                <div className="bulkDoc-docIcon-blue" style={{ padding: '16px 11px', marginRight: '15px' }} />
                <div className="bulkDoc-docRow-name table-ellipses-3-lines1">{doc.name}</div>
                {/* only show date input if doc hasn't been uploaded yet */}
                {doc.status === STATUSES.unstarted && <div style={{ position: 'absolute', right: 80 }}>{this.renderDatePicker(doc, docIdx)}</div>}
                <div style={{ position: 'absolute', right: 30 }}>{this.renderStatus(doc, docIdx)}</div>
            </div>
        );
    };

    // note: the ref, focus, blur, and preventOpenOnFocus are to facilitate tabbing through the date pickers
    renderDatePicker = (doc, docIdx) => {
        const s = this.state;
        const p = this.props;
        return (
            <UTCDatePicker
                placeholderText="MM-DD-YYYY"
                dateFormat="MM-dd-yyyy"
                selected={doc.date}
                tabIndex={docIdx + 1}
                onChange={(newDate) => {
                    // const hasChangesIdxs = { ...s.hasChangesIdxs }
                    // hasChangesIdxs[doc.id] = newDate !== null
                    const docs = [...s.docs];
                    docs[docIdx].date = newDate;
                    this.setState({
                        docs,
                        // hasChangesIdxs,
                    });
                }}
            />
        );
    };

    renderStatus = (doc, docIdx) => {
        const filledColor = 'var(--color-link)';
        const circleSize = 25;

        const getStatusDiv = (doc) => {
            switch (doc.status) {
                case STATUSES.uploading:
                    return (
                        <StatusCircle
                            size={circleSize}
                            percent={this.getUploadProgress(doc)}
                            filledColor={filledColor}
                            emptyColor={'var(--color-lighter-gray'}
                            onClick={!doc.controller?.abort ? null : () => this.cancelUpload(doc, docIdx)}
                        />
                    );
                case STATUSES.unstarted:
                    return <StatusCircle percent={this.getUploadProgress(doc)} size={circleSize} filledColor={filledColor} emptyColor={'var(--color-lighter-gray'} />;
                case STATUSES.unsupported:
                    return <div style={{ color: 'var(--color-dark-red' }}>{STATUSES.unsupported}</div>;
                case STATUSES.uploadError:
                    return <div style={{ color: 'var(--color-dark-red' }}>{STATUSES.uploadError}</div>;
                case STATUSES.uploadErrorFileExists:
                    return <div style={{ color: 'var(--color-dark-red' }}>{STATUSES.uploadErrorFileExists}</div>;
                case STATUSES.cancelled:
                    return <div style={{ color: 'var(--color-dark-red' }}>{STATUSES.cancelled}</div>;
                case STATUSES.done:
                    return (
                        <div className="bulkDoc-check" style={{ '--c': filledColor, '--w': circleSize }}>
                            <div />
                        </div>
                    );

                default:
                    break;
            }
        };

        return <div>{getStatusDiv(doc)}</div>;
    };

    getAllowedTypesString = () => {
        const listStr = ALLOWED_FILE_TYPES.map((t) => (t.includes('/') ? t.split('/')[1] : t)).join(', ');
        return <span style={{ fontSize: '12px' }}>{`Supported formats: ${listStr}`}</span>;
    };

    onDrop = async (files) => {
        this.setDragging(false, true);
        const fileToBlob = async (_file) => new Blob([new Uint8Array(await _file.arrayBuffer())], { type: _file.type });
        for (let i = 0; i < files.length; i++) {
            if (ALLOWED_FILE_TYPES.includes(files[i].type)) {
                files[i].status = STATUSES.unstarted;
                files[i].blob = await fileToBlob(files[i]);
            } else {
                files[i].status = STATUSES.unsupported;
            }
            files[i].id = randomAscii(15);
        }
        this.setState({ docs: [...files, ...this.state.docs] });
    };

    // without this timer system, the dragOver and dragLeave functions continuously get called, causing flickering
    setDragging = (dragging, force = false) => {
        if (force) {
            this.setState({ dragging });
            this.draggingOffRequest.current = null;
            clearTimeout(this.draggingTimeout);
        } else {
            // attempt to limit flickering
            const THRESHOLD = 1000;
            const TIMEOUT = 100;
            const nowUtc = dateToUTCMidnight();
            if (dragging) {
                // turn on dragging
                this.setState({ dragging });
                this.draggingOffRequest.current = null;
            } else if (!dragging && this.draggingOffRequest.current && nowUtc.getTime() - this.draggingOffRequest.current > THRESHOLD) {
                // turn off dragging, clear timer and timeout
                this.setState({ dragging });
                this.draggingOffRequest.current = null;
                if (this.draggingTimeout) clearTimeout(this.draggingTimeout);
            } else if (!dragging && !this.draggingOffRequest.current) {
                // reset timer and set timeout
                this.draggingOffRequest.current = nowUtc.getTime();
                if (this.draggingTimeout) clearTimeout(this.draggingTimeout);
                this.draggingTimeout = setTimeout(() => this.setDragging(dragging), TIMEOUT);
            } else {
                // call timeout again
                this.draggingTimeout = setTimeout(() => this.setDragging(dragging), TIMEOUT);
            }
        }
    };

    setDocValue = (docId, field, value) => {
        let docs = [...this.state.docs];
        docs = docs.map((d) => {
            if (d.id === docId) d[field] = value;
            return d;
        });
        this.setState({ docs });
    };

    getUploadProgress = (doc) => (!doc.amtUploaded || !doc.blob.size ? 0 : (doc.amtUploaded / doc.blob.size) * 100);

    calculateProcess = () => {
        let uploaded = 0;
        let total = 0;
        this.state.docs
            .filter((d) => d.status === STATUSES.uploading)
            .forEach((d) => {
                uploaded += d.amtUploaded ?? 0;
                total += d.blob.size;
            });
        const remaining = total - uploaded;

        let speed = (this.state.currentProgress.remaining - remaining) / (UPDATE_PROCESSING_CALC / 1000);
        let remainingTime = 0;

        // if another file has been added or has finished, use old speed to calculate time
        const totalDiff = total - this.state.currentProgress.total;
        if (totalDiff !== 0) {
            remainingTime = this.state.currentProgress.speed <= 0 ? (remaining === 0 ? 0 : -1) : remaining / this.state.currentProgress.speed;
        }
        // else, same files are still uploading
        else {
            remainingTime = speed <= 0 ? (remaining === 0 ? 0 : -1) : remaining / speed;
        }

        const currentProgress = {
            ...this.state.currentProgress,
            uploaded,
            remaining,
            total,
            ratio: total === 0 ? 0 : uploaded / total,
            speed,
            remainingTime, // seconds
        };
        this.setState({ currentProgress });
    };

    getTimeRemaining = () => {
        const t = this.state.currentProgress.remainingTime;
        if (t <= 0) return '';
        if (t < 60) return `${round(t)} second${t !== 1 ? 's' : ''} left`;
        if (t < 60 * 60) return `${round(t / 60)} min${t !== 1 ? 's' : ''} left`;
        return `${round(t / 60 / 60)} hour${t !== 1 ? 's' : ''} left`;
    };

    render = () => {
        const s = this.state;
        const p = this.props;

        return (
            <Dropzone onDrop={this.onDrop} multiple={true} onDragEnter={() => this.setDragging(true)} onDragLeave={() => this.setDragging(false)}>
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps({ className: 'bulkDoc-component' })} ref={this.dropDiv} onMouseOut={!s.dragging ? null : () => this.setDragging(false, true)}>
                        <div onClick={(e) => e.stopPropagation()} className="bulkDoc-innerComponent">
                            {!s.currentlyUploading && (
                                <div
                                    className="bulkDoc-close"
                                    onClick={() => {
                                        if (!this.hasChangesToSave() || window.confirm('You have unsaved changes. Are you sure you want to close?')) p.close();
                                    }}
                                />
                            )}
                            {s.docs.length === 0 ? this.renderInitStage() : this.renderDocListStage()}
                            {s.dragging && this.renderDragging()}
                        </div>
                    </div>
                )}
            </Dropzone>
        );
    };
}

export const openBulkDocumentUploader = (openModal, investment, setAlert, reloadData, open = true) => {
    if (!open) openModal();
    return openModal(
        <BulkDocumentUploader investment={investment} close={openModal} setAlert={setAlert} reloadData={reloadData} />,
        true,
        'bulk-upload-modal',
        {
            containerStyle: { position: 'fixed', backgroundColor: 'rgba(42, 66, 102, 0.55)' },
            noClose: true,
        },
        true,
        false
    );
};
