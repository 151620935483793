import api2 from '../../api2';
import { addQueryToRoute } from '../../utilities/apiHelpers/addQueryToRoute';
import promiseAllRequests from '../../utilities/apiHelpers/promiseAllRequests';
import { fromQueryString } from '../../utilities/apiHelpers/queryString';

// note: "many" functions do not throw errors, they just return an array with the results of the requests
// which may include errors.  Check for "success === true" to see if the request was successful

type QueryType = string | Record<string, any>;

const get = <T = any>(resourceName: string, apiInstance: any) => {
    return async (id: string, query: QueryType): Promise<T> => {
        let route = `/${resourceName}/${id}`;
        route = addQueryToRoute(route, query);
        const response = await apiInstance.get(route);
        return response.data;
    };
};

const list = <T = any>(resourceName: string, apiInstance: any) => {
    return async (query: QueryType): Promise<T[]> => {
        let route = `/${resourceName}`;
        route = addQueryToRoute(route, query);
        const response = await apiInstance.get(route);
        return response.data;
    };
};

// use if you need to get more than 100 results
const paginateList = <T = any>(resourceName: string, apiInstance: any) => {
    return async (query: QueryType = {}, limit: number | null = null, resultsPath: string = `data.${resourceName}`): Promise<T[]> => {
        console.log(`Starting pagination for ${resourceName} with limit: ${limit} and resultsPath: ${resultsPath}`);
        const resultsFieldArray = resultsPath.split('.');
        const getDataFromResults = (data: any) => {
            resultsFieldArray.forEach((field) => {
                data = data[field];
            });
            return data;
        };

        const queryObj = typeof query === 'string' ? fromQueryString(query) : { ...query };
        let chunkLimit = 100;
        queryObj.limit = chunkLimit;
        queryObj.skip = 0;

        let results: any[] = [];
        let dataRes = await list<T[]>(resourceName, apiInstance)(queryObj);
        let data = getDataFromResults(dataRes);
        results = results.concat(data);

        const getAllResults = limit === null || limit < 0;
        const shouldGetMoreResults = (currentLength: number) => getAllResults || currentLength < (limit as number);
        while (data.length === chunkLimit && shouldGetMoreResults(results.length)) {
            if (!getAllResults && limit !== null) {
                const remaining = limit - results.length;
                chunkLimit = Math.min(chunkLimit, remaining);
            }
            queryObj.skip += chunkLimit;
            dataRes = await list<T[]>(resourceName, apiInstance)(queryObj);
            data = getDataFromResults(dataRes);
            results = results.concat(data);
        }

        console.log(`Pagination complete for ${resourceName}. Total results fetched:`, results.length);
        return results;
    };
};

const create = <T = any>(resourceName: string, apiInstance: any) => {
    return async (body: any): Promise<any> => {
        let route = `/${resourceName}`;
        return await apiInstance.post(route, body);
    };
};

const update = <T = any>(resourceName: string, apiInstance: any) => {
    return async (id: string, body: any): Promise<any> => {
        let route = `/${resourceName}/${id}`;
        return await apiInstance.patch(route, body);
    };
};

const updateMany = <T = any>(resourceName: string, apiInstance: any) => {
    return async (updates: { id: string; body: any }[]): Promise<any[]> => {
        const requests = updates.map((updateObj) => update<any>(resourceName, apiInstance)(updateObj.id, updateObj.body));
        return await promiseAllRequests(requests);
    };
};

const deleteFunction = <T = any>(resourceName: string, apiInstance: any) => {
    return async (id: string): Promise<any> => {
        let route = `/${resourceName}/${id}`;
        console.log('delete route', route);
        return await apiInstance.delete(route);
    };
};

const deleteMany = <T = any>(resourceName: string, apiInstance: any) => {
    return async (ids: string[]): Promise<any[]> => {
        const requests = ids.map((id) => deleteFunction<any>(resourceName, apiInstance)(id));
        return await promiseAllRequests(requests);
    };
};

const baseCalls = <T = any>(resourceName: string, apiInstance: any = api2) => {
    return {
        get: get<T>(resourceName, apiInstance),
        list: list<T>(resourceName, apiInstance),
        paginateList: paginateList<T>(resourceName, apiInstance),
        create: create<T>(resourceName, apiInstance),
        update: update<T>(resourceName, apiInstance),
        updateMany: updateMany<T>(resourceName, apiInstance),
        delete: deleteFunction<T>(resourceName, apiInstance),
        deleteMany: deleteMany<T>(resourceName, apiInstance),
    };
};

export default baseCalls;
