import { FileParseResult, FileTextResult } from '../../../../constants/file';
import BulkUploadHandler from './BulkUploadHandler';
import sharedFields, { isObjectId } from '../types/sharedFields';
import { BulkUploadColumn, BulkUploadRequestResult, BulkUploadType } from '../types/bulkUploadTypes';
import api from '../../../../api';
import UserUploadHandler, { UserUploadHandlerConfig, UserUploadHandlerCreateType } from './UserUploadHandler';

export interface AdvisorUploadHandlerConfig extends Omit<UserUploadHandlerConfig, 'access'> {}

export interface AdvisorUploadHandlerCreateType extends Omit<UserUploadHandlerCreateType, 'access'> {
    advisory_group?: string;
}

class AdvisorUploadHandler extends BulkUploadHandler<AdvisorUploadHandlerCreateType, AdvisorUploadHandlerConfig> {
    // The type of the bulk upload, eg. 'user' or 'valuation'
    type = BulkUploadType.advisor;

    // instance of UserUploadHandler to use base functions
    userUploadHandlerInstance = new UserUploadHandler({
        access: 'advisor',
    });

    // The base columns that are required for the bulk upload
    base_columns = {
        name: this.userUploadHandlerInstance.base_columns.name,
        email: this.userUploadHandlerInstance.base_columns.email,
        password: this.userUploadHandlerInstance.base_columns.password,
        advisory_group: {
            ...sharedFields.object_id,
            displayName: 'Advisory Group',
            fieldName: 'advisory_group',
            style: { width: '250px' },
            required: false,
        },
    };

    // The order of the columns in the CSV file
    columnOrder = Object.keys(this.base_columns) as (keyof AdvisorUploadHandlerCreateType)[];

    // sort the columns based on the order in columnOrder, or the default order if not provided
    getColumns = (columnOrder: (keyof AdvisorUploadHandlerCreateType)[] = this.columnOrder): { [key in keyof AdvisorUploadHandlerCreateType]: BulkUploadColumn } => {
        // Sort the columns based on the given column order or the default order.
        const sortedColumns = this._sortColumns(this.base_columns, columnOrder);
        delete sortedColumns.access;
        return sortedColumns;
    };

    // check a single row of data to see if it is valid
    isDataValid = (data: { [key in keyof AdvisorUploadHandlerCreateType]: string }, columnOrder?: (keyof AdvisorUploadHandlerCreateType)[]) => {
        const columns = this.getColumns(columnOrder);
        const isDataValid = this._isColumnDataValid(columns, data, columnOrder);
        // any additional validation goes here
        return isDataValid;
    };

    /**
     * Parses a single line from a CSV file into an object with the correct fields.
     * Does not handle validation.
     * @param {string} line A single line from a CSV file.
     * @returns {Object} An object with the correct fields for the line.
     */
    parseSingleCsvLine = (line: string, columnOrder?: (keyof AdvisorUploadHandlerCreateType)[]): AdvisorUploadHandlerCreateType => {
        // if not enough commas are included, the fields will be empty strings
        const expectedColumns = this.getColumns(columnOrder);
        const parsedValues = this._parseSingleCsvLine(line, columnOrder);

        // set up special conditions based on the config and data type
        return {
            name: parsedValues.name,
            email: parsedValues.email,
            password: parsedValues.password,

            // Only include the advisory_group if it's provided and valid.
            ...(parsedValues.advisory_group
                ? {
                      advisory_group:
                          expectedColumns.advisory_group && expectedColumns.advisory_group.isValid(parsedValues.advisory_group)
                              ? expectedColumns.advisory_group.format(parsedValues.advisory_group)
                              : '',
                  }
                : {}),
        };
    };

    // Parse the text file results into a FileParseResult
    parseTextFileResult = async (
        textFileResult: FileTextResult,
        columnOrder?: (keyof AdvisorUploadHandlerCreateType)[]
    ): Promise<FileParseResult<AdvisorUploadHandlerCreateType>> => {
        try {
            const data = textFileResult.lines.map((line) => this.parseSingleCsvLine(line, columnOrder));
            return {
                success: true,
                message: 'File parsed successfully',
                file: textFileResult.file,
                data,
            } as FileParseResult<AdvisorUploadHandlerCreateType>;
        } catch (err: any) {
            return {
                success: false,
                message: `Error parsing file: ${err.message}`,
                file: textFileResult.file,
            } as FileParseResult<AdvisorUploadHandlerCreateType>;
        }
    };

    // Get the notes for the bulk upload type
    getNotes = (): string[] => {
        let notes: string[] = [];
        return notes;
    };

    // function to create the object in the database from the parsed data
    create = async (
        columnObj: { [key: string]: BulkUploadColumn },
        data: { [key: string]: any },
        columnOrder?: (keyof AdvisorUploadHandlerCreateType)[]
    ): Promise<BulkUploadRequestResult> => {
        // Ensure the data is valid
        if (!this.isDataValid(data, columnOrder)) {
            return { success: false, message: 'Invalid data' };
        }

        let access = 'advisor';

        let groupId;
        if (data.advisory_group) {
            groupId = data.advisory_group;
        }

        try {
            // Attempt to create a single user from the current object.
            const createRes = await api.post(
                '/users',
                {
                    name: data.name,
                    email: data.email,
                    password: data.password,
                    access,
                    groupId,
                },
                {},
                true
            );

            if (createRes.success && createRes.user?._id) {
                return {
                    success: true,
                    message: 'Advisor created successfully',
                    id: createRes.user?._id,
                };
            } else {
                return {
                    success: false,
                    message: `Error creating Advisor.`,
                };
            }
        } catch (err) {
            // Log and return null in case of an error.
            console.log('error creating user:', err);
            return {
                success: false,
                message: `Error creating user: ${(err as any)?.response?.data?.message || (err as any).message}`,
            };
        }
    };

    // function to delete the object from the database
    delete = async (id: string): Promise<BulkUploadRequestResult> => {
        try {
            const deleteRes = await api.delete(`/users/${id}`, {}, true);
            return {
                success: true,
                message: 'User deleted successfully',
            };
        } catch (err) {
            console.log('error deleting user:', err);
            return {
                success: false,
                message: `Error deleting user: ${(err as any)?.response?.data?.message || (err as any).message}`,
            };
        }
    };
}

// getter for the AdvisorUploadHandler
export const getAdvisorUploadHandler: (config: AdvisorUploadHandlerConfig) => AdvisorUploadHandler = (config: AdvisorUploadHandlerConfig) => new AdvisorUploadHandler(config);

export default AdvisorUploadHandler;
