import React from 'react';
import ObjectSearchSelectTs, { ObjectSearchSelectProps } from '../Dropdowns/ObjectSearchSelectTs';
import { toQueryString } from '../../utilities/apiHelpers/queryString';
import { Document } from '../../types/Document';
import api2 from '../../api2';
import { EM_DASH } from '../../constants/constantStrings';
import formatDate from '../../utilities/format/formatDate';
import { generateStringHash } from '../../utilities/string/generateStringHash';
import renderOwnershipInfo from '../../common/documents/renderOwnershipInfo';
import { VUES } from '../../constants/constantStrings';
import routes from '../../routes';
import { UserAccess } from '../../types/User';

type DocumentSearchFilterProps = Omit<ObjectSearchSelectProps<Document>, 'getLabel'> & {
    filter?: any;
    excludeDisplayFields?: (keyof Document | 'ownership')[];
    vue?: (typeof VUES)[keyof typeof VUES] | null;
    linkDocument?: boolean;
};

const DocumentSearchFilter = ({
    filter = {},
    onChange,
    selected,
    isMulti = false,
    formatOptionLabel,
    width,
    defaultOptions = false,
    defaultLabel = '',
    excludeDisplayFields = [],
    label = '',
    isDisabled = false,
    vue = null,
    linkDocument = false,
}: DocumentSearchFilterProps) => {
    let filterHash = generateStringHash(JSON.stringify(filter));
    return (
        <ObjectSearchSelectTs<Document>
            key={filterHash}
            label={label}
            placeholder={`Select Document${isMulti ? 's' : ''}`}
            selected={selected}
            onChange={onChange}
            getLabel={(document: Document) => document?.name}
            matchProperty="_id"
            loadOptions={async (inputValue: string) => {
                try {
                    const documents = (
                        await api2.client.DocumentApi.listDocuments({
                            ...filter,
                            search: inputValue,
                            populate_user: true,
                            populate_ownership: true,
                            limit: 50,
                            sort_field: 'name',
                        })
                    ).data.documents;

                    return documents as any;
                } catch (e) {
                    console.error('Error loading documents:', e);
                    return [];
                }
            }}
            width={width ?? '100%'}
            isMulti={isMulti}
            formatOptionLabel={(option) => {
                if (formatOptionLabel) return formatOptionLabel(option);
                const document: Document = option.value;
                if (!document) return defaultLabel || 'None';

                let path;
                if (vue && linkDocument) {
                    const userId = document.user?._id || (document.user as unknown as string);
                    path = routes.client.viewDocument({
                        userId,
                        access: vue as UserAccess,
                        documentId: document._id,
                    });
                }

                return (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                // marginBottom: '5px',
                                width: '100%',
                            }}
                        >
                            {!excludeDisplayFields.includes('type') && <div style={{ fontSize: '12px', color: 'var(--color-light-gray)' }}>{document.type}</div>}

                            {!excludeDisplayFields.includes('date') ? (
                                <div style={{ fontSize: '12px', color: 'var(--color-light-gray)' }}>{document.date ? formatDate(document.date) : EM_DASH}</div>
                            ) : (
                                <div />
                            )}
                        </div>

                        {!excludeDisplayFields.includes('user') && <div style={{ fontSize: '12px', color: 'var(--color-light-gray)' }}>{document.user?.name || EM_DASH}</div>}

                        {!excludeDisplayFields.includes('ownership') && (
                            <div className={'table-ellipses'} style={{ fontSize: '12px', color: 'var(--color-light-gray)' }}>
                                {renderOwnershipInfo(document)}
                            </div>
                        )}

                        <div className={'table-ellipses'} style={{ fontSize: '14px' }} title={document.name}>
                            {path ? (
                                <a href={path} target="_blank" rel="noreferrer" style={{ color: 'var(--color-primary)' }}>
                                    {document.name}
                                </a>
                            ) : (
                                document.name
                            )}
                        </div>
                    </div>
                );
            }}
            defaultLabel={defaultLabel || 'None'}
            defaultOptions={defaultOptions}
            isDisabled={isDisabled}
        />
    );
};

export default DocumentSearchFilter;
