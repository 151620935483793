import { CopyButton } from '../../components/Buttons/IconButton';
import { EM_DASH, VUES } from '../../constants/constantStrings';
import routes from '../../routes';

/**
 * Currenly RalRequests and Documents have ownership info.
 * @param {object} document with ownership and user populated
 * @param {string | null} vue investor, advisor, admin.  If not provided, ownership ids will not be clickable links
 * @param {string | null} globalInvestorAccount for investor vue, the account id that is selected globally
 * @param {boolean} alwaysDisplayUser
 * @param {number} itemsToDisplay default 2
 * @returns
 */
const renderOwnershipInfo = (doc, vue = null, globalInvestorAccount = null, globalInvestment = null, alwaysDisplayUser = false, itemsToDisplay = 2, showCopyButton = false) => {
    if (!doc) return null;
    const ownershipObjectNames = doc.ownership_ids?.map((obj) => obj.name) ?? doc.ownership_ids ?? [];
    const USER_STR = 'User';
    const ownershipType = doc.ownership_type || USER_STR;
    const shouldDisplaySmallUser = alwaysDisplayUser && ownershipType !== USER_STR;
    const ownershipTypeStr = ownershipType === 'Account' ? 'Entity' : ownershipType;

    const userId = doc.user?._id || doc.user;

    const getPath = (vue, ownershipType, objId) => {
        if (!vue) return null;
        if (ownershipType === 'Investment') {
            return routes.client.investmentDetails({
                access: vue,
                userId: userId,
                accountId: globalInvestorAccount,
                investmentId: objId,
            });
        } else if (ownershipType === 'Account') {
            return routes.client.manageAccount({
                access: vue,
                userId: userId,
                accountId: globalInvestorAccount,
            });
        }
        return null;
    };

    const userPath =
        !vue || vue === VUES.INVESTOR
            ? null
            : routes.client.investments({
                  access: vue,
                  userId: userId,
                  accountId: globalInvestorAccount,
              });

    const user = doc.user;
    const userName = user?.name ?? EM_DASH;

    const title = `${ownershipTypeStr}: ${ownershipObjectNames.join(', ')}`;

    return (
        <>
            {/* <div style={{ fontSize: '12px', color: 'var(--color-light-gray)' }}>{ownershipType} Level</div> */}

            {/* show small user if type is 'Investment' or 'Account' */}
            {ownershipType !== USER_STR && shouldDisplaySmallUser && (
                <div style={{ fontSize: '12px', color: 'var(--color-light-gray)', display: 'flex', alignItems: 'center', gap: '5px' }}>
                    User:{' '}
                    {!userPath ? (
                        userName
                    ) : (
                        <a href={userPath} title={userName}>
                            {userName}
                        </a>
                    )}
                    {showCopyButton && <CopyButton text={user._id} title={`Copy ID to Clipboard (${doc._id})`} size={'14px'} />}
                </div>
            )}

            {ownershipType === USER_STR && (
                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    {!userPath ? (
                        userName
                    ) : (
                        <a href={userPath} title={userName}>
                            {userName}
                        </a>
                    )}
                    {showCopyButton && <CopyButton text={user._id} title={`Copy ID to Clipboard (${user._id})`} size={'14px'} />}
                </div>
            )}

            {/* if ownership type is not user */}
            {ownershipType !== USER_STR && (
                <div title={title}>
                    <span
                        style={{
                            fontSize: '14px',
                            color: 'var(--color-light-gray)',
                            marginRight: '5px',
                        }}
                    >
                        {ownershipTypeStr}:
                    </span>

                    <span key={`owner${doc._id}_${ownershipType}`} style={{ fontSize: '14px', whiteSpace: 'wrap' }}>
                        {doc.ownership_ids?.slice(0, itemsToDisplay)?.map((obj, idx) => {
                            let path = getPath(vue, ownershipType, obj._id);

                            return (
                                <span key={`owner${doc._id}_${idx}`} style={{ fontSize: '14px', display: 'flex', alignItems: 'center' }}>
                                    {!path ? <span>{obj.name}</span> : <a href={path}>{obj.name}</a>}

                                    {showCopyButton && <CopyButton text={doc._id} title={`Copy ID to Clipboard (${doc._id})`} size={'14px'} />}

                                    {idx < itemsToDisplay - 1 && doc.ownership_ids.length >= itemsToDisplay && ', '}
                                </span>
                            );
                        })}

                        <span>{doc.ownership_ids?.length > itemsToDisplay && ` + ${ownershipObjectNames.length - itemsToDisplay} more`}</span>
                    </span>
                </div>
            )}
        </>
    );
};

export default renderOwnershipInfo;
