// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import _ from 'lodash';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';

import '../../../styles/widget.css';
import '../../../styles/widget-totalValue.css';

// eslint-disable-next-line

// import { format } from 'date-fns';
import moment from 'moment';
import dateToUTCMidnight from '../../../utilities/date/dateToUTCMidnight';
import { CURRENCIES, CURRENCY_SYMBOLS } from '../../../constants/currencyConstants';

const THOUSAND = 1_000;
const MILLION = 1_000_000;
const BILLION = 1_000_000_000;

const colors = ['#2A4266', '#009F9B', '#F2A900'];

class InvestmentValueChart extends Component {
    state = {
        keys: [],
        selectedKey: '',
        data: null,
        labels: [],
    };

    componentDidMount = () => {
        this.loadData();
    };

    componentDidUpdate = (prev) => {
        // only update if data is new or data is not set yet
        if (!_.isEqual(prev.data, this.props.data)) this.loadData();
    };

    sortByMonthYear = (x1, x2) => {
        let x1Time = x1.time ?? Date.UTC(x1.year, x1.month);
        let x2Time = x2.time ?? Date.UTC(x2.year, x2.month);
        return x1Time - x2Time;
    };

    loadData = () => {};

    getYRangeMinMax = () => {
        // get a min range for tick marks
        const curData = this.props.data;
        if (!curData) return [0, 0];
        const curValues = curData?.map((d) => d?.value);
        // get min, max, range
        let [min, max] = [Math.min(...curValues), Math.max(...curValues)];

        // figure out minRange based on scale of number
        let minTickSize = (max * 1.2) / 8;
        let tickSize = 1;

        for (let i = 1; i <= 9 && tickSize < minTickSize; i++) {
            tickSize = 10 ** i / 5;
        }

        let displayMin = min >= 0 ? Math.max(min - min * 0.1, 0) : min * 1.1;
        let displayMax = max * 1.1;

        displayMin = Math.floor(displayMin / tickSize) * tickSize;
        displayMax = Math.ceil(displayMax / tickSize) * tickSize;

        return [displayMin, displayMax];
    };

    getChartOptions = () => {};

    getChartData = (data) => {
        const p = this.props;

        const map_and_sort = (data, key) => {
            let ret = data?.length
                ? data.map((d) => {
                      // return d
                      let innerRet = {
                          x: d.time ?? dateToUTCMidnight(Date.UTC(d.year, d.month)),
                          y: d[key],
                      };
                      return innerRet;
                  })
                : [];
            ret = ret.sort((a, b) => dateToUTCMidnight(a.x) - dateToUTCMidnight(b.x));
            return ret;
        };

        let datasets = [];

        if (p.dataInfo) {
            p.dataInfo.forEach((dInfo, i) => {
                let key = dInfo.key;
                let color = dInfo.color ?? colors[i % colors.length];
                let resData = map_and_sort(data, key);
                datasets.push({
                    label: dInfo.label,
                    data: resData,
                    pointHoverRadius: 5,
                    pointRadius: 3,
                    pointBorderWidth: 0,
                    pointBackgroundColor: color,
                    backgroundColor: color + 'b9',
                    borderColor: color,
                });
            });
        }

        let returnData = { datasets };

        let [min, max] = this.getYRangeMinMax();
        min = 0;

        let config = {
            animation: false,
            responsive: true,
            maintainAspectRatio: false,
            fill: true,
            // backgroundColor: "#abccff7f",
            scales: {
                x: {
                    display: true,
                    type: 'time',
                    time: { displayFormats: { year: 'MMM yyy', month: 'MMM yyy' }, minUnit: 'month', maxUnit: 'year' },
                    stacked: true,
                    grid: {
                        display: false,
                    },
                },
                y: {
                    // beginAtZero: false,
                    // grace: "20%",
                    min,
                    max,
                    // suggestedMin: min,
                    // suggestedMax: max,
                    stacked: true,
                    ticks: {
                        callback: (value, index, values) => {
                            // make money string with b/m/k substituted for billion/million/thousand, respectively
                            let v = value;
                            if (Math.abs(value) >= BILLION) {
                                v = `${(value / BILLION).toFixed(1)}b`;
                            } else if (Math.abs(value) >= MILLION) {
                                v = `${(value / MILLION).toFixed(1)}m`;
                            } else if (Math.abs(value) >= THOUSAND) {
                                v = `${(value / THOUSAND).toFixed(1)}k`;
                            }
                            const currency = p.currency ?? CURRENCIES.USD;
                            const currencySymbol = CURRENCY_SYMBOLS[currency] ?? CURRENCY_SYMBOLS.USD;
                            return `${currencySymbol}${v}  `;
                        },
                    },
                    grid: {
                        drawBorder: false,
                    },
                },
            },
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    mode: 'index',
                    includeInvisible: true,
                    callbacks: {
                        title: function (ttis) {
                            let ret = '';
                            if (ttis.length) {
                                ret = moment(dateToUTCMidnight(ttis[0].raw.x)).format('MMMM yyyy');
                            }
                            return ret;
                        },
                        label: function (tti) {
                            let datum = tti.raw;
                            return tti?.dataset?.label + ': ' + new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(datum.y);
                        },
                        afterBody: function (input) {
                            let amount = input.reduce((acc, cur) => acc + cur?.raw?.y, 0);
                            return 'Total: ' + new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
                        },
                    },
                    backgroundColor: '#0d2957e8',
                },
            },
            elements: {
                point: {
                    radius: 0,
                },
                line: {
                    borderWidth: 3,
                },
            },
        };

        return { config, data: returnData };
    };

    render = () => {
        const { config, data } = this.getChartData(this.props.data);
        return (
            <div className="widget totalValueComponent">
                <div className="widget-header">{this.props.title ?? 'Investment Value'}</div>

                <div className="cashFlowGraph">
                    <Chart type={'line'} data={data} options={config} style={{ position: 'relative', backgroundColor: 'transparent', height: '350px' }} />
                </div>
            </div>
        );
    };
}

export default withRouter(InvestmentValueChart);
