// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import NotesWidget from './NotesWidget';

import '../../../styles/widget.css';
import '../../../styles/widget-notes.css';

class NoteTabsWidget extends Component {
    state = {
        activeTab: 0,
    };

    renderTabs = () => {
        const p = this.props;
        const s = this.state;
        return (
            <div
                className="tabs11"
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    height: 'min-content',
                    fontSize: '12px',
                }}
            >
                {p.tabs?.map((tab, i) => {
                    return (
                        <span
                            style={{
                                fontSize: '16px',
                                width: 'min-content',
                                textAlign: 'center',
                                border: '1px solid black',
                                cursor: 'pointer',
                                padding: '5px 10px',
                                whiteSpace: 'nowrap',
                                backgroundColor: s.activeTab === i ? 'white' : 'lightgray',
                            }}
                            key={'notes_tab' + i}
                            onClick={() => {
                                this.setState({ activeTab: i });
                            }}
                        >
                            {tab.name}
                        </span>
                    );
                })}
            </div>
        );
    };

    renderNotes = (tab) => {
        const p = this.props;
        return (
            <div style={{ width: 'calc(100% - 0px)', minHeight: '100px' }}>
                <NotesWidget
                    containerStyle={{
                        margin: '0px',
                    }}
                    loading={p.loading}
                    loaded={p.loaded}
                    type={tab.type}
                    typeId={tab.typeId}
                    breadcrumbs={tab.breadcrumbs}
                />
            </div>
        );
    };

    render = () => {
        const s = this.state;
        const p = this.props;

        return (
            <div
                id="note_tabs_widget"
                className="widget"
                style={{
                    position: 'relative',
                    margin: 0,
                    height: 'fit-content',
                    ...p.containerStyle,
                    // width: 'calc(100% - 40px)',
                }}
            >
                <h1
                    style={{
                        fontSize: '24px',
                        margin: '0px 0px 10px 0px',
                        padding: '0px 0px 10px 0px',
                    }}
                >
                    {p.title}
                </h1>
                {this.renderTabs()}
                {p.tabs?.length > 0 && this.renderNotes(p.tabs[s.activeTab])}
            </div>
        );
    };
}

export default withRouter(NoteTabsWidget);
