import React from 'react';
import { createHash } from 'crypto';

import ObjectSearchSelectTs, { ObjectSearchSelectProps } from '../Dropdowns/ObjectSearchSelectTs';
import api2 from '../../api2';
import { Account, AccountUser } from '../../types/Account';
import { generateStringHash } from '../../utilities/string/generateStringHash';

type AccountSearchFilterProps = Omit<ObjectSearchSelectProps<Account>, 'getLabel'> & {
    filter?: any;
    excludeDisplayFields?: (keyof Account)[];
};

const AccountSearchFilter = ({
    filter = {},
    onChange,
    selected,
    isMulti = false,
    formatOptionLabel,
    width,
    defaultOptions = false,
    defaultLabel = '',
    excludeDisplayFields = [],
    label = '',
    additionalOptions,
}: AccountSearchFilterProps) => {
    let filterHash = generateStringHash(JSON.stringify(filter));
    return (
        <ObjectSearchSelectTs<Account>
            key={filterHash}
            label={label}
            placeholder={`Select Entity${isMulti ? 's' : ''}`}
            selected={selected}
            onChange={onChange}
            getLabel={(account: any) => account?.name}
            matchProperty="_id"
            loadOptions={async (inputValue: string) => {
                try {
                    const accounts = (
                        await api2.client.AccountApi.listAccounts({
                            ...filter,
                            search: inputValue,
                            populate_user: true,
                            limit: 50,
                        })
                    ).data.accounts;

                    return accounts as any;
                } catch (e) {
                    console.error('Error loading accounts:', e);
                    return [];
                }
            }}
            width={width ?? '100%'}
            isMulti={isMulti}
            formatOptionLabel={(option) => {
                if (formatOptionLabel) return formatOptionLabel(option);
                const account: Account = option.value;

                if (!account) return defaultLabel || 'All Entities';
                const showUser = !excludeDisplayFields.includes('user');
                const title = `${account.name}${account.user && showUser ? ` (${(account.user as AccountUser).name})` : ''}`;
                return (
                    <div title={title}>
                        {showUser && account.user && <div style={{ fontSize: '12px', color: 'var(--color-light-gray)' }}>{(account.user as AccountUser).name}</div>}
                        <div style={{}}>{account.name}</div>
                    </div>
                );
            }}
            defaultLabel={defaultLabel || 'None'}
            defaultOptions={defaultOptions}
            additionalOptions={additionalOptions}
        />
    );
};

export default AccountSearchFilter;
