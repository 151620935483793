import api from '../../api';
import { ObjectSearchSelect } from './ObjectSearchSelect';

export const UserSearchSelect = (props) => {
    return (
        <ObjectSearchSelect
            placeholder="Filter by user"
            selected={props.searchUser}
            onChange={(userObj) => {
                props.onChange?.(userObj);
            }}
            getLabel={(obj) => {
                if (props.getLabel) {
                    return <span title={props.getLabel(obj)}>{props.getLabel(obj)}</span>;
                } else {
                    let label = obj?.name ? obj?.name : '';
                    if (obj?.email) {
                        label += ` (${obj?.email})`;
                    }
                    return label ? <div title={label}>{label}</div> : null;
                }
            }}
            defaultLabel="All Users"
            matchProperty="_id"
            width={300}
            loadOptions={async (inputValue) => {
                const inv = await api.get(`/users?access=investor&minimal=true&search=${inputValue}&count=50`);
                return inv.results;
            }}
            containerStyle={{
                zIndex: 1000,
                ...props.containerStyle,
            }}
        />
    );
};
