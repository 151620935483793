import api from '../../api';
import { setDismissableAlert } from '../alert/setDismissableAlert';

const resendEmailVerificationEmail = async (e, email, setAlert = null, callback = null) => {
    e.stopPropagation();
    const res = await api.post(`/users/reverify`, { email });
    if (res) {
        setDismissableAlert?.(
            setAlert,
            <>
                A verification email has been sent to <strong style={{ color: '#fff' }}>{email}</strong>.
            </>,
            false,
            7000
        );
    } else {
        setDismissableAlert?.(setAlert, 'Error sending verification email.', true);
    }
    callback?.();
};

export default resendEmailVerificationEmail;
