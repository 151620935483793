import React, { useState } from 'react';
import Select from 'react-select';
import { capitalizeFirstEveryWord } from '../format/capitalizeFirst';

interface Theme {
    'color-primary': string;
    'color-accent': string;
    'color-accent-light': string;
    'color-link': string;
    'color-link-hover': string;
    'color-link-ultralight': string;
}

const themes: Record<string, Theme> = {
    normal: {
        'color-primary': 'var(--color-altx-blue)',
        'color-accent': 'var(--color-light-blue)',
        'color-accent-light': '#d3e1f97f',
        'color-link': '#02b6b0',
        'color-link-hover': '#00beb8',
        'color-link-ultralight': '#02b6b0',
    },
    weird: {
        'color-primary': 'var(--color-blue)',
        'color-accent': 'var(--color-green)',
        'color-accent-light': 'var(--color-light-green)',
        'color-link': 'var(--color-dark-orange)',
        'color-link-hover': 'var(--color-orange)',
        'color-link-ultralight': 'var(--color-yellow)',
    },
};

let currentTheme = themes.normal;

const changeTheme = (theme: Theme): void => {
    currentTheme = theme;
    const root = document.documentElement;
    Object.keys(theme).forEach((property: string) => {
        root.style.setProperty(`--${property}`, theme[property as keyof Theme]);
    });
};

const themeOptions = Object.keys(themes).map((theme) => ({ value: themes[theme], label: capitalizeFirstEveryWord(theme) }));

const renderThemeDropdown = (): JSX.Element => {
    return (
        <Select
            options={themeOptions}
            value={themeOptions.find((option) => option.value === currentTheme)}
            onChange={(selectedOption) => {
                if (selectedOption) {
                    const newTheme = selectedOption.value as Theme;
                    changeTheme(newTheme);
                }
            }}
        />
    );
};

const CreateThemeComponent: React.FC = () => {
    const [newTheme, setNewTheme] = useState<Theme>(currentTheme);

    const handleColorChange = (color: string, property: keyof Theme) => {
        setNewTheme((prevTheme) => ({
            ...prevTheme,
            [property]: color,
        }));
    };

    return (
        <div>
            {Object.keys(newTheme).map((property) => (
                <div key={property}>
                    <label htmlFor={property}>{property}</label>
                    <input type="color" id={property} value={newTheme[property as keyof Theme]} onChange={(e) => handleColorChange(e.target.value, property as keyof Theme)} />
                </div>
            ))}
            <button onClick={() => changeTheme(newTheme)}>Apply Theme</button>
        </div>
    );
};

export { renderThemeDropdown, CreateThemeComponent };
