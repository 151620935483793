import React from 'react';
import ObjectSearchSelectTs, { ObjectSearchSelectProps } from '../Dropdowns/ObjectSearchSelectTs';
import api2 from '../../api2';
import { RALPandaDocStatus, RALRequest, RALRequestOwnership, RALRequestSignedRal, RALRequestUser } from '../../types/RalRequest';
import renderOwnershipInfo from '../../common/documents/renderOwnershipInfo';
import { generateStringHash } from '../../utilities/string/generateStringHash';
import { formatUTCDate } from '../../utilities/format/formatDate';

type RalRequestSearchFilterProps = Omit<ObjectSearchSelectProps<RALRequest>, 'getLabel'> & {
    filter?: any;
    excludeDisplayFields?: (keyof RALRequest)[];
};

const RalRequestSearchFilter = ({
    filter = {},
    onChange,
    selected,
    isMulti = false,
    formatOptionLabel,
    width,
    defaultOptions = false,
    defaultLabel = '',
    excludeDisplayFields = [],
    label = '',
}: RalRequestSearchFilterProps) => {
    let filterHash = generateStringHash(JSON.stringify(filter));
    return (
        <ObjectSearchSelectTs<RALRequest>
            key={filterHash}
            label={label}
            placeholder={`Select RAL Request${isMulti ? 's' : ''}`}
            selected={selected}
            onChange={onChange}
            getLabel={(account: any) => account?.name}
            matchProperty="_id"
            loadOptions={async (inputValue: string) => {
                try {
                    const ralRequests = (
                        await api2.client.RALRequestApi.listRALRequests({
                            ...filter,
                            search: inputValue,
                            populate_user: true,
                            populate_ownership: true,
                            populate_signed_ral: true,
                            limit: 50,
                        })
                    ).data.ral_requests;

                    return ralRequests as any;
                } catch (e) {
                    console.error('Error loading accounts:', e);
                    return [];
                }
            }}
            width={width ?? '100%'}
            isMulti={isMulti}
            formatOptionLabel={(option) => {
                if (formatOptionLabel) return formatOptionLabel(option);
                const ralRequest: RALRequest = option.value;
                if (!ralRequest) return 'All Accounts';
                return (
                    <div>
                        {renderOwnershipInfo(ralRequest, null, null, null, true)}

                        <div style={{ fontSize: '12px', color: 'var(--color-light-gray)' }}>
                            Status: {Object.keys(RALPandaDocStatus).find((key) => ({ ...RALPandaDocStatus })[key] === ralRequest.ral_panda_doc_status)}
                        </div>

                        {ralRequest.signed_at ? (
                            <div style={{ fontSize: '14px', color: 'var(--color-green)' }}>Signed At: {formatUTCDate(ralRequest.signed_at)}</div>
                        ) : ralRequest.sent_at ? (
                            <div style={{ fontSize: '14px', color: 'var(--color-blue)' }}>Sent At: {formatUTCDate(ralRequest.sent_at)}</div>
                        ) : (
                            <div style={{ fontSize: '14px' }}>Never sent or signed</div>
                        )}

                        {(ralRequest.signed_ral as RALRequestSignedRal)?.name && (
                            <div style={{ fontSize: '12px', color: 'var(--color-light-gray)' }} title={(ralRequest.signed_ral as RALRequestSignedRal).name}>
                                <span className="table-ellipses">Name: {(ralRequest.signed_ral as RALRequestSignedRal).name}</span>
                            </div>
                        )}

                        {ralRequest.deleted_at && (
                            <div style={{ fontSize: '16px', fontWeight: 'bold', color: 'var(--color-dark-red)' }} title={ralRequest.deleted_at}>
                                Deleted
                            </div>
                        )}
                    </div>
                );
            }}
            defaultLabel={defaultLabel || 'None'}
            defaultOptions={defaultOptions}
        />
    );
};

export default RalRequestSearchFilter;
