// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import formatCurrency from '../../../utilities/format/formatCurrency';

import '../../../styles/widget.css';
import '../../../styles/widget-InvestmentPerformanceSummary.css';
import api from '../../../api';
import { calcAdditionsWithdrawals, calcAmountInvested, calcTotalValue } from '../../../utilities/calculate/financeCalculations';
import { EM_DASH } from '../../../constants/constantStrings';
import { UserPermissions } from '../../../utilities/AdvisorVue/permissions';
import _ from 'lodash';
import MostRecentValuation from '../MostRecentValuation';
import { TimelessDate } from '../../../types/TimelessDate';
import { formatUTCDate } from '../../../utilities/format/formatDate';
import IconTooltip from '../../Icons/IconTooltip';

class InvestmentPerformanceSummary extends Component {
    state = {
        committedAmount: 0,
        currentValuation: null,
    };

    componentDidMount = () => {
        this.loadData();
    };

    componentDidUpdate = (prev) => {
        if (!_.isEqual(this.props.investment, prev.investment)) this.loadData();
    };

    loadData = async () => {
        const i = this.props.investment;
        if (!i) return;
    };
    renderTable = (data, total) => {
        const p = this.props;
        return (
            <table>
                <tbody>
                    {data &&
                        Object.keys(data).map((key, keyIdx) => {
                            return (
                                <tr key={'perfSummary' + keyIdx}>
                                    <td style={{ display: 'flex', alignItems: 'center' }}>
                                        {data[key].name}
                                        {data[key].icon && (
                                            <img
                                                className="investment-performance-summary-icon"
                                                src={data[key].icon}
                                                alt="table icon"
                                                onClick={data[key].onIconClick && UserPermissions().canUpdateAnyInvestments ? data[key].onIconClick : null}
                                            />
                                        )}
                                    </td>
                                    <td
                                        style={{
                                            color: data[key].value < 0 ? 'var(--color-dark-red)' : data[key].value > 0 ? 'var(--color-dark-green)' : 'var(--color-dark-grey)',
                                        }}
                                    >
                                        {data[key].displayValue ?? (Number.isFinite(data[key].value) ? `${formatCurrency(data[key].value, p.investment?.currency)}` : EM_DASH)}
                                    </td>
                                </tr>
                            );
                        })}
                    <tr className="investment-performance-summary-divider" />
                    <tr>
                        <td style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                            {total.name}
                            {total.icon && (
                                <img
                                    src={total.icon}
                                    style={{ cursor: 'pointer', marginLeft: '15px' }}
                                    alt="icon"
                                    onClick={!UserPermissions().canUpdateAnyInvestments ? null : total.onIconClick}
                                />
                            )}
                        </td>
                        <td style={{ color: total.value < 0 ? 'var(--color-dark-red)' : total.value > 0 ? 'var(--color-dark-green)' : 'var(--color-dark-grey)' }}>
                            {total.displayValue ?? (Number.isFinite(total.value) ? `${formatCurrency(total.value, p.investment?.currency)}` : EM_DASH)}
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    };

    renderInvestedTable = () => {
        const i = this.props.investment;
        if (i?.type === 'Hedge Fund') {
            return this.renderHedgeFundTable(i);
        }

        const data = {
            committed: {
                name: 'Capital Committed',
                value: i.performance?.itd?.committed ?? 0,
                icon: !UserPermissions().canUpdateAnyInvestments ? null : '/images/icons/edit.png',
                onIconClick: !UserPermissions().canUpdateAnyInvestments ? null : () => this.props.openAddEditCommitmentDrawer(i),
            },
            called: {
                name: 'Capital Called',
                value: i?.performance?.itd?.contributions,
            },
            fees: {
                name: 'Fees',
                value: i?.performance?.itd?.fees,
            },
        };
        let totalVal = i?.performance?.itd?.amount_invested_to_date - i?.performance?.itd?.fees;
        // let displayVal = Number.isFinite(totalVal) ? totalVal : EM_DASH
        const total = {
            name: 'Net Amount Invested',
            value: totalVal,
        };
        return this.renderTable(data, total);
    };

    renderHedgeFundTable = (investment) => {
        const itd = investment?.performance?.itd;
        const gain = itd?.gain ?? 0;

        const additionsWithdrawals = calcAdditionsWithdrawals(investment);
        const gainLoss = gain;
        const currentValue = investment?.valuation ?? 0;

        const data = {
            invested: {
                name: 'Initial Investment',
                value: investment.initial_contribution ?? 0,
            },
            added: {
                name: 'Additions/Withdrawals',
                value: additionsWithdrawals,
            },
            gainLoss: {
                name: 'Gain/Loss',
                value: gainLoss,
            },
        };
        const currValue = {
            name: 'Current Value (Unrealized)',
            value: currentValue,
            icon: !UserPermissions().canUpdateAnyInvestments ? null : '/images/icons/edit.png',
            onIconClick: !UserPermissions().canUpdateAnyInvestments ? null : () => this.props.openAddEditUnrealizedValueDrawer(investment),
        };
        return this.renderTable(data, currValue);
    };

    renderGainLossTable = () => {
        const i = this.props.investment;
        const data = {
            mostRecentValuation: {
                name: `Last Reported Value${this.state.currentValuation?.date ? ` (as of ${formatUTCDate(this.state.currentValuation?.date, 'MM/DD/YYYY')})` : ''}`,
                displayValue: (
                    <MostRecentValuation
                        investmentId={i._id}
                        currency={i?.currency}
                        shouldDisplayDate={false}
                        onValuationFetched={(currentValuation) => {
                            this.setState({ currentValuation });
                        }}
                    />
                ),
            },
            unrealizedValue: {
                name: (
                    <span style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        Current Value
                        <IconTooltip text={"This is the latest valuation we've received plus any capital calls and minus principal distributions."} />
                    </span>
                ),
                value: i?.valuation,
                // icon: !UserPermissions().canUpdateAnyInvestments ? null : '/images/icons/edit.png',
                // onIconClick: !UserPermissions().canUpdateAnyInvestments ? null : () => this.props.openAddEditUnrealizedValueDrawer(i),
            },

            realizedValue: {
                name: (
                    <span className="">
                        Realized Value <span style={{ fontSize: '14px' }}>(Distributions)</span>
                    </span>
                ),
                value: i?.performance?.itd?.distributions,
            },
            totalValue: {
                name: 'Total Value',
                value: calcTotalValue(i),
            },
        };
        const total = {
            name: 'Gain / Loss',
            value: i?.performance?.itd?.gain,
        };
        return this.renderTable(data, total);
    };

    render = () => {
        return (
            <div className="widget" style={{ ...this.props.containerStyle }}>
                <div className="widget-flex-header">
                    <div className="investment-performance-summary-header">{this.props.title ?? 'Investment Performance'}</div>
                    {this.props.headerComponent ?? <></>}
                </div>
                <div className={this.props?.investment?.type === 'Hedge Fund' ? 'full-width-performance-summary-table' : 'investment-performance-summary-tables'}>
                    {this.renderInvestedTable()}
                    {this.props?.investment?.type !== 'Hedge Fund' && this.renderGainLossTable()}
                </div>
            </div>
        );
    };
}

export default withRouter(InvestmentPerformanceSummary);
