// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';

import '../../styles/emptyOverlay.css';

class EmptyOverlay extends Component {
    render = () => {
        // outer div must be relative
        return (
            <div className="emptyOverlay_component">
                {/* overlay div - fits to space, no click through */}
                {this.props.isEmpty && (
                    <div className="emptyOverlay_overlay" style={{ ...this.props.containerStyle }}>
                        {/* internal div needed for text to enforce padding */}
                        <div className="emptyOverlay_text" style={{ ...this.props.textStyle }}>
                            {this.props.emptyText}
                        </div>
                    </div>
                )}

                {this.props.children}
            </div>
        );
    };

    // // old version that fills space, but doesn't display children
    // render = () => {
    //     return <div style={{
    //         ...EMPTY_STYLE,
    //         ...this.props.textStyle
    //     }}>
    //         {this.props.emptyText}
    //     </div>
    // }
}

export default EmptyOverlay;
