import '../../styles/alert.css';
import React, { Component } from 'react';

export default class DetailedAlert extends Component {
    render = () => {
        return (
            <div className={'detailedAlert'}>
                <div className={'detailedAlertMessage'}>
                    <div className={'detailedAlertTitle'}>{this.props.title}</div>
                    <div className={'detailedAlertDetails'}>{this.props.description}</div>
                </div>
                <div className="centerButtonContainer">
                    <button id={this.props.buttonId ?? ''} className={'alert-button'} onClick={this.props.onClick}>
                        {this.props.buttonText}
                    </button>
                </div>
            </div>
        );
    };
}
