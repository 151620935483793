// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

//TODO: delete this file?
import _ from 'lodash';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import '../../../styles/widget.css';
import '../../../styles/investmentCashFlow.css';

import { Chart } from 'react-chartjs-2';
import { CURRENCIES, CURRENCY_SYMBOLS } from '../../../constants/currencyConstants';

const isMobile = () => window.innerWidth <= 768;

const MONTH_NAMES = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

// keys matching label name, color, and the sign of the value
// "sign" is the sign of the number in terms of this graph
// distributions are counted as positive, while contributions and other are negative

const DISTRIBUTIONS_COLOR = '#009F9B';
const CONTRIBUTIONS_COLOR = '#36637d';
const TOTAL_COLOR = '#B64040';
const getLegendKeys = (currency = CURRENCIES.USD) => {
    return [
        {
            label: `Paid-in Capital (${currency})`,
            key: `contributions`,
            color: CONTRIBUTIONS_COLOR,
            sign: 1,
        },
        {
            label: `Total Distributions (${currency})`,
            key: `distributions`,
            color: DISTRIBUTIONS_COLOR,
            sign: 1,
        },
    ];
};

class InvestmentCashFlow2 extends Component {
    state = {
        data: null,
    };

    componentDidMount = () => {
        this.loadData();
    };

    componentDidUpdate = (prev) => {
        // only update if data is new or data is not set yet
        if (!_.isEqual(this.props.investments, prev.investments)) this.loadData();
    };

    // helper function to ensure a number has the given sign (sign is -1 or 1)
    getsNumWithSign = (num, sign) => (num === 0 ? 0 : Math.abs(num) * sign);
    sortCashflow = (x1, x2) => (x1.year === x2.year ? x1.month - x2.month : x1.year - x2.year);
    findCashflow = (cashflow, month, year) => cashflow.find((m) => month === m.month && year === m.year);

    getCashflow = (investments) => {
        if (!investments || investments.length === 0) return [];

        // get all monthly data from all investments
        let monthlyList = investments.reduce((acc, i) => (i.performance?.monthly ? acc.concat(i.performance.monthly.map((m) => ({ ...m, investmentId: i._id }))) : acc), []);

        // get year range
        const minYear = Math.min(...monthlyList.map((m) => m.year));
        const maxYear = Math.max(...monthlyList.map((m) => m.year));

        // compile monthly data by year
        let cashflowRes = {};
        let cumulativeDistributions = 0;
        for (let year = minYear; year <= maxYear; year++) {
            const curMonthly = monthlyList.filter((m) => m.year === year);

            const yearlyCashflow = {
                contributions: 0,
                distributions: 0,
                total: 0,
                year,
            };

            // accumulate contributions and distributions
            curMonthly.forEach((m) => {
                yearlyCashflow.contributions += m.snapshot?.contributions ? Math.abs(m.snapshot?.contributions) : 0;
                yearlyCashflow.distributions += m.snapshot?.distributions ? Math.abs(m.snapshot?.distributions) : 0;
            });

            // calc total value from all investments for the last given month in this year
            const investmentIds = curMonthly.map((m) => m.investmentId).filter((v, i, a) => a.indexOf(v) === i);
            investmentIds.forEach((id) => {
                // get last month data for this investment for this year
                const invMonthly = curMonthly.filter((m) => m.investmentId === id);
                yearlyCashflow.total += invMonthly?.[invMonthly.length - 1]?.valuation ?? 0;
            });

            // total is valuation + distributions
            cumulativeDistributions += yearlyCashflow.distributions;
            yearlyCashflow.total += cumulativeDistributions;

            cashflowRes[year] = yearlyCashflow;
        }
        return Object.values(cashflowRes).sort((a, b) => a.year - b.year);
    };

    loadData = () => {
        const p = this.props;

        if (!p.investments || !p.investments.length) return;

        const cashflow = this.getCashflow(p.investments);

        // create line chart data by summing distributions, contributions, and other
        const lineData = {
            type: 'line',
            label: `Total Value (${p.currency ?? CURRENCIES.USD})`,
            backgroundColor: TOTAL_COLOR,
            borderColor: TOTAL_COLOR,
            borderWidth: 3,
            // xAxisID: 'xAxis1',
            data: cashflow.map((item) => item.total),
        };

        // create bar chart data with separate distributions, contributions, and other bars
        const barData = getLegendKeys(p.currency).map((key) => {
            return {
                type: 'bar',
                label: key.label,
                backgroundColor: key.color + 'd9',
                borderColor: key.color,
                borderWidth: 2,
                barThickness: isMobile() ? 8 : 30,
                data: cashflow.map((item) => this.getsNumWithSign(item[key.key], 1)),
            };
        });

        const datasets = [lineData, ...barData];
        // const datasets = [lineData]

        // create tables from the month of the cashFlow date
        const labels = cashflow.map((c) => c.year);
        // const labels = years

        const data = { labels, datasets };

        this.setState({ data });
    };

    getChartOptions = (data) => ({
        animation: false,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                stacked: false,
                grid: {
                    display: false,
                },
            },
            y: {
                stacked: false,
                grid: {
                    display: true,
                },
            },
        },
        // scales: {
        //     xAxes: {
        //         stacked: false,
        //         grid: {
        //             display: false,
        //         },
        //     },
        //     yAxes: {
        //         stacked: false,
        //         grid: {
        //             display: true,
        //         },
        //     },
        // },
        interaction: {
            mode: 'index',
        },
        plugins: {
            legend: {
                display: false,
            },
        },
        elements: {
            point: {
                radius: 0,
            },
            line: {
                borderColor: '#969696',
                borderWidth: 1,
            },
        },
    });

    renderLegendKeys = () => {
        const p = this.props;
        return (
            <div className="cashFlowLegend">
                <div className="cashFlowLegendItem" key={`legendKey${-1}`} style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                        className="cashFlowLegendCircle2"
                        style={{
                            borderBottom: `3px solid ${TOTAL_COLOR}`,
                            width: '20px',
                            marginRight: '5px',
                        }}
                    />
                    <div className="cashFlowLegendText">Total Value ({p.currency ?? CURRENCIES.USD})</div>
                </div>
                {getLegendKeys(p.currency).map((elem, idx) => (
                    <div className="cashFlowLegendItem" key={`legendKey${idx}`}>
                        <div className="cashFlowLegendCircle" style={{ backgroundColor: elem.color }} />
                        <div className="cashFlowLegendText">{elem.label}</div>
                    </div>
                ))}
            </div>
        );
    };

    render = () => {
        const data = this.state.data;
        if (!data) return null;
        return (
            <div className="widget cashFlowComponent">
                <div className="widget-header">
                    Cash Flow
                    {this.renderLegendKeys()}
                </div>

                {data && (
                    <div className="cashFlowGraph">
                        <Chart data={data} options={this.getChartOptions(data)} style={{ position: 'relative', backgroundColor: 'transparent' }} height={400} />
                    </div>
                )}
                {/* <div style={{clear: 'both', height: '150px'}} /> */}
            </div>
        );
    };
}

export default withRouter(InvestmentCashFlow2);
