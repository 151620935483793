import getBaseResourceCall from './helpers/getBaseResourceCall';
import { routeConstants } from './helpers/constants';
import api2 from '../api2';
import { saveAs } from 'file-saver';
import promiseAllRequests from '../utilities/apiHelpers/promiseAllRequests';
import saveZip from '../utilities/file/saveZip';

const baseCalls = getBaseResourceCall(routeConstants.documents);

// if successful, returns an object like { blob, filename, and document }
// if save is true, it will save the file to the user's computer as document.name
const download = async (documentId, save = true, filenameOverride = null) => {
    try {
        // get the document object to get the download url
        const documentRes = await baseCalls.get(documentId);
        const document = documentRes.data.document;
        const downloadUrl = document.file_download_urls.doc;
        if (downloadUrl) {
            const filename = filenameOverride || document.name;
            let blobRes = await api2.getBlob(downloadUrl);
            if (blobRes.success) {
                const blob = blobRes.data;
                if (save) {
                    saveAs(blob, filename);
                }
                return {
                    success: true,
                    blob,
                    filename,
                    document,
                };
            } else {
                const errorMsg = 'No blob found for document';
                console.error(errorMsg, document);
                throw new Error(errorMsg);
            }
        } else {
            const errorMsg = 'No download url found for document';
            console.error(errorMsg, document);
            throw new Error(errorMsg);
        }
    } catch (err) {
        console.error('Error downloading document', err);
        throw err;
    }
};

const downloadZip = async (documentIds, zipFilename = 'documents.zip', getDocFilename = (doc) => doc.name) => {
    try {
        // get documents
        const downloadPromises = documentIds.map((id) => download(id, false));
        const downloadResults = await promiseAllRequests(downloadPromises);
        if (!downloadResults.every((r) => r.success)) {
            console.error('Error in downloading one or more documents.');
            throw new Error('Error downloading documents');
        }
        // create the zip
        saveZip(
            downloadResults.map((res) => {
                const filename = getDocFilename(res.document);
                return {
                    blob: res.blob,
                    filename,
                };
            }),
            zipFilename
        );
    } catch (err) {
        console.error('Error downloading zip', err);
        throw err;
    }
};

// reprocess metadata for a document
const reprocessMetadata = async (documentId) => {
    try {
        const res = await api2.post(`/documents/${documentId}/reprocess_metadata`);
        return res;
    } catch (err) {
        console.error('Error reprocessing metadata', err);
        throw err;
    }
};

export default {
    ...baseCalls,
    download,
    downloadZip,
    reprocessMetadata,
};
