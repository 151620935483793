import React from 'react';

import Select from 'react-select';
// import '../../styles/objectFilterSelect.css'

import Dropdown from '../Dropdowns/Dropdown';
import { DrawerDateInput } from './DrawerDateInput';
import renderInputError from '../renderInputError';
import formatCurrency from '../../utilities/format/formatCurrency';
import Checkbox from '../Inputs/Checkbox';
import DropdownSection from './DropdownSection';
import ReactDatePicker from 'react-datepicker';
import dateToUTCMidnight from '../../utilities/date/dateToUTCMidnight';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';
import '../../styles/drawerInputObject.css';
import '../../styles/react-datepicker.css';
import _ from 'lodash';
import UTCDatePicker from '../Inputs/UTCDatePicker/UTCDatePicker';
import { PLEASE_SELECT___ } from '../../constants/constantStrings';
import { CURRENCIES } from '../../constants/currencyConstants';
import { TimelessDate } from '../../types/TimelessDate';

const PLACEHOLDER_COLOR = '#888';

export const DrawerInputObject = (props) => {
    const { readOnly } = props;

    const [isCurrencyFocused, setCurrencyFocused] = React.useState(false);
    const [isPercentFocused, setPercentFocused] = React.useState(false);

    const [showPassword, setShowPassword] = React.useState(false);
    const toggleShowPassword = () => setShowPassword(!showPassword);

    const currencyInput = React.useRef(null);
    const percentInput = React.useRef(null);

    const onChange = (val) => {
        // data check on certain data types
        if (props.object.type === 'number' && isNaN(val) && val !== '-' && val !== '+') {
            // reject non numbers, unless they're "-" or "+" only
            return;
        } else if (props.object.type === 'percent' && isNaN(val) && val !== '-' && val !== '+') {
            // reject non numbers, unless they're "-" or "+" only
            return;
        } else if (props.object.type === 'currency' && isNaN(val) && val !== '-' && val !== '+' && val !== '.') {
            // reject non numbers, unless they're "-" or "+" only
            return;
        }
        (async () => {
            const ___ = await props?.onChangeCallback?.(val);
            const _ = await props.object.callback?.(val);
        })();
    };

    const formatValue = (val, type, disabled) => {
        // format currency if disabled or if not focused, otherwise, show editable value
        if (type === 'currency' && (!isCurrencyFocused || disabled)) {
            val = formatCurrency(Number(val), props.object?.currency ?? CURRENCIES.USD);
        } else if (type === 'percent' && (!isPercentFocused || disabled)) {
            val = val + '%';
        }
        return val;
    };

    let optionComponent = undefined;
    if (['option', 'option-section'].includes(props.object.type)) {
        let ddProps = {
            selection: [undefined, null].includes(props.value)
                ? props.object.optionField
                    ? { [props.object.optionField]: props.object.placeholder }
                    : props.object.placeholder
                : props.value,
            fontColor: [undefined, null].includes(props.value) ? PLACEHOLDER_COLOR : null,
            options: props.object.options,
            optionField: props.object.optionField,
            select: (value) => {
                if (value === PLEASE_SELECT___) {
                    if (props.object.optionClearable) onChange(null);
                    return;
                }
                onChange(value);
            },
            style: { height: '38px' },
            readOnly: readOnly,
        };
        if (props.object.type === 'option') {
            optionComponent = (
                <div style={{ textAlign: 'left' }}>
                    <Dropdown {...ddProps} />
                </div>
            );
        } else {
            return <DropdownSection {...ddProps} />;
        }
    }

    let selectComponent = undefined;
    if (['select'].includes(props.object.type)) {
        let options =
            props.object.options?.map((opt) => {
                return {
                    value: opt,
                    label: props.object.optionField ? opt[props.object.optionField] : opt,
                };
            }) ?? [];

        if (props.object.optionClearable) {
            options = [{ value: null, label: PLEASE_SELECT___ }, ...options];
        }

        const value = options.find((opt) => _.isEqual(opt.value, props.value));

        let ddProps = {
            value,
            placeholder: props.object.placeholder,
            options,
            onChange: (newSelection) => {
                if (newSelection.value === null) {
                    if (props.object.optionClearable) onChange(null);
                    return;
                }
                onChange(newSelection.value);
            },
            style: { height: '38px' },

            theme: (theme) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    neutral50: PLACEHOLDER_COLOR, // Placeholder color
                },
            }),
            isDisabled: readOnly,
        };
        selectComponent = (
            <div style={{ textAlign: 'left' }}>
                <Select {...ddProps} />
            </div>
        );
    }

    return (
        <div key={'drawerInputObject' + props.object.type + props.object.fieldName} style={{ '--drawer-input-placeholder-color': PLACEHOLDER_COLOR, position: 'relative' }}>
            {/* <div style={{width: '100%', height: '100%', zIndex: 10000, position: 'absolute'}} onClick={e => e.stopPropagation()}></div> */}
            {!props.object.hideLabel && props.object.type !== 'checkbox' && (
                <label htmlFor="name" style={{ display: 'flex', position: 'relative' }}>
                    {props.object.label}
                    {props.notateRequired && props.object.required && <div style={{ position: 'absolute', right: 0, fontSize: '24px', marginTop: '5px' }}>*</div>}
                </label>
            )}

            {/* select component */}
            {selectComponent}

            {/* option component */}
            {optionComponent}

            {/* number component */}
            {props.object.type === 'number' && (
                <input
                    id="name"
                    placeholder={props.object.placeholder}
                    type="text"
                    value={![null, undefined].includes(props.value) ?? ''}
                    onChange={props.object.disabled ? () => {} : (e) => onChange(e.target.value)}
                    disabled={props.object.disabled}
                    style={{ cursor: props.object.disabled ? 'not-allowed' : undefined }}
                    readOnly={props.readOnly}
                />
            )}

            {/* currency component */}
            {props.object.type === 'currency' && (
                <input
                    ref={currencyInput}
                    id="name"
                    placeholder={props.object.placeholder}
                    type="text"
                    value={![null, undefined].includes(props.value) && !isNaN(props.value) ? formatValue(props.value, props.object.type, props.object.disabled) : ''}
                    onChange={props.object.disabled ? () => {} : (e) => onChange(e.target.value.replace(',', '').replace('$', ''))}
                    onFocus={() => (props.object.disabled ? currencyInput.current?.blur() : setCurrencyFocused(true))}
                    onBlur={() => setCurrencyFocused(false)}
                    // disabled={props.object.disabled}
                    style={{ cursor: props.object.disabled ? 'not-allowed' : undefined }}
                    readOnly={props.readOnly}
                />
            )}

            {/* text component */}
            {props.object.type === 'text' && (
                <input
                    id="textInput"
                    placeholder={props.object.placeholder}
                    type="text"
                    value={props.value ?? ''}
                    onChange={props.object.disabled ? undefined : (e) => onChange(e.target.value)}
                    disabled={props.object.disabled}
                    style={{ cursor: props.object.disabled ? 'not-allowed' : undefined }}
                    readOnly={props.readOnly}
                />
            )}

            {/* password component */}
            {props.object.type === 'password' && (
                <div style={{ position: 'relative' }}>
                    <input
                        id="passwordInput"
                        name="passwordInput"
                        placeholder={props.object.placeholder}
                        // type="text"
                        type={showPassword ? 'text' : 'password'}
                        autoComplete="new-password"
                        value={props.value ?? ''}
                        onChange={props.object.disabled ? undefined : (e) => onChange(e.target.value)}
                        disabled={props.object.disabled}
                        style={{ cursor: props.object.disabled ? 'not-allowed' : undefined }}
                        readOnly={props.readOnly}
                    />
                    {props.object.allowShow && (
                        <img
                            style={{ position: 'absolute', right: '10px', cursor: 'pointer', top: '7px' }}
                            src={`/images/icons/eye${showPassword ? '_filled' : ''}.png`}
                            alt={'show/hide password'}
                            title={'show/hide password'}
                            onClick={readOnly ? null : toggleShowPassword}
                        />
                    )}
                </div>
            )}

            {/* text component */}
            {props.object.type === 'percent' && (
                <input
                    id="name"
                    placeholder={props.object.placeholder}
                    type="text"
                    value={props.value ? formatValue(props.value, props.object.type, props.object.disabled) : ''}
                    onChange={props.object.disabled ? undefined : (e) => onChange(e.target.value.replace('%', ''))}
                    disabled={props.object.disabled}
                    onFocus={() => (props.object.disabled ? percentInput.current?.blur() : setPercentFocused(true))}
                    onBlur={() => setPercentFocused(false)}
                    style={{ cursor: props.object.disabled ? 'not-allowed' : undefined }}
                    readOnly={props.readOnly}
                />
            )}

            {/* checkbox component*/}
            {props.object.type === 'checkbox' && (
                <div style={{ display: 'flex', gap: '8px', alignItems: 'center', marginTop: '10px', marginBottom: '10px' }}>
                    <Checkbox id="name" setChecked={(e) => onChange(e.target.value)} checked={props.value} style={{ width: '12px', height: '12px' }} disabled={props.readOnly} />
                    {!props.object.hideLabel && <p style={{ fontSize: '14px' }}>{props.object.label}</p>}
                </div>
            )}

            {/** custom component */}
            {props.object.type === 'custom' && props.object.component}

            {props.object.type === 'dateDropdowns' && <DrawerDateInput date={props.value} setDate={onChange} readOnly={props.readOnly} />}

            {props.object.type === 'date' && (
                <div className="drawerInputObject-datePicker">
                    <UTCDatePicker
                        placeholderText="Enter MM/DD/YYYY"
                        dateFormat="MM/dd/yyyy"
                        selected={(() => {
                            return props.value ? TimelessDate.parse(props.value) : null;
                        })()}
                        onChange={(newDate) => onChange(newDate ? newDate.toDate() : null)}
                        disabled={props.readOnly}
                    />
                    {/* <ReactDatePicker
                    placeholderText='Enter MM/DD/YYYY'
                    selected={(() => {
                        let dateValue = props.value ? dateToUTCMidnight(props.value, true) : null
                        dateValue = dateValue ? new Date(dateValue.year(), dateValue.month(), dateValue.date()) : null
                        return dateValue ? dateValue : null
                    })()}
                    onChange={newDate => {
                        let resp = newDate ? dateToUTCMidnight(newDate) : null;
                        return onChange(resp);
                    }}
                    dateFormat='MM/dd/yyyy'
                /> */}
                </div>
            )}

            {props.object.type === 'dateCalendar' && (
                <ReactDatePicker selected={dateToUTCMidnight(props.value)} onChange={props.readOnly ? null : onChange} dateFormat="MM-dd-yyyy" />
            )}

            <div>{props.errors && renderInputError(props.errors, props.object.fieldName)}</div>
        </div>
    );
};
