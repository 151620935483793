import React, { Component } from 'react';
import Dropdown from '../Dropdowns/Dropdown';

export default class DropdownSection extends Component {
    render = () => {
        return (
            <div className="altx-drawer-infobox">
                <p className="altx-drawer-section">{this.props.title}</p>
                <Dropdown {...this.props} />
            </div>
        );
    };
}
