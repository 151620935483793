import React, { Component } from 'react';
import clickOutsideHandler from '../../utilities/clickOutsideHandler';

import '../../styles/hamburgerMenu.css';

export default class HamburgerMenu extends Component {
    containerRef = null;

    state = {
        open: false,
        outclickSet: false,
        activeOption: null,
    };

    componentDidMount = () => {
        this.setOutclick();
    };

    componentDidUpdate = (prevProps) => {
        this.setOutclick();
    };

    componentWillUnmount = () => {
        this.setState({ outclickSet: false });
        clickOutsideHandler.remove(this.containerRef, () => this.openMenu(false));
    };

    setOutclick = () => {
        if (this.containerRef && !this.state.outclickSet) {
            clickOutsideHandler.add(this.containerRef, () => this.openMenu(false));
            this.setState({ outclickSet: true });
        }
    };

    handleClickOutside = (event) => {
        if (this.containerRef && !this.containerRef.contains(event.target)) {
            this.openMenu(false);
        }
    };

    setActiveOption = (index) => {
        this.setState({ activeOption: index });
    };

    clearActiveOption = () => {
        this.setState({ activeOption: null });
    };

    openMenu = (open) => this.setState({ open });

    handleMouseOutFromOption = (event, index) => {
        if (this.containerRef) {
            const optionDiv = this.containerRef.querySelector(`[data-option-index="${index}"]`);
            const subOptionsDiv = this.containerRef.querySelector(`[data-suboptions-index="${index}"]`);

            if (optionDiv && !optionDiv.contains(event.target) && subOptionsDiv && !subOptionsDiv.contains(event.target)) {
                this.clearActiveOption();
            }
        }
    };
    render = () => {
        const p = this.props;
        const s = this.state;
        const numDots = p.numDots ?? 3;

        return (
            <div ref={(node) => (this.containerRef = node)} className={'ham_container'} style={{ ...p.containerStyle, '--num-dots': numDots }}>
                <div className="ham_menu" onClick={(e) => this.openMenu(!s.open)}>
                    {[...Array(numDots)].map((_, i) => {
                        return <div className={'ham_dot'} style={{ ...p.dotStyle }} key={i} />;
                    })}
                </div>
                {s.open && (
                    <div className="ham_options">
                        {p.options.map((opt, i) => {
                            return (
                                <div
                                    key={'opt' + i}
                                    data-option-index={i} // New attribute to help identify the option div
                                    onMouseOver={() => this.setActiveOption(i)}
                                    onMouseOut={(e) => this.handleMouseOutFromOption(e, i)}
                                    style={{ position: 'relative' }}
                                >
                                    <div
                                        className={'ham_option' + (opt.disabled ? ' ham_option_disabled' : '')}
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', ...p.optionStyle }}
                                        onClick={
                                            !opt.disabled && opt.action
                                                ? (e) => {
                                                      e.stopPropagation();
                                                      opt.action && opt.action();
                                                      this.openMenu(false);
                                                  }
                                                : null
                                        }
                                    >
                                        {opt.label} {opt.subOptions && opt.subOptions.length > 0 && <div className="ham_option_arrow1">&#9658;</div>}
                                    </div>
                                    {opt.subOptions && opt.subOptions.length > 0 && this.state.activeOption === i && (
                                        <div className="ham_suboptions" data-suboptions-index={i}>
                                            {' '}
                                            {/* New attribute to help identify the sub-options div */}
                                            {opt.subOptions.map((subOpt, j) => (
                                                <div
                                                    key={'subopt' + j}
                                                    className={'ham_suboption' + (subOpt.disabled ? ' ham_option_disabled' : '')}
                                                    onClick={
                                                        !subOpt.disabled && subOpt.action
                                                            ? (e) => {
                                                                  e.stopPropagation();
                                                                  subOpt.action && subOpt.action();
                                                                  this.openMenu(false);
                                                              }
                                                            : null
                                                    }
                                                >
                                                    {subOpt.label}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        );
    };
}
