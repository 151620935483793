import React from 'react';
import ObjectSearchSelectTs, { ObjectSearchSelectProps } from '../Dropdowns/ObjectSearchSelectTs';
import api2 from '../../api2';
import { generateStringHash } from '../../utilities/string/generateStringHash';
import { ListAdminTeams200ResponseAdminTeamsInner } from '../../openApiClient';

type AdminTeam = ListAdminTeams200ResponseAdminTeamsInner;

type AdminTeamSearchFilterProps = Omit<ObjectSearchSelectProps<AdminTeam>, 'getLabel'> & {
    filter?: any;
    excludeDisplayFields?: (keyof AdminTeam)[];
};

const AdminTeamSearchFilter = ({
    filter = {},
    onChange,
    selected,
    isMulti = true,
    formatOptionLabel,
    width,
    defaultOptions = false,
    defaultLabel = '',
    excludeDisplayFields = [],
    label = '',
}: AdminTeamSearchFilterProps) => {
    let filterHash = generateStringHash(JSON.stringify(filter));
    return (
        <ObjectSearchSelectTs<AdminTeam>
            key={filterHash}
            label={label}
            placeholder={`Select Admin Team${isMulti ? 's' : ''}`}
            selected={selected}
            onChange={onChange}
            getLabel={(adminTeam: any) => adminTeam?.name}
            matchProperty="_id"
            loadOptions={async (inputValue: string) => {
                try {
                    const query = {
                        ...filter,
                        search: inputValue,
                        count: 50,
                        sortField: 'name',
                    };

                    const adminTeams = (
                        await api2.client.AdminTeamApi.listAdminTeams({
                            ...query,
                            limit: 50,
                        })
                    ).data.admin_teams;
                    if (!adminTeams) return [];

                    return adminTeams;
                } catch (e) {
                    console.error('Error loading admin teams:', e);
                    return [];
                }
            }}
            width={width ?? '100%'}
            isMulti={isMulti}
            formatOptionLabel={(option) => {
                if (formatOptionLabel) return formatOptionLabel(option);
                const adminTeam: AdminTeam | null = option.value;

                if (!adminTeam) return defaultLabel || 'All Admin Teams';

                return (
                    <div>
                        {/* <div style={{ fontSize: '12px', color: 'var(--color-light-gray)' }}>{adminTeam?.name}</div> */}
                        <div>{adminTeam?.name}</div>
                    </div>
                );
            }}
            defaultLabel={defaultLabel || 'None'}
            defaultOptions={defaultOptions}
        />
    );
};

export function getGroupIdsFromAdminTeams(adminTeams: AdminTeam[]): string[] {
    const ids_list = adminTeams.reduce((acc: string[], adminTeam) => {
        const these_group_ids = adminTeam?.advisory_groups?.map((group) => group?._id ?? (group as string)) ?? [];
        return [...acc, ...these_group_ids];
    }, [] as string[]);
    // Convert to set then back to array to remove duplicates
    return Array.from(new Set(ids_list));
}

export default AdminTeamSearchFilter;
