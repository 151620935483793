export const routeConstants = {
    accounts: 'accounts',
    assetManagers: 'assetmanagers',
    commitments: 'commitments',
    connections: 'connections',
    connectionSyncs: 'connectionsyncs',
    investments: 'investments',
    investment_masters: 'investmentmasters',
    transactions: 'transactions',
    users: 'users',
    valuations: 'valuations',
    documents: 'documents',

    // api 1
    users: 'users',
};
