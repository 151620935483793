import React from 'react';
import QRCode from 'qrcode.react';
import api from '../../api';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';
import '../../styles/tfaDrawer.css';

class TFADrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tfa: {
                loading: false,
                setupCompleted: false,
                otpURL: '',
                recoveryCodes: [],
                secret: '',
                totp: '',
            },
            verificationError: undefined,
        };
    }

    componentDidMount = async () => {
        await this.props.loading();
        if (!this.props?.user?.tfa?.totp?.enabled) {
            await this.setupTfa();
        }
        await this.props.loaded();
    };

    disableTfa = async () => {
        this.setState({ loading: true });
        const response = await api.post('/users/' + this.props.user?._id + '/totp_status', {
            totpEnabled: false,
        });
        this.setState({ loading: false });
        if (response?.success) {
            setDismissableAlert(this.props.setAlert, 'TFA Disabled Successfully', false, 7000);
            this.close();
        } else {
            setDismissableAlert(this.props.setAlert, 'TFA Disable Failed', true, 7000);
            this.close();
        }
    };

    setupTfa = async () => {
        this.setState({ loading: true });
        const response = await api.post('/users/' + this.props.user?._id + '/totp_setup');
        const { recovery_codes, totp_secret } = response;
        const label = 'AltExchange - ' + this.props.user?.email;
        const issuer = 'AltExchange';
        const otpURL = 'otpauth://totp/' + label + '?secret=' + totp_secret + '&issuer=' + issuer + '&algorithm=SHA1&digits=6&period=30';
        this.setState({
            tfa: {
                recoveryCodes: recovery_codes,
                secret: totp_secret,
                otpURL,
                setupCompleted: true,
            },
            loading: false,
        });
    };

    handleTotpChange = (e) => {
        this.setState({ tfa: { ...this.state.tfa, totp: e.target.value } });
    };

    verifyTotp = async () => {
        this.setState({ loading: true });
        const response = await api.post(
            '/users/' + this.props.user?._id + '/totp_status',
            {
                totpEnabled: true,
                totpCode: this.state.tfa.totp,
            },
            {
                400: (err) => this.setState({ verificationError: err.message, loading: false }),
            }
        );
        this.setState({ loading: false });
        if (response?.success) {
            setDismissableAlert(this.props.setAlert, 'TFA Configured Successfully', false, 7000);
            return this.close();
        } else {
            // console.log("Failed to update TOTP setup status to backend server.");
        }
    };

    cancelTfaSetup = async () => {
        // if setup completed, make api request to cancel setup
        if (this.state.tfa.setupCompleted) {
            const response = await api.post('/users/' + this.props.user?._id + '/totp_status', {
                totpEnabled: false,
            });
        }
        this.close();
    };

    close = async () => {
        await this.setState({ loading: false });
        await this.props.loading();
        await this.props.reloadData();
        await this.props.loaded();
        await this.props.close();
    };

    render() {
        let drawerContent;
        if (this.props.user.tfa.totp.enabled && !this.state.loading) {
            drawerContent = (
                <div className="tfa-setup-content">
                    <h1 className="tfa-header">TFA is currently enabled for your account</h1>
                    <button className="tfa-button tfa-disable-button" onClick={this.disableTfa}>
                        Disable TFA
                    </button>
                    <div className="tfa-cancel" onClick={this.cancelTfaSetup}>
                        <span className="tfa-cancel-text">Cancel</span>
                    </div>
                </div>
            );
        } else if (!this.state.tfa.setupCompleted || this.state.loading) {
            drawerContent = (
                <div className="tfa-setup">
                    <p className="tfa-loading">Loading...</p>
                </div>
            );
        } else {
            const { otpURL, recoveryCodes } = this.state.tfa;
            drawerContent = (
                <div className="tfa-setup-details">
                    <h1 className="tfa-header">Two Factor Code Setup</h1>
                    <div>
                        <label>Scan this QR Code with your Authenticator app:</label>
                        <QRCode value={otpURL} className="tfa-qrcode" />
                        <div className="otp-container">
                            <p className="otp-url">{otpURL}</p>
                        </div>
                    </div>
                    <div>
                        <label className="label">Recovery Codes:</label>
                        <div className="recovery-codes-container">
                            {recoveryCodes.slice(0, recoveryCodes.length / 2).map((code, index) => (
                                <p key={index}>{code?.code}</p>
                            ))}
                        </div>
                        <div className="recovery-codes-container">
                            {recoveryCodes.slice(recoveryCodes.length / 2).map((code, index) => (
                                <p key={index}>{code?.code}</p>
                            ))}
                        </div>
                    </div>
                    <div>
                        <label className="tfa-totp-label" htmlFor="totp">
                            Enter the TOTP from the app:
                        </label>
                        {this.state.verificationError && <p className="error-message">{this.state.verificationError}</p>}
                        <input id="totp" onChange={this.handleTotpChange} className="tfa-input" />
                    </div>
                    <button onClick={this.verifyTotp} className="tfa-button tfa-verify-button">
                        Verify and Enable
                    </button>
                    <div className="tfa-cancel" onClick={this.cancelTfaSetup}>
                        <span className="a">Cancel</span>
                    </div>
                </div>
            );
        }

        return <div className="tfa-drawer">{drawerContent}</div>;
    }
}

export default TFADrawer;
