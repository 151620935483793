import React from 'react';
import Dropdown from '../Dropdowns/Dropdown';

import '../../styles/paginationFooter.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import DelayedInput from '../Inputs/DelayedInput';

const isMobile = window.innerWidth <= 768;

const PaginationFooter = (props) => {
    // total number of pages in the current data set
    const numPages = () => (props.total === 0 ? 0 : Math.ceil(props.total / props.rowsPerPage));

    const hasMorePages = () => props.page < numPages() - 1;

    const pageNumberDisplay = numPages() ? props.page + 1 : 0;

    const renderPageRange = () => {
        const start = props.page * props.rowsPerPage + 1;
        let end = pageNumberDisplay * props.rowsPerPage;
        end = props.forceSeeAll ? props.total : Math.min(end, props.total);
        return (
            <div
                className="pagFooter_footRange"
                style={{
                    whiteSpace: 'nowrap',
                }}
            >{`${start}-${end} of ${props.total}`}</div>
        );
    };

    const setRowsPerPage = (rows) => {
        rows = rows === 'All' ? -1 : rows;
        if (rows === props.rows) return;
        if (props.page > 0) {
            const currentRange = props.page * props.rows;
            let page = 1;
            if (currentRange < rows) {
                page = 0;
            } else if (currentRange > rows) {
                page = Math.floor((props.page * props.rows) / rows);
            }
            props.setPage(page);
        }
        props.setRowsPerPage(rows);
    };

    const renderRefreshButton = () => {
        if (!props.refreshData) return null;
        return (
            <FontAwesomeIcon
                icon={faRefresh}
                // size='xl'
                title="Refresh Table Data"
                className={`ral_refreshIcon a`}
                style={{ marginLeft: '20px', width: '22px', height: '22px' }}
                onClick={async (e) => {
                    e.stopPropagation();
                    props.refreshData?.();
                }}
            />
        );
    };

    return (
        // Need margin bottom for dropdown
        <div
            className="pagFooter_footContainer"
            style={{
                ...(isMobile ? { marginBottom: '160px' } : {}),
                position: 'relative',
                // backgroundColor: 'blue',
                display: 'flex',
                flexDirection: 'row',
                // justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {!props.forceSeeAll && (
                <>
                    <span style={{ whiteSpace: 'nowrap' }}>Rows per page:&emsp;</span>
                    {/* Rows per page dropdown */}
                    <Dropdown
                        disableDefaultOption={true}
                        idPrefix="rowsPerPage"
                        options={props.numRowsOptions.map((num) => (num < 0 ? 'All' : num))}
                        select={setRowsPerPage}
                        style={{ width: '65px', height: props.dropdownHeight ?? '30px' }}
                        defaultSelection={props.defaultRowsPerPage ?? props.numRowsOptions[0]}
                    />
                </>
            )}
            {renderPageRange()}

            {!props.forceSeeAll && (
                <>
                    {/* page forward and back arrows */}
                    <div className={'pagFooter_footPageBack' + (props.page !== 0 ? ' pagFooter_link' : '')}>
                        <img
                            src={`/images/icons/leftCaret${props.page !== 0 ? '' : 'Dim'}.png`}
                            alt="previous page"
                            onClick={() => {
                                if (props.page === 0) return;
                                props.setPage(props.page - 1);
                            }}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            // backgroundColor: 'red',
                            // height: '100%',
                        }}
                    >
                        {/* <div className="pagFooter_footPageNumber">{pageNumberDisplay}</div> */}

                        <DelayedInput
                            className="pagFooter_footPageNumber"
                            containerStyle={{
                                // width: 30 + Math.floor((numPages()-1) / 10) * 10, // get wider as the number of pages increases
                                width: `${20 + Math.ceil(Math.log10(numPages() + 1)) * 10}px`,
                            }}
                            inputStyle={{
                                height: '20px',
                            }}
                            type="text"
                            value={pageNumberDisplay}
                            onChange={(value) => {
                                const valueNumber = parseInt(value, 10);
                                if (isNaN(valueNumber) || valueNumber < 0 || valueNumber > numPages()) {
                                    return;
                                }
                                const newPage = valueNumber - 1;
                                const maxPage = numPages() - 1;
                                const pageNum = Math.max(0, Math.min(maxPage, newPage));
                                props.setPage(pageNum);
                            }}
                            delay={700}
                        />
                        <div className="pagFooter_footPageDivider">/</div>
                        <div className="pagFooter_footPageNumber">{numPages()}</div>
                    </div>
                    <div className={'pagFooter_footPageForward' + (hasMorePages() ? ' pagFooter_link' : '')}>
                        <img
                            src={`/images/icons/rightCaret${hasMorePages() ? '' : 'Dim'}.png`}
                            alt="next page"
                            onClick={() => {
                                if (!hasMorePages()) return;
                                props.setPage(props.page + 1);
                            }}
                        />
                    </div>
                </>
            )}

            {renderRefreshButton()}
        </div>
    );
};

export default PaginationFooter;
