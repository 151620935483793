import React, { useState, ChangeEvent } from 'react';
import formatCurrency from '../../utilities/format/formatCurrency';
import styles from '../../styles/currencyInput.module.scss';
import { CURRENCIES } from '../../constants/currencyConstants';
import { Currency } from '../../types/Currency';

interface CurrencyInputProps {
    value: string;
    onChange: (value: string) => void;
    currency?: Currency;
}

const CurrencyInput: React.FC<CurrencyInputProps> = ({ value, onChange, currency = CURRENCIES.USD }) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
        const formattedValue = formatCurrency(value, currency);
        // onChange(formattedValue);
    };

    const removeLeadingZeros = (input: string): string => {
        return input.replace(/^0+(?=\d)/, '');
    };

    const isValidCurrencyInput = (input: string): boolean => {
        // Allow only digits and a single period
        return /^\d*\.?\d{0,2}$/.test(input);
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        let inputValue = e.target.value;

        // Remove leading zeros
        inputValue = removeLeadingZeros(inputValue);

        // Validate input to allow only digits and a single period for decimal values
        if (isValidCurrencyInput(inputValue)) {
            onChange(inputValue);
        }
    };

    return (
        <input
            className={styles.amountInput}
            type="text"
            value={isFocused ? value : formatCurrency(value, currency)}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
        />
    );
};

export default CurrencyInput;
