import _ from 'lodash';
import React from 'react';
import api from '../../api';

const RecalculateButton = (props) => {
    const [recalculated, setRecalculated] = React.useState(false);
    const [confirm, setConfirm] = React.useState(false);

    const investmentStr = JSON.stringify(props.investment);

    // turn off recalculating when the investment changes
    React.useEffect(() => {
        setRecalculated(false);
    }, [investmentStr]);

    const recalculate = async () => {
        if (!confirm) {
            setConfirm(true);
            setTimeout(() => setConfirm(false), 3000);
            return;
        }
        setConfirm(false);
        if (props.investment && props.user) {
            await props.loading(null, 'recalculate-button');
            await api.post(`/users/${props.user?._id}/recalculate`, {
                run_investments: [props.investment?._id],
            });
            const _ = await props.reloadData?.();
            await props.loaded('recalculate-button');
            setRecalculated(true);
        } else {
            console.log('no investment');
        }
    };

    const text = props.text ?? 'Recalculate';
    const doneText = props.doneText ?? 'Recalculated!';

    return (
        <span id="recalculateButton" className="a" onClick={recalculate}>
            {confirm ? 'Confirm?' : recalculated ? doneText : text}
        </span>
    );
};

export default RecalculateButton;
