import React from 'react';
import ObjectSearchSelectTs, { ObjectSearchSelectProps } from '../Dropdowns/ObjectSearchSelectTs';
import api from '../../api';
import { toQueryString } from '../../utilities/apiHelpers/queryString';
import { AssetManager } from '../../types/AssetManager';
import { generateStringHash } from '../../utilities/string/generateStringHash';

type AssetManagerSearchFilterProps = Omit<ObjectSearchSelectProps<AssetManager>, 'getLabel'> & {
    filter?: any;
    excludeDisplayFields?: (keyof AssetManager)[];
};

const AssetManagerSearchFilter = ({
    filter = {},
    onChange,
    selected,
    isMulti = false,
    formatOptionLabel,
    width,
    defaultOptions = false,
    defaultLabel = '',
    excludeDisplayFields = [],
    label = '',
}: AssetManagerSearchFilterProps) => {
    let filterHash = generateStringHash(JSON.stringify(filter));
    return (
        <ObjectSearchSelectTs<AssetManager>
            key={filterHash}
            label={label}
            placeholder={`Select Asset Manager${isMulti ? 's' : ''}`}
            selected={selected}
            onChange={onChange}
            getLabel={(assetManager: AssetManager) => assetManager?.name}
            matchProperty="_id"
            loadOptions={async (inputValue: string) => {
                try {
                    const assetManagerQuery = {
                        ...filter,
                        search: inputValue,
                    };
                    const assetManagerQueryString = toQueryString(assetManagerQuery);
                    const assetManagers: AssetManager[] = (await api.get(`/assetmanagers?${assetManagerQueryString}`)).results ?? [];

                    return assetManagers;
                } catch (e) {
                    console.error('Error loading asset managers:', e);
                    return [];
                }
            }}
            width={width ?? '100%'}
            isMulti={isMulti}
            formatOptionLabel={(option) => {
                if (formatOptionLabel) return formatOptionLabel(option);
                const assetManager: AssetManager = option.value;
                if (!assetManager) return defaultLabel || 'None';
                return (
                    <div>
                        <div style={{}}>{assetManager.name}</div>
                        <div style={{ fontSize: '12px', color: 'var(--color-light-gray)' }}>{assetManager.website}</div>
                    </div>
                );
            }}
            defaultLabel={defaultLabel || 'None'}
            defaultOptions={defaultOptions}
        />
    );
};

export default AssetManagerSearchFilter;
