import api from '../../api'; // Assuming api is correctly imported based on context provided
import { toQueryString } from '../../utilities/apiHelpers/queryString';
import { generateStringHash } from '../../utilities/string/generateStringHash';
import ObjectSearchSelectTs, { ObjectSearchSelectProps } from '../Dropdowns/ObjectSearchSelectTs';

type AdvisoryGroup = any; // replace once AdvisoryGroups are added to api2

type AdvisoryGroupSearchFilterProps = Omit<ObjectSearchSelectProps<AdvisoryGroup>, 'getLabel'> & {
    filter?: any;
    excludeDisplayFields?: (keyof AdvisoryGroup)[];
};

const AdvisoryGroupSearchFilter = ({
    filter = {},
    onChange,
    selected,
    isMulti = false,
    formatOptionLabel,
    width,
    defaultOptions = false,
    defaultLabel = '',
    excludeDisplayFields = [],
    label = '',
}: AdvisoryGroupSearchFilterProps) => {
    let filterHash = generateStringHash(JSON.stringify(filter));
    return (
        <ObjectSearchSelectTs<AdvisoryGroup>
            key={filterHash}
            label={label}
            placeholder={`Select Advisory Group${isMulti ? 's' : ''}`}
            selected={selected}
            onChange={onChange}
            getLabel={(advisoryGroup: any) => advisoryGroup?.name}
            matchProperty="_id"
            loadOptions={async (inputValue: string) => {
                try {
                    const query = {
                        ...filter,
                        search: inputValue,
                        count: 50,
                        sortField: 'name',
                    };

                    const advisoryGroups = (await api.get(`/advisorygroups?${toQueryString(query)}`)).results;

                    return advisoryGroups as AdvisoryGroup[];
                } catch (e) {
                    console.error('Error loading advisory groups:', e);
                    return [];
                }
            }}
            width={width ?? '100%'}
            isMulti={isMulti}
            formatOptionLabel={(option) => {
                if (formatOptionLabel) return formatOptionLabel(option);
                const advisoryGroup: AdvisoryGroup | null = option.value;

                if (!advisoryGroup) return defaultLabel || 'All Advisory Groups';

                return (
                    <div>
                        <div style={{ fontSize: '12px', color: 'var(--color-light-gray)' }}>{advisoryGroup?.plan?.public_plan_name}</div>
                        <div>{advisoryGroup?.name}</div>
                    </div>
                );
            }}
            defaultLabel={defaultLabel || 'None'}
            defaultOptions={defaultOptions}
        />
    );
};

export default AdvisoryGroupSearchFilter;
