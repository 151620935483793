export const DEFAULT_HIGHLIGHT_COLOR = 'rgba(0,255,0,0.4)';
// export const DEFAULT_HIGHLIGHT_COLOR = 'rgba(255,139,72,0.4)';

export const getDistanceBetweenPoints = (x1, y1, x2, y2) => {
    return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
};

export const getPageDimensionsAndMouseOffset = (event) => {
    const page = document.querySelector('.pageImage');
    const pageWidth = page.getBoundingClientRect().width;
    const pageHeight = page.getBoundingClientRect().height;
    return {
        width: pageWidth,
        height: pageHeight,
        // OffsetX is relative to THIS page image
        mouseOffsetX: event ? event.offsetX / pageWidth : undefined,
        mouseOffsetY: event ? event.offsetY / pageHeight : undefined,
    };
};

// get distance in relative units between mouse and word
export const getDistanceToWord = (event, word) => {
    const { mouseOffsetX, mouseOffsetY } = getPageDimensionsAndMouseOffset(event);

    const toLeft = mouseOffsetX < word.x;
    const toRight = mouseOffsetX > word.x + word.width;
    const toTop = mouseOffsetY < word.y;
    const toBottom = mouseOffsetY > word.y + word.height;

    if (!toLeft && !toRight && !toTop && !toBottom) {
        // mouse is inside word
        return 0;
    } else if (toLeft && toTop) {
        return getDistanceBetweenPoints(mouseOffsetX, mouseOffsetY, word.x, word.y);
    } else if (toLeft && toBottom) {
        return getDistanceBetweenPoints(mouseOffsetX, mouseOffsetY, word.x, word.y + word.height);
    } else if (toRight && toTop) {
        return getDistanceBetweenPoints(mouseOffsetX, mouseOffsetY, word.x + word.width, word.y);
    } else if (toRight && toBottom) {
        return getDistanceBetweenPoints(mouseOffsetX, mouseOffsetY, word.x + word.width, word.y + word.height);
    } else if (toLeft) {
        return word.x - mouseOffsetX;
    } else if (toRight) {
        return mouseOffsetX - (word.x + word.width);
    } else if (toTop) {
        return word.y - mouseOffsetY;
    } else if (toBottom) {
        return mouseOffsetY - (word.y + word.height);
    }
};

export const isWordHovered = (event, word) => {
    // const distance = getDistanceToWord(event, word);
    // return distance < 0.01;

    const { mouseOffsetX, mouseOffsetY } = getPageDimensionsAndMouseOffset(event);
    const HOVER_THRESHOLD_PERCENT = 0.03;

    return (
        mouseOffsetX > word.x - word.width * HOVER_THRESHOLD_PERCENT &&
        mouseOffsetX < word.x + word.width + word.width * HOVER_THRESHOLD_PERCENT &&
        mouseOffsetY > word.y - word.height * HOVER_THRESHOLD_PERCENT &&
        mouseOffsetY < word.y + word.height + word.height * HOVER_THRESHOLD_PERCENT
    );
};

export const getHoveredWord = (event, words) => {
    for (let i = 0; i < words.length; i++) {
        const word = words[i];
        if (isWordHovered(event, word)) {
            return {
                ...word,
                wordIndex: i,
            };
        }
    }
};

export const getClosestWord = (event, words) => {
    const distances = words.map((word) => getDistanceToWord(event, word));
    const minDistance = Math.min(...distances);
    const wordIndex = distances.indexOf(minDistance);
    const closestWord = words[wordIndex];
    return {
        ...closestWord,
        distance: minDistance,
        wordIndex,
    };
};

export const doWordsMatch = (tag, word) => {
    return tag.page === word.page && tag.line === word.line && tag.word === word.word && tag.text === word.text;
};

export const getPreviousAndNextDocId = (docId, docIdList) => {
    const docIndex = docIdList?.findIndex((id) => id === docId);
    const previous = docIdList?.[docIndex - 1];
    const next = docIdList?.[docIndex + 1];
    return { previous, next };
};
