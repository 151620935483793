import React, { PureComponent } from 'react';
import '../../styles/multitoggle.css';

export default class Multitoggle extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            selectionIdx: this.getSelectedIndex(props),
        };
    }

    getSelectedIndex = (props) => {
        if (props.selection !== undefined) {
            return props.options.findIndex((o) => o === props.selection);
        } else if (props.defaultSelected) {
            return props.options.findIndex((o) => o === props.defaultSelected);
        }
        return -1;
    };

    componentDidUpdate(prevProps) {
        if (prevProps.defaultSelected !== this.props.defaultSelected || prevProps.selection !== this.props.selection) {
            this.setState({ selectionIdx: this.getSelectedIndex(this.props) });
        }
    }

    handleOptionClick = (opt, idx, opts) => {
        if (!this.props.disabled) {
            this.setState({ selectionIdx: idx }, () => this.props.onSelect?.(opt, idx, opts));
        }
    };

    renderOption = (opt, idx, opts) => {
        const { keyStr, options, renderOption, optionStyle, disabled, titleHover, renderTitle, zIndex } = this.props;
        const isSelected = idx === this.state.selectionIdx;
        const key = `toggleBarOpt_${keyStr ?? ''}${idx}`;
        const inner = renderOption ? renderOption(opt, idx, opts) : opt;
        const style = {
            width: `${100 / options.length}%`,
            zIndex: zIndex ?? 0,
            ...optionStyle,
            ...(disabled ? { cursor: 'not-allowed' } : {}),
        };

        return (
            <div
                key={key}
                className="optionContainer"
                style={style}
                onClick={(e) => {
                    e.stopPropagation();
                    this.handleOptionClick(opt, idx, opts);
                }}
                title={titleHover ? (renderTitle?.(opt, idx, opts) ?? inner) : ''}
            >
                {inner}
            </div>
        );
    };

    renderOptions = () => this.props.options.map((opt, idx, opts) => this.renderOption(opt, idx, opts));

    render() {
        const { options, containerStyle, selectedOptionStyle } = this.props;
        const { selectionIdx } = this.state;
        const sliderStyle = {
            width: `${100 / options.length}%`,
            left: `${selectionIdx * (100 / options.length)}%`,
            ...selectedOptionStyle,
        };

        return (
            <div className="toggleBar" style={containerStyle}>
                <div className="slider" style={sliderStyle} />
                {this.renderOptions()}
            </div>
        );
    }
}
